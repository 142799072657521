import { useQuery } from "react-query";
import StatusFilter from "./StatusFilter";
import axios from "axios";
import { outletUrl } from "../../../utils/urls";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import {useMediumScreen, useSmallScreen} from "../../../utils/mediaQueries";

const RestoListFilter = ({
  onClick = () => {},
  menuList: _menuList = null,
  matches = false,
}) => {
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const { user } = useSelector((state) => state.auth);
  const { pendingOrders } = useSelector((state) => state.pendingOrders);
  const { hasMess } = useSelector((state) => state.auth.user);
  const outletIdnew = useSelector((state) => state.outledId.outletId);

  const { data, isLoading } = useQuery("outlet-list", () =>
    axios(`${outletUrl}${user?.outletId}`)
  );
  const [outletIdds, setOutletIdds] = useState([]);
  useEffect(() => {
    const Ids = data?.data?.data?.map((o) => o?.outletId);

    Ids && user?.outletId && setOutletIdds([...Ids, user?.outletId]);
  }, [data?.data?.data]);

  //now from the ids i want to call api and response of that api is pending order count and ountlet
  useEffect(() => {
    !hasMess && outletIdds?.length > 0 && getPendingOrderCount();
  }, [outletIdds,outletIdnew]);

  const [apiData, setApiData] = useState([]);

  const getPendingOrderCount = async () => {
    try {
      // api call for outletId's array and get in response outletId and pendingordercount
      // console.log("outletIdds ", outletIdds);
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "order/order/getPendingOrderCountForOutlets",
        { outletIds: outletIdds }
      );

      // console.log("res of pending orders ", res);

      if (res?.data?.success) {
        setApiData(res?.data?.data);
      }
    } catch (error) {
      console.log("Error while fetch pending orders from outletIds");
    }
  };

  return user?.isPrimaryOutlet ? (
    <StatusFilter
      defaultValue={`${user?.outletName || ""}, ${
        user?.campusId?.campusName
      }, ${user?.campusId?.cityId?.city}`}
      width={matches ? "20%" : "50rem"}
      labelWidth={isSmallScreen ? "15rem" : isMediumScreen ? "75%" : "50rem"}
      label={
        <>
          {" "}
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            Select Restaurant
          </span>{" "}
          {isLoading && <CircularProgress size={14} />}{" "}
        </>
      }
      onClick={onClick}
      menuList={
        data?.data?.data
          ? [
              {
                id: user?.outletId,
                label: `${user?.outletName || ""}, ${
                  user?.campusId?.campusName
                }, ${user?.campusId?.cityId?.city} `,
                count:`${
                  !hasMess &&
                  apiData?.find(
                    (apiItem) => apiItem?.outletId === user?.outletId
                  )
                    ? apiData?.find(
                        (apiItem) => apiItem?.outletId === user?.outletId
                      )?.orders
                    : ""
                }`
              },
              ...data?.data?.data?.map((o) => {
                const matchedApiData = apiData?.find(
                  (apiItem) => apiItem?.outletId === o?.outletId
                );
                const count =
                  !hasMess && matchedApiData && matchedApiData?.orders;
                return {
                  id: o.outletId,
                  label: `${o.outletName}, ${o.campusId?.campusName}, ${
                    o.cityId?.city
                  } `,
                  count:`${!hasMess && count}`
                };
              }),
            ]
          : []
      }
    />
  ) : (
    <div></div>
  );
};

export default RestoListFilter;
