import { Box, Dialog, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../formUI/CustomButton";
import PaperBox from "./PaperBox";
import PageHeader from "../pageUI/PageHeader";
import StackRow from "./StackRow";
import { Close } from "@mui/icons-material";
import { Form, Formik, useFormik, useFormikContext } from "formik";
import CustomTextFieldFormik from "../formUI/CustomTextFieldFormik";
import CustomSelectFormik from "../formUI/CustomSelectFormik";
import * as Yup from "yup";
// import { createStaff, getRestaurantRole, updateSatff } from "../../Redux Store/staff";
import { dismissLoader, presentLoader } from "../../Redux Store/loaderService";
import { errorToast, successToast } from "../../Redux Store/toast";
import { useSelector } from "react-redux";
import {
  createStaff,
  getRestaurantRole,
  updateSatff,
} from "../../Services/staff";
import CustomSelect from "../formUI/CustomSelect";
import { mobilePattern } from "../../utils/regex";
import {useSmallScreen} from "../../utils/mediaQueries"

const StaffDialog = ({
  open: _open = false,
  setOpen: _setOpen = () => {},
  buttonComp,
  width,
  children,
  onClose,
  title,
  subTitle,
  defaultData,
  refetch = () => 777,
  onSuccess = () => {},
}) => {
  const resData = useSelector((state) => state.auth);
  const [resRoleList, setResRoleList] = useState([]);
  const isSmallScreen = useSmallScreen();

  const initialState = {
    name: "",
    email: "",
    mobile: "",
    role: "",
    password: "",
    cpassword: "",
  };
  const [open, setOpen] = useState(_open || false);
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    setOpen(_open);
  }, [_open]);

  useEffect(() => {
    defaultData &&
      setFormData({ ...defaultData, role: defaultData?.roleId?.role });
  }, [defaultData]);

  

  const closeHandler = () => {
    setOpen(!open);
    _setOpen(!open);
    onClose?.();
    setFormData(initialState);
  };

  

  useEffect(() => {
    getRestaurantRole(resData?.user?.outletId)
      .then((res) => {
        let tempArr = [];
        res.data.data.map((e) =>
          tempArr.push({
            name: e.role,
            value: e.roleId,
            id: e.roleId,
          })
        );
        setResRoleList(tempArr);
      })
      .catch((err) => {});
  }, []);

  const handleSubmit = (values) => {
    
    if (defaultData) {
      const updatedData = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        roleId: values.roleId,
      };
      
      presentLoader();
      updateSatff(defaultData.outletStaffAuthUId, updatedData)
        .then((res) => {
          if (res.data.success) {
            dismissLoader();
            setOpen(!open);
            onSuccess();
            refetch();
            successToast("Staff Updated Successfully");
          }
        })
.catch((err) => {});
    } else {
      const updatedFormData = resData.user.outletId
        ? {
            name: values.name,
            email: values.email,
            mobile: values.mobile,
            roleId: values.role,
            password: values.cpassword,
            outletId: resData.user.outletId,
            restaurantId: resData.user.restaurantId,
          }
        : {
            name: values.name,
            email: values.email,
            mobile: values.mobile,
            roleId: values.role,
            password: values.cpassword,
            restaurantId: resData.user.restaurantId,
          };
      
      presentLoader();
      createStaff(updatedFormData)
        .then((res) => {
          onSuccess();
          refetch();
          if (res.data.success) {
            successToast("Staff Added Successfully");
            dismissLoader();
            // setOpen(!open);
            closeHandler();
          }
        })
        .catch((error) => {
          dismissLoader();
          errorToast(error.response.data.error);
        });
    }
  };
  
  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => {
          closeHandler();
        }}
        sx={{
          "& .MuiDialog-paper": {
            background: "transparent",
            width: width || "auto",
          },
        }}
      >
        <Formik
          initialValues={{
            ...formData,
            ...defaultData,
            role: defaultData?.roleId.roleId,
          }}
          onSubmit={(values) => {
            
            handleSubmit({ ...values, roleId: values.role });
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required("Name is required")
              .min(3, "Name must be at least 3 characters")
              .max(30, "Name can't be more than 30 characters"),
            email: Yup.string().required("Email is required"),
            mobile: Yup.string()
              .required("Mobile Number is required")
              .matches(mobilePattern, "Invalid mobile number"),
            role: Yup.string().required("Role is required"),
            password: defaultData
              ? Yup.string()
              : Yup.string()
                  .required("Password is required")
                  .matches(
                    /^(?=.*[a-z])/,
                    "Password must contain at least one lowercase letter"
                  )
                  .matches(
                    /^(?=.*[A-Z])/,
                    "Password must contain at least one uppercase letter"
                  )
                  .matches(
                    /^(?=.*\d)/,
                    "Password must contain at least one digit"
                  )
                  .matches(
                    /^(?=.*[@$!%*?&])/,
                    "Password must contain at least one special character"
                  )
                  .min(8, "Password must be at least 8 characters long"),
            cpassword: defaultData
              ? Yup.string()
              : Yup.string()
                  .required("Confirm Password is required")
                  .oneOf([Yup.ref("password"), null], "Passwords must match"),
          })}
        >
          {/* {({ errors }) => */}
          <Form>
            <Box>
              <PaperBox padding={"24px"}>
                <PageHeader
                  secondary={true}
                  title={title}
                  subTitle={subTitle}
                  rightUi={
                    <StackRow center>
                      <div onClick={() => closeHandler()}>
                        <Close
                          sx={{
                            color: "#64748B",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </StackRow>
                  }
                />
                <Grid container spacing={"20px"}>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <CustomTextFieldFormik
                      label={"Name"}
                      placeholder="Enter Name"
                      required
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <CustomTextFieldFormik
                      label={"Email"}
                      placeholder="Enter Email"
                      required
                      isDisabled={defaultData}
                      name="email"
                    />
                  </Grid>

                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <CustomTextFieldFormik
                      label={"Owner Mobile Number"}
                      placeholder="Enter Owner Mobile Number"
                      required
                      name="mobile"
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <CustomSelectFormik
                      label="Select Role"
                      placeholder="Select"
                      required
                      isStaff={defaultData}
                      name={"role"}
                      menuList={resRoleList || []}
                    />
                  </Grid>
                  {!defaultData && (
                    <>
                      <Grid item xs={isSmallScreen ? 12 : 6}>
                        <CustomTextFieldFormik
                          label={"Password"}
                          placeholder="Enter Password"
                          required
                          name="password"
                          onPaste={(e) => e.preventDefault()}
                        />
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : 6}>
                        <CustomTextFieldFormik
                          label={"Confirm Password"}
                          placeholder="Enter Confirm Password"
                          required
                          name="cpassword"
                          onPaste={(e) => e.preventDefault()}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <StackRow
                  sx={{
                    mt: "32px",
                  }}
                  justifyContent={isSmallScreen ? "center" : "flex-end"}
                  gap="12px"
                  flexWrap="wrap"
                >
                  <CustomButton
                    onClick={() => {
                      closeHandler();
                    }}
                    variant="outlined"
                    sx={{
                      minWidth: "180px",
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    sx={{
                      minWidth: "180px",
                    }}
                  >
                    {defaultData ? "Update" : "Submit"}
                  </CustomButton>
                </StackRow>
              </PaperBox>
            </Box>
          </Form>
          {/* } */}
        </Formik>
      </Dialog>

      {buttonComp?.({
        onClick: () => {
          setOpen(!open);
          _setOpen(!open);
        },
      })}
    </Box>
  );
};

export default StaffDialog;

{
  /* <PaperBox padding={"24px"}>
<PageHeader
  secondary
  title={"Upload Excel"}
  subTitle={"Add Menu Via Upload Excel"}
/>
</PaperBox> */
}
