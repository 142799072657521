import React from "react";

const SearchSvg = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.18586"
        cy="6.92463"
        r="4.49983"
        stroke="#64748B"
        strokeWidth="1.12496"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2793 10.2758L12.0917 13.0883"
        stroke="#64748B"
        strokeWidth="1.04996"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchSvg;
