import axios from "axios";

 async function updateRestaurantData(restaurantId, postbody){
    return await axios.post(process.env.REACT_APP_BACKEND_BASE_URL+'restaurent/updateRestaurant/'+restaurantId, postbody)
 }
 async function updateOutletData(outletId, postbody){
    return await axios.post(process.env.REACT_APP_BACKEND_BASE_URL+'outlet/updateOutlet/'+outletId, postbody)
 }
 async function updateOutletOpenStatus(postbody){
  return await axios.post(process.env.REACT_APP_BACKEND_BASE_URL+'outlet/outletIsOpenStatusChange', postbody)
}
 async function getCities(){
   return await axios.get( process.env.REACT_APP_BACKEND_BASE_URL+'city/getCities')
 }
 async function getBankList(){
   return await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + 'common/getBankNames')
 }
 async function updateFSSAILicense(){
   return await axios.post(process.env.REACT_APP_BACKEND_BASE_URL+'outlet/upsertFssaiLicensePhoto'+'')
 }
 export{
    updateOutletData, updateRestaurantData, getCities, updateFSSAILicense, getBankList, updateOutletOpenStatus
 }