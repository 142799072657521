import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useField } from "formik";
import LargeText from "../typographyUI/LargeText";

export default function CustomAutoComplete({
  noLabel = false,
  required,
  label = "Add Label",
  sx = {},
  placeholder,
  value = "Role",
  menuList = [],
  isStaff = false,
  noLabelMenu,
  defaultValue,
  disabled = false,
  onChange = () => {},
  key,
  isSetNull = false,
  ...otherProps
}) {
  const [field, meta] = useField(otherProps.name);
  useEffect(() => {
    field.onChange({
      target: defaultValue || { name: otherProps.name, value: "" },
    });
  }, []);
  return (
    <Stack spacing={0.5}>
      {!noLabel && (
        <Typography
          sx={{
            mb: "6px",
          }}
          fontWeight={"500"}
          color={"#0F172A"}
        >
          {label}{" "}
          <span
            style={{
              display: required ? "inline" : "none",
              color: "#EF4444",
            }}
          >
            *
          </span>
        </Typography>
      )}
      <Autocomplete
        sx={{
          "& legend, & .MuiFormLabel-root": {
            display: "none!important",
          },
        }}
        id="custom-select-autocomplete"
        options={menuList}
        getOptionLabel={(option) => option.name || ""}
        value={field?.value || defaultValue || null}
        onChange={(_, newValue) => {
          newValue &&
            field.onChange({
              target: { name: otherProps.name, value: newValue },
            });
          newValue &&
            onChange({ target: { name: otherProps.name, value: newValue } });
        }}
        onBlur={field.onBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={meta.touched && meta.error}
            disabled={disabled}
            sx={{
              // position: 'relative',
              // top: '2px',
              // mb: '1.25rem',
              // borderRadius: '0.625rem',
              // boxShadow: '0px 1px 2px rgba(15, 23, 42, 0.06)',
              // height: '40px',
              // padding: '11px',
              // color: '#94A3B8',
              "& .MuiFormHelperText-root": {
                // position: "absolute",
                // bottom: "-22px",
              },
              "& .MuiInputBase-root": {
                "&:hover": {
                  borderColor: "#CBD5E1",
                },
                boxShadow: "0px 1px 2px 0px rgba(15, 23, 42, 0.06)",
                borderRadius: "10px",
                "& > input": {
                  padding: "11px",
                  "&::placeholder": {
                    color: " #94A3B8",
                    opacity: "1",
                  },
                },
              },
              "& fieldset": {
                borderColor: "#CBD5E1",
              },
              "& .MuiInputBase-root": {
                py: 0,
                borderRadius: "10px",
              },
              borderColor: meta.touched && meta.error ? "#d32f2f" : "#CBD5E1",
              ...sx,
            }}
            helperText={meta.touched && meta.error ? meta.error : ""}
          />
        )}
      />
    </Stack>
  );
}
