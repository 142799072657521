import React, { useState } from "react";
import QrReader from "react-qr-scanner";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import CustomDialog from "../components/layoutUI/CustomDialog";
import Scan from "../assets/icons/scan.svg";
import { Box, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { useGetApis } from "../hooks/useGetApis";
import Text from "../components/typographyUI/Text";
import DummyImage from "../assets/images/dummy-image.png";

const RSVPQrScane = ({ outletId, afterScanMethod }) => {
  const [open, setOpen] = useState(false);
  const [reopenAfterScan, setReopenAfterScan] = useState(false);

  const { serveMeal } = useGetApis();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleScan = (data) => {
    if (data) {
      console.log("data ", data);
      if (data?.text?.includes("===")) {
        const menuId = data.text.split("===")?.[0];
        const customerAuthUID = data.text.split("===")?.[1];

        axios
          .post(`${serveMeal}`, {
            menuId,
            customerAuthUID,
            outletId,
          })
          .then((res) => {
            console.log("QR scan response ", res);
            if (res?.data?.success) {
              // toast.success(res?.data?.message, { autoClose: 1000 });
              toast.success(
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // padding: "2rem",
                    }}
                  >
                    <img
                      src={res?.data?.data?.Customer?.photo || DummyImage}
                      alt="Profile"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    <div>
                      <Text bold style={{fontSize:"1.8rem"}}>{res?.data?.data?.Customer?.customerName}</Text>
                      <Text bold style={{fontSize:"1.1rem"}}>{res?.data?.data?.Customer?.email}</Text>
                    </div>
                  </div>
                  <Text
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#D1FFBD",
                      color: "darkgreen",
                      padding: "0.5rem",
                      borderRadius: "20px",
                      marginTop: "2rem",
                      textAlign: "center",
                    }}
                  >
                     Meal Served Successfully
                  </Text>
                </>,
                { autoClose: 3000 }
              );
              afterScanMethod();
            } else {
              toast.error(res?.data?.message, { autoClose: 1000 });
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.error, { autoClose: 1000 });
          });
          
      } else {
        toast.error("Invalid QR code.", { autoClose: 1000 });
      }

      setReopenAfterScan(true);
      handleClose();
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  React.useEffect(() => {
    if (!open && reopenAfterScan) {
      const timer = setTimeout(() => {
        setOpen(true);
        setReopenAfterScan(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [open, reopenAfterScan]);

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <img
          src={Scan}
          style={{
            width: "30px",
            height: "30px",
          }}
        />
      </IconButton>

      {open && (
        <CustomDialog
          open={true}
          dontClose={true}
          sx={{
            width: 600,
          }}
        >
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Close
              sx={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setReopenAfterScan(false);
                handleClose();
              }}
            />
          </Box>
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%" }}
          />
        </CustomDialog>
      )}
    </div>
  );
};

export default RSVPQrScane;
