import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PageHeader from "../components/pageUI/PageHeader";
import StackRow from "../components/layoutUI/StackRow";
import CustomSearchTextField from "../components/formUI/CustomSearchTextField";
import CustomFilter from "../components/pageUI/filters/CustomFilter";
import StatusFilter from "../components/pageUI/filters/StatusFilter";
import DateFilter from "../components/pageUI/filters/DateFilter";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import CurrentOrders from "../components/pageUI/orders/CurrentOrders";
import History from "../components/pageUI/orders/History";
import Canceled from "../components/pageUI/orders/Cancelled";
import PetPoojaOrders from "../components/pageUI/orders/PetPoojaOrders";
import Refund from "../components/pageUI/orders/Refund";
import PendingOrders from "../components/pageUI/orders/PendingOrders";
import AllOrders from "../components/pageUI/orders/AllOrders";
import LiveOrders from "../components/pageUI/orders/LiveOrders";
import ReadyOrders from "../components/pageUI/orders/ReadyOrders";
import { useLocation, useNavigate } from "react-router-dom";
import { tabsClasses } from '@mui/material/Tabs';
import { useSelector } from "react-redux";
import { getAllOrdersUrl } from "../utils/urls";
import { Padding } from "@mui/icons-material";
import { useGetApis } from "../hooks/useGetApis";
import {
  useSmallScreen,
  isMobile,
  useMediumScreen,
} from "../utils/mediaQueries";

const NotificationTab = ({ count, label, ...props }) => {
  const isSmallScreen = useSmallScreen();
  return (
    <Tab
      label={
        <Badge
          badgeContent={count}
          color="secondary"
          style={{
            padding: isSmallScreen ? "2px 4px" : "4px 8px",
            marginTop: 2,
            fontSize: isSmallScreen ? "0.8rem" : "1rem",
          }}
        >
          {label}
        </Badge>
      }
      {...props}
      sx={{
        minWidth: "auto",
        flex: isSmallScreen ? "1 0 auto" : "none",
        maxWidth: isSmallScreen ? "50%" : "none",
      }}
    />
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Orders() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();

  const tabs = [
    { label: "pending", component: PendingOrders },
    { label: "current", component: CurrentOrders },
    { label: "Live", component: LiveOrders },
    { label: "Ready", component: ReadyOrders },
    { label: "history", component: History },
    { label: "cancelled", component: Canceled },
  ];

  const outletData = useSelector((state) => state.auth.user);
  const isAuthId = !!outletData?.outletStaffData?.outletStaffAuthUId;
  const isIncluded = outletData?.outletStaffData?.roleId?.access || [];
  // console.log("isIncluded ", isIncluded);

  const { getReadyOrders, getPendingOrders, getLiveOrders, orderGetUrl } =
    useGetApis();

  const outletNewId = useSelector((state) => state.outledId.outletId);
  const { pendingOrders } = useSelector((state) => state.pendingOrders);

  const [redyCount, setRedyCount] = React.useState(0);
  const [pendingCount, setPendingCount] = React.useState(0);
  const [currentCount, setCurrentCount] = React.useState(0);
  const [liveCount, setLiveCount] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate("#" + tabs[newValue].label.toLowerCase().replaceAll(" ", ""));
  };

  React.useEffect(() => {
    getAllOrderDetails();
  }, [
    outletNewId,
    redyCount,
    pendingCount,
    currentCount,
    liveCount,
    handleChange,
  ]);

  const getAllOrderDetails = () => {
    outletNewId && getRedyOrderDetails();
    outletNewId && getPendingOrderDetails();
    outletNewId && getCurrentOrderDetails();
    outletNewId && getLiveOrderDetails();
  };

  const getRedyOrderDetails = async () => {
    try {
      const response = await fetch(`${getReadyOrders}${outletNewId}`);
      const data = await response.json();
      if (data.success === true) {
        setRedyCount(data.data.length);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const getPendingOrderDetails = async () => {
    try {
      const response = await fetch(`${getPendingOrders}${outletNewId}`);
      const data = await response.json();
      if (data.success === true) {
        setPendingCount(pendingOrders.length);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const getCurrentOrderDetails = async () => {
    try {
      const response = await fetch(`${orderGetUrl}${outletNewId}`);
      const data = await response.json();
      if (data.success === true) {
        setCurrentCount(data.data.length);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const getLiveOrderDetails = async () => {
    try {
      const response = await fetch(`${getLiveOrders}${outletNewId}`);
      const data = await response.json();
      if (data.success === true) {
        setLiveCount(data.data.length);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  React.useEffect(() => {
    const currentTab = tabs.findIndex(
      (t) => `#${t.label}`.toLowerCase().replaceAll(" ", "") === location.hash
    );
    setValue(currentTab === -1 ? 0 : currentTab);
  }, [location.hash]);

  // const filteredTabs = isAuthId
  //   ? tabs.filter(tab => isIncluded.includes(tab.label))
  //   : tabs;

  const filteredTabs = React.useMemo(
    () =>
      isAuthId
        ? isIncluded.length == 1 && isIncluded[0] === "Orders"
          ? tabs
          : tabs.filter((tab) => isIncluded.includes(tab.label))
        : tabs,
    [isAuthId, isIncluded, tabs]
  );

  // console.log("sdfasfd ",filteredTabs)

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          overflowX: isSmallScreen ? "auto" : "visible",
        }}
      >
        {outletData?.isOrderHandlingFromPetpooja ? (
          <Tabs value={value}>
            <Tab
              style={{ fontSize: isSmallScreen ? "0.8rem" : "1rem" }}
              label="PetPooja Orders"
              {...a11yProps(6)}
            />
          </Tabs>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            scrollButtons="auto"
            allowScrollButtonsMobile
            variant="scrollable"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
            // sx={{
            //   flexWrap: 'wrap',
            //   '& .MuiTabs-flexContainer': {
            //     flexWrap: 'wrap',
            //   },
            // }}
          >
            {filteredTabs.map((tab, index) => (
              <NotificationTab
                key={tab.label}
                count={
                  tab.label === "pending"
                    ? pendingCount
                    : tab.label === "current"
                    ? currentCount
                    : tab.label === "Live"
                    ? liveCount
                    : tab.label === "Ready"
                    ? redyCount
                    : 0
                }
                label={tab.label}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        )}
      </Box>
      {outletData?.isOrderHandlingFromPetpooja ? (
        <CustomTabPanel value={value} index={0}>
          <PetPoojaOrders />
        </CustomTabPanel>
      ) : (
        filteredTabs.map((tab, index) => (
          <CustomTabPanel
            key={tab.label}
            value={value}
            index={index}
            style={{ padding: isSmallScreen ? "8px" : "16px" }}
          >
            <tab.component
              getAllOrderDetails={getAllOrderDetails}
              isSmallScreen={isSmallScreen}
              isMediumScreen={isMediumScreen}
            />
          </CustomTabPanel>
        ))
      )}
    </Box>
  );
}
