import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  kot: [],
};

export const KOT = createSlice({
  name: "KOT",
  initialState,
  reducers: {
    setKotData: (state, action) => {
      state.kot = [...action.payload]
    },
  },
});

export const { setKotData } = KOT.actions;

export default KOT.reducer;