import React from "react";

const DeleteSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.20886 7.27848C3.50477 6.3397 4.17462 5 5.3481 5H14.6519C15.8254 5 16.4952 6.3397 15.7911 7.27848V7.27848C15.2776 7.9632 15 8.796 15 9.6519V14.3333C15 16.5425 13.2091 18.3333 11 18.3333H9C6.79086 18.3333 5 16.5425 5 14.3333V9.6519C5 8.796 4.7224 7.9632 4.20886 7.27848V7.27848Z"
        stroke="#0F172A"
        strokeWidth="1.5"
      />
      <path
        d="M11.667 14.1665L11.667 9.1665"
        stroke="#0F172A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33301 14.1665L8.33301 9.1665"
        stroke="#0F172A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3337 5L12.9562 3.86754C12.6839 3.05086 11.9197 2.5 11.0588 2.5H8.94184C8.08098 2.5 7.31671 3.05086 7.04448 3.86754L6.66699 5"
        stroke="#0F172A"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DeleteSvg;
