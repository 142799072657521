import { Box, CircularProgress } from "@mui/material";
import React from "react";
import LoaderPng from "../../assets/icons/loader.png";
const CustomLoader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "240px",
        display: "grid",
        placeContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        {/* <img
          src={LoaderPng}
          style={{
            width: 45,
            height: 45,
            position: "absolute",
            top: 7.5,
            left: 7.5,
          }}
        /> */}
        <CircularProgress
          size={60}
          width={1}
          sx={{
            "& .MuiCircularProgress-svg": {
              color: "#fd8e7c",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomLoader;
