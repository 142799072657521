import { Box, Dialog, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../formUI/CustomButton";
import PaperBox from "./PaperBox";
import PageHeader from "../pageUI/PageHeader";
import StackRow from "./StackRow";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import CustomTextFieldFormik from "../formUI/CustomTextFieldFormik";
import { createRole, updateRole } from "../../Services/RoleService";
import CustomCheckbox from "../formUI/CustomCheckbox";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { FormHelperText } from "@mui/material";
import { InputLabel } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoadingSate } from "../../Redux Store/Slices/loaderSlice";
import { updatePetPoojaConfigs } from "../../Services/menuService";
import { authLogin } from "../../Redux Store/Slices/auth";
import CustomSwitch from "../../components/formUI/CustomSwitch";

const PetPoojaDialog = ({
  open,
  closeHandler,
  buttonComp,
  width,
  children,
  title,
  subTitle,
  setIsConnected,
}) => {


  const restaurantData = useSelector((state) => state.auth.user);
  const [toggleStatus, setToggleStatus] = useState(restaurantData?.isOrderHandlingFromPetpooja);
  const [petPoojaOrderDropStatus, setPetPoojaOrderDropStatus] = useState(restaurantData?.isOrderDropInPetpooja)

  // 
  const dispatch = useDispatch();

  async function updatePetPoojaConfigsToServer(value) {
    try {
      let postData = {
        ...value,
        isOrderHandlingFromPetpooja: toggleStatus,
        isOrderDropInPetpooja: petPoojaOrderDropStatus,
      };
      if (restaurantData?.publishProcessingStep === 1) {
        postData.publishProcessingStep = 2;
      }
      const updatePetPoojaConfigsResponse = await updatePetPoojaConfigs(
        postData,
        restaurantData?.outletId
      );
      
      setIsConnected(true);
      toast.success("PetPooja Integrated successfully.");
      dispatch(authLogin({ ...restaurantData, ...postData }));
      closeHandler(true);
    } catch (error) {
      
      if (typeof error?.response?.data?.error === "string") {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error("Something went wrong please try again");
      }
    }
  }

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => {
          closeHandler();
        }}
        sx={{
          "& .MuiDialog-paper": {
            background: "transparent",
            width: width || "auto",
          },
        }}
      >
        <Formik
          initialValues={{
            petPoojaAppKey: "",
            petPoojaAppSecret: "",
            petPoojaApAccessToken: "",
            petPoojaRestId: "",
            ...restaurantData,
          }}
          onSubmit={(value) => {
            
            updatePetPoojaConfigsToServer(value);
          }}
          validationSchema={Yup.object().shape({
            petPoojaAppKey: Yup.string().required("App key is required"),
            petPoojaAppSecret: Yup.string().required("App secret is required"),
            petPoojaApAccessToken: Yup.string().required(
              "Access token is required"
            ),
            petPoojaRestId: Yup.string().required("Restaurant id is required"),
          })}
        >
          {({ handleSubmit, isSubmitting, dirty }) => (
            <Form>
              <Box>
                <PaperBox padding={"24px"}>
                  <PageHeader
                    secondary={true}
                    title={title}
                    subTitle={subTitle}
                    rightUi={
                      <StackRow center>
                        <div onClick={() => closeHandler()}>
                          <Close
                            sx={{
                              color: "#64748B",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </StackRow>
                    }
                  />
                  <Grid container spacing={"20px"}>
                    <Grid item xs={12}>
                      <CustomTextFieldFormik
                        label={"Pet pooja App key"}
                        name="petPoojaAppKey"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextFieldFormik
                        label={"App Secret"}
                        name="petPoojaAppSecret"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextFieldFormik
                        label={"Access Token"}
                        name="petPoojaApAccessToken"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CustomTextFieldFormik
                        label={"RestID"}
                        name="petPoojaRestId"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomSwitch
                        value={toggleStatus}
                        label={"Manage orders from PetPooja"}
                        onChange={(e) => {
                          setToggleStatus(e);
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <CustomSwitch
                        value={petPoojaOrderDropStatus}
                        label={"Order drop in PetPooja"}
                        onChange={(e) => {
                          setPetPoojaOrderDropStatus(e)
                        }}
                      />
                    </Grid> */}
                  </Grid>
                  <StackRow
                    sx={{
                      mt: "32px",
                    }}
                    justifyContent="flex-end"
                    gap="12px"
                  >
                    <CustomButton
                      onClick={() => {
                        closeHandler();
                      }}
                      variant="outlined"
                      sx={{
                        minWidth: "180px",
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      sx={{
                        minWidth: "180px",
                      }}
                      // disabled={isSubmitting || !dirty}
                      loading= {isSubmitting}
                      onClick={() => {}}
                    >
                      Connect
                    </CustomButton>
                  </StackRow>
                </PaperBox>
              </Box>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
};
export default PetPoojaDialog;
