import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Skeleton, Stack } from "@mui/material";
import PageHeader from "../../components/pageUI/PageHeader";
import CustomButton from "../../components/formUI/CustomButton";
import { Add } from "@mui/icons-material";
import StackRow from "../../components/layoutUI/StackRow";
import CustomSearchTextField from "../../components/formUI/CustomSearchTextField";
import OutletCard from "../../components/pageUI/outlets/OutletCard";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { outletUrl } from "../../utils/urls";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomLoader from "../../components/layoutUI/CustomLoader";
import PaperBox from "../../components/layoutUI/PaperBox";
import NoRowsSvg from "../../assets/images/no-rows.png";
import Headline3 from "../../components/typographyUI/Headline3";
import CustomMenu from "../../components/layoutUI/CustomMenu";
import { setOutletDuplicate } from "../../Redux Store/Slices/duplicateOutlet";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const Outlets = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  //
  // const [page, setPage] = useState(1);
  const page = useRef(1);
  const [list, setList] = useState([]);
  const [totalOutlets, setTotalOutlets] = useState(0);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");

  const getOutlets = async (isMerge) => {
    let url = `${outletUrl}${user?.outletId}?page=${
      search ? page.current : "1"
    }&perPage=10&searchText=${search}`;
    //
    //
    let isInclude =
      user.address.toLowerCase().includes(search.toLowerCase()) ||
      user.outletName.toLowerCase().includes(search.toLowerCase());
    //
    axios
      .get(url)
      .then((res) => {
        //
        let _user = { ...user, isPrimaryOutlet: true };
        if (isMerge) {
          let newList = [...list, ...res?.data?.data];
          if (isInclude) newList.unshift(_user);
          setList(newList);
          setTotalOutlets(res?.data?.meta?.totalCount + (isInclude ? 1 : 0));
        } else {
          let newList = [...res.data.data];
          // if(!search) newList.unshift(_user)
          if (isInclude) newList.unshift(_user);

          setList(newList);
          setTotalOutlets(res?.data?.meta?.totalCount + (isInclude ? 1 : 0));
        }
        // setPage(page + 1);
        page.current++;
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const fetchList = async (isMerge) => {
    setLoading(true);
    await getOutlets(isMerge);
  };

  useEffect(() => {
    if (search) {
      page.current = 1;
    }
    setList([]);
    fetchList(false);
  }, [search]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box sx={{ mb: 4 }}>
        <PageHeader
          title={"Outlets"}
          subTitle={"Your all outlets are here"}
          rightUi={
            <Box style={{ display: "flex", gap: "1rem" }}>
              <CustomButton
                onClick={() => {
                  navigate("/outlets/onboard");
                  dispatch(setOutletDuplicate(false));
                }}
                startIcon={<Add />}
              >
                Add Outlet
              </CustomButton>
              <CustomButton
                onClick={() => {
                  dispatch(setOutletDuplicate(true));
                  navigate(`/outlets/editOutlet/${user?.outletId}`);
                }}
                startIcon={<ContentCopyOutlinedIcon style={{fontSize:"1.5rem"}}/>}
              >
                Duplicate Outlet
              </CustomButton>
              {/* <CustomMenu
                stepGuide
                width={"220px"}
                menuList={[
                  {
                    id: 1,
                    label: "Duplicate Outlet",
                    className: "custom-menu-item-3",
                  },
                  {
                    id: 2,
                    label: "Add New Outlet",
                    className: "custom-menu-item-1",
                  },
                ]}
                // onClick={() => setCloseGuide(true)}
                menuOnClick={({ label, id: _id }) => {
                  if (_id == 1) {
                    navigate(`/outlets/editOutlet/${user?.outletId}`);
                  } else {
                    navigate("/outlets/onboard");
                  }
                }}
              /> */}
            </Box>
          }
          bottomBorder
        />

        <StackRow between mb={"1.71rem"}>
          <CustomSearchTextField
            placeholder="Search by name/address"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* <CustomFilter /> */}
        </StackRow>
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            <InfiniteScroll
              dataLength={list.length} //This is important field to render the next data
              next={() => getOutlets(true)}
              hasMore={loading || list.length !== totalOutlets}
              loader={
                <Grid
                  container
                  spacing={"24px"}
                  sx={{
                    mt: 2,
                  }}
                >
                  {new Array(6).fill(0).map((sk, index) => (
                    <Grid key={index} item xs={12} md={4}>
                      <PaperBox
                        padding={"12px"}
                        sx={{
                          position: "relative",
                          cursor: "pointer",
                          transition: "0.3s",
                        }}
                      >
                        <Skeleton
                          height={"288.78px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 2,
                          }}
                          animation="wave"
                        />
                        <StackRow gap={2}>
                          <Skeleton
                            height={"60px"}
                            width={"60px"}
                            sx={{
                              transform: "scale(1)",
                              transformOrigin: "0",
                            }}
                            animation="wave"
                          />
                          <Stack spacing={1}>
                            <Skeleton
                              height={"3ch"}
                              width={"160px"}
                              sx={{
                                transform: "scale(1)",
                                transformOrigin: "0",
                                mb: 1,
                              }}
                              animation="wave"
                            />
                            <Skeleton
                              height={"2ch"}
                              width={"120px"}
                              sx={{
                                transform: "scale(1)",
                                transformOrigin: "0",
                              }}
                              animation="wave"
                            />
                          </Stack>
                        </StackRow>
                      </PaperBox>
                    </Grid>
                  ))}
                </Grid>
              }
              // endMessage={
              //   loading == false &&
              //   list.length == 0 && (
              //     <Text
              //       sx={{
              //         width: "100%",
              //       }}
              //     >
              //       <b>Not Outlets yet.</b>
              //     </Text>
              //   )
              // }
            >
              <Grid container spacing={"24px"}>
                {/* {!loading && (
              <OutletCard
                isPrimaryOutlet
                key={user?.outletId}
                img={
                  user?.headerImage ||
                  "https://placehold.co/300x200?text=MealPE"
                }
                logo={user?.logo}
                title={user?.outletName}
                subTitle={user?.address}
              />
            )} */}
                {list.map((outlet, index) => (
                  <OutletCard
                    isPrimaryOutlet={outlet.isPrimaryOutlet}
                    key={outlet.outletId}
                    img={
                      outlet.headerImage ||
                      "https://placehold.co/300x200?text=MealPE"
                    }
                    logo={
                      outlet?.isPrimaryOutlet
                        ? outlet.logo
                        : outlet?.primaryOutletId?.logo
                    }
                    title={outlet.outletName}
                    subTitle={outlet.address}
                    isActive={outlet?.isActive}
                    outletId={outlet?.outletId}
                  />
                ))}
                {!list.length && (
                  <Box
                    sx={{
                      display: "grid",
                      placeContent: "center",
                      margin: "auto",
                      height: "70vh",
                    }}
                  >
                    <Stack spacing={0} alignItems={"center"}>
                      <img src={NoRowsSvg} />
                      <Headline3>No Outlets found</Headline3>
                    </Stack>
                  </Box>
                )}
              </Grid>
            </InfiniteScroll>
          </>
        )}
      </Box>
    </motion.div>
  );
};

export default Outlets;
