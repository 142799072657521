import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  pendingOrders: [],
  pendingTOCancelOrders: [],
};

export const pendingOrder = createSlice({
  name: "pendingOrder",
  initialState,
  reducers: {
    setRealtimeOrder: (state, action) => {
      state.pendingOrders = [action.payload,...state.pendingOrders].flat()
    },
    setpendingTOCancelOrders: (state, action) => {
      state.pendingTOCancelOrders = [action.payload,...state.pendingTOCancelOrders].flat()
    },
    clearPendingOrder:(state, action) => {
      state.pendingOrders=[]
    },
    removeRealTimeOrder: (state, action) => {
      state.pendingOrders = state.pendingOrders.filter(f => f?.order_id !== action.payload)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRealtimeOrder, setpendingTOCancelOrders, removeRealTimeOrder, clearPendingOrder } = pendingOrder.actions;

export default pendingOrder.reducer;