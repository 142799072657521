import { Stack } from "@mui/material";
import React from "react";

const StackRow = ({ children, between, isFlexEnd, center, ...otherProps }) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={center ? "center" : "flex-start"}
      justifyContent= {isFlexEnd ? 'flex-end' : between ? 'space-between' : 'flex-start'}
      gap={"4px"}
      {...otherProps}
    >
      {children}
    </Stack>
  );
};

export default StackRow;
