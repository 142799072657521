import { useEffect, useState } from "react";
import StackRow from "../../layoutUI/StackRow";
import CustomChip from "../CustomChip";
import { IconButton } from "@mui/material";
import ButtonPng from "../../../assets/icons/Button.png";
import CloseButtonPng from "../../../assets/icons/CloseButton.png";
import CustomButton from "../../formUI/CustomButton";
import ConfirmationModal from "./ConfirmationModal";

export const OrderStatus = ({
  row,
  noChip = false,
  noRejectBtn = false,
  handleAccept,
  handleReject,
  tab,
  noAction = false,
  button = false,
  title = "",
  disable = false,
  stopLoader,
  setStopLoader,
  confirmationModal,
  setConfirmationModal,
  bypass,
}) => {
  const [status, setStatus] = useState("Pending");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    setStatus(
      row?.orderId?.orderStatusId?.text
        ? row?.orderId?.orderStatusId?.text
        : row?.order_status_text
    );
  }, [row]);
  useEffect(() => {
    if (stopLoader) {
      setLoading2(false);
      setStopLoader(false);
    }
  }, [stopLoader]);
  const [chipColor] = useState({
    orderaccepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    orderplaced: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    reject: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    orderdelivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    preparingorder: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });

  return (
    <StackRow center={true}>
      {!noChip && (
        <CustomChip
          label={status}
          textColor={
            chipColor[status?.toLowerCase()?.replaceAll(" ", "")]?.textColor
          }
          backgroundColor={
            chipColor[status?.toLowerCase()?.replaceAll(" ", "")]
              ?.backgroundColor
          }
          sx={{
            mr: "0.5rem",
          }}
        />
      )}
      {button && (
        <>
          <StackRow center>
            {!noRejectBtn && (
              <CustomButton
                // type="submit"
                variant="outlined"
                color="error"
                sx={{
                  fontWeight: "400",
                  mr: 2,
                  // filter : 'grayscale(1)',
                  zoom: "0.8",
                }}
                loading={loading1}
                disabled={loading2}
                onClick={() => {
                  setStatus("Reject");
                  setLoading1(true);
                  handleReject(row?.order_id);
                }}
              >
                Reject
              </CustomButton>
            )}
            <CustomButton
              // type="submit"
              sx={{
                fontWeight: "400",
                zoom: "0.8",
              }}
              loading={loading2}
              disabled={disable || loading1}
              onClick={() => {
              
                if (!bypass) {
                  setStatus("Accepted");
                  setLoading2(true);

                  handleAccept(row?.order_id);
                } else {
                  setConfirmationModal({
                    text1: "Confirm Bypassing Verification",
                    text2: "Are you sure you wish to verify without OTP?",
                    btn1Text: "Confirm",
                    btn2Text: "Cancel",
                    btn1Handler: () => handleAccept(row?.order_id),
                    btn2Handler: () => setConfirmationModal(null),
                  });
                }
              }}
            >
              {title}
            </CustomButton>
          </StackRow>
        </>
      )}
    </StackRow>
  );
};
