import React, { useEffect } from "react";
import { toast } from "react-toastify";

const FormikErrorScroller = ({ errors, paddingTop = 400, dep }) => {
  useEffect(() => {
    let err = typeof Object.entries(errors)?.[0] == 'object'? Object.entries(errors)?.[0] :  Object.entries(errors)?.[0]?.[1];
    let FIELD_NAME = null;
    let ERROR_NAME = null;
    if (typeof Object.entries(errors)?.[0]?.[1] == "string") {
      FIELD_NAME = Object.entries(errors)?.[0]?.[0];
      ERROR_NAME = Object.entries(errors)?.[0]?.[1];
    } else {
      FIELD_NAME = Object.entries(errors)?.[0]?.[1]
        ? Object.entries(Object.entries(errors)?.[0]?.[1])?.[0]?.[0]
        : null;
        ERROR_NAME = Object.entries(errors)?.[0]?.[1]
        ? Object.entries(Object.entries(errors)?.[0]?.[1])?.[0]?.[1]
        : null;
      
    }

    const Scroll = (err) => {
      //   let err = Object.entries(errors)?.[0]?.[0];
      let htmlInputView = document.querySelector(`input[name="${err}"]`);
      htmlInputView?.scrollIntoView();
        // window.scrollBy(0, paddingTop);
    };
    // toast.error(ERROR_NAME)
    Scroll(err?.length == 2 ? err[0] + '.'+ FIELD_NAME : FIELD_NAME);
    
  }, [errors, paddingTop, dep]);
  return <></>;
};

export default FormikErrorScroller;
