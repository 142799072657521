import React, { useEffect, useState } from "react";
import cssClasses from "./FetchMenuModal.module.css";
import { Avatar, Box, Modal, Typography } from "@mui/material";
import CustomButton from "../../../components/formUI/CustomButton";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  addMenuFromPetPooja,
  getPetpoojaMenuForSelection,
  updatePetPoojaConfigs,
} from "../../../Services/menuService";
import CircularProgress from "@mui/material/CircularProgress";
import dummyImage from "../../../assets/images/item.png";
import { toast } from "react-toastify";
import { authLogin } from "../../../Redux Store/Slices/auth";

function FetchMenuModal({ open, handleClose, setDep = () => {} }) {
  const dispatch = useDispatch();
  const outletDetails = useSelector((state) => state.auth.user);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // if (outletDetails?.outletId) {
    getPetpoojaMenuForSelectionFromServer();
    // }
  }, []);

  async function getPetpoojaMenuForSelectionFromServer() {
    try {
      setIsLoading(true);
      const getPetpoojaMenuForSelectionResponse =
        await getPetpoojaMenuForSelection({
          outletId: outletDetails?.outletId,
        });

      setIsLoading(false);
      setDep(Math.random());
      setItemList([...getPetpoojaMenuForSelectionResponse.data.data.items]);
    } catch (error) {}
  }

  async function addSelectedMenu() {
    setIsLoading(true);
    for (const itemId of selectedItem) {
      let itemObject = itemList.find((f) => f.itemid === itemId);

      const postBody = {
        itemname: itemObject?.itemname,
        price: +itemObject?.price,
        itemdescription: itemObject?.itemdescription,
        minimumpreparationtime: +itemObject?.minimumpreparationtime,
        kcal: "1",
        servinginfo: "",
        outletId: outletDetails?.outletId,
        petpoojaItemId: itemObject?.itemid
      };

      const addMenuFromPetPoojaResponse = await addMenuFromPetPooja(postBody);
    }

    if (outletDetails?.publishProcessingStep <= 1) {
      const postData = {
        publishProcessingStep: 2,
      };
      const updatePetPoojaConfigsResponse = await updatePetPoojaConfigs(
        postData,
        outletDetails?.outletId
      );

      if (updatePetPoojaConfigsResponse?.data?.success) {
        dispatch(authLogin({ ...outletDetails, ...postData }));
      }
    }

    setIsLoading(false);
    setDep(Math.random());
    toast.success("Menu items added successfully.");
    handleClose();
  }

  return (
    <Modal
      open={open}
      // onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[{ ".MuiBackdrop-root": { background: "rgba(193, 201, 210, 0.7)" } }]}
      disableAutoFocus={true}
    >
      <Box
        className={cssClasses.modalWrapper}
        sx={{
          "& .MuiDataGrid-virtualScrollerRenderZone ,& .MuiDataGrid-columnHeadersInner":
            {
              transform: "none!important",
            },
        }}
      >
        <div className={cssClasses.header}>
          {/* <img src={MessageIcon} alt="" /> */}
          <h1>
            Menu <span className={cssClasses.petPoojaChip}>PetPooja</span>
          </h1>
          <p className={cssClasses.subHeading}>
            View and update your store details
          </p>
          {/* <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={() => handleClose(false)} /> */}
        </div>

        <Box className={cssClasses.mainContainer}>
          {isLoading ? (
            <>
              <Box className={cssClasses.loadingBox}>
                <CircularProgress />
              </Box>
            </>
          ) : (
            <>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"1rem"}
                mb={"10px"}
              >
                <Typography className={cssClasses.selectedFoodTitle}>
                  Food Items
                </Typography>
                <Typography className={cssClasses.selectedFoodTitle}>
                  Selected ({selectedItem.length || 0})
                </Typography>
              </Box>
              <Box flex={1} className={cssClasses.tableContainer}>
                <DataGrid
                  rows={itemList}
                  columns={itemColumns}
                  hideFooterPagination={false}
                  hideFooter={false}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  // page={pageMaintain}
                  // sx={[dataGridStyles]}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnSelector
                  disableDensitySelector
                  disableSelectionOnClick
                  disableVirtualization
                  getRowId={(row) => {
                    //
                    return row?.itemid;
                  }}
                  checkboxSelection
                  disableRowSelectionOnClick
                  // loading={dataGridLoading}
                  // onPageChange={(_page)=>{
                  //
                  //   setPageMaintain(_page)
                  //   if (pageNumber < _page) {
                  //     // * Next When Data need
                  //     setPageNumber(_page);
                  //     getMoreData()
                  //   }else{
                  //     // *previous
                  //   }
                  // }}

                  onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectedItem([...newSelectionModel]);
                    // const selectedIDs = new Set(newSelectionModel);
                    // const selectedRowData = examCentersOriginalList.filter((row) =>
                    // selectedIDs.has(row.id.toString())
                    // );
                    // //
                    // setSelectedExamCenterList([...selectedRowData])
                  }}
                />
              </Box>
            </>
          )}
        </Box>

        <div className={cssClasses.footer}>
          <div>
            <CustomButton
            variant='outlined'
            //   sx={{
            //     color: "#344054",
            //     backgroundColor: "#ffffff",
            //     border: "1px solid #E5E7EB",
            //   }}
              disabled={Boolean(isLoading)}
              onClick={() => handleClose(false)}
            >
              Cancel
            </CustomButton>
          </div>
          <div>
            <CustomButton
            //   sx={{ color: "#ffffff", backgroundColor: "#2563EB" }}
              disabled={!Boolean(selectedItem?.length > 0) || isLoading}
              onClick={() => addSelectedMenu()}
            >
              Add
            </CustomButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default FetchMenuModal;

const itemColumns = [
  { field: "itemid", headerName: "Item ID", width: 150, sortable: false },
  {
    field: "itemname",
    headerName: "Item Name",
    width: 300,
    sortable: false,
    renderCell: (params) => {
      return (
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          {/* <Avatar src={""} style={{width: "40px", height: "40px"}}/> */}
          <img
            className={cssClasses.foodImages}
            src={params.row.item_image_url || dummyImage}
            style={{ width: "40px", height: "40px" }}
          />
          <Box ml={"12px"} display={"flex"} flexDirection={"column"}>
            <Typography
              color={"#101828"}
              fontSize={"14px"}
              lineHeight={"20px"}
              fontWeight={500}
            >
              {params.row.itemname}
            </Typography>
            <Typography
              color={"#667085"}
              fontSize={"14px"}
              lineHeight={"20px"}
              fontWeight={400}
            ></Typography>
          </Box>
        </Box>
      );
    },
  },
  { field: "price", headerName: "Price", width: 150, sortable: false },
];

const dataGridStyles = {
  "font-size": "14px",
  "font-style": "normal",
  "font-weight": "500",

  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  "& 	.MuiDataGrid-columnHeaders": {
    background: "#F9FAFB",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "20px",
      height: "20px",
      background: "#FFFFFF",
    },
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none",
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "10px",
    paddingRight: "10px",
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "10px",
    paddingLeft: "10px",
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "hidden !important",
  },
  "&  .MuiDataGrid-row ": {
    maxHeight: "72px !important",
    minHeight: "72px !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "1.6rem",
    minHeight: "72px !important",
    maxHeight: "72px !important",
    outline: "none !important",
  },
  "& .MuiButtonBase-root": {
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    margin: "0 1rem",
  },
  "& .MuiTablePagination-actions": {
    margin: "0 20px",
  },
};
