import { Typography } from "@mui/material";
import React from "react";

const Headline2 = ({ children, sx, ...otherProps }) => {
  return (
    <Typography
      component="h1"
      fontWeight={500}
      fontSize={"1.7rem"}
      sx={sx}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default Headline2;
