import { Box, Grid, InputAdornment, Stack } from "@mui/material";
import React from "react";
import FormHeader from "../../../formUI/FormHeader";
import CustomTextField from "../../../formUI/CustomTextField";
import CustomSelect from "../../../formUI/CustomSelect";
import { Form, Formik, useFormik } from "formik";
import CustomButton from "../../../formUI/CustomButton";
import * as Yup from "yup";
import CustomTextFieldFormik from "../../../formUI/CustomTextFieldFormik";
import { mobilePattern, panCardPattern } from "../../../../utils/regex";
import CustomSelectFormik from "../../../formUI/CustomSelectFormik";
import StackRow from "../../../layoutUI/StackRow";
const OutletManager = ({ formData, onSetState, tabIndexHandler }) => {
  const handlenBack = () => {
    tabIndexHandler(1)
  }
  return (
    <Formik
      initialValues={{
        ...formData,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Outlet Manager Name is required"),
        // address: Yup.string().required("Address is required"),
        // email: Yup.string()
        //   .email("Invalid email")
        //   .required("Email is required"),
        // mobile: Yup.string()
        //   .required("Mobile number is required")
        //   .matches(mobilePattern, "Invalid mobile number"),
        pancard: Yup.string()
          .required("PAN Card is required")
          .matches(panCardPattern, "Invalid PAN Card"),
      })}
      onSubmit={(values) => {
        onSetState((prev) => ({ ...prev, ...values }));
        tabIndexHandler(3);
      }}
    >
      <Form>
        <Stack
          sx={{
            minHeight: "550px",
          }}
        >
          <FormHeader>Outlet manager Details:</FormHeader>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <CustomTextFieldFormik
                label="Outlet manager Name"
                placeholder="Enter Outlet manager Name"
                required
                name="name"
              />
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <CustomTextFieldFormik
                label="Outlet manager number"
                placeholder="Enter Outlet manager number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                name="mobile"
              />
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <CustomTextFieldFormik
                label="Outlet manager Email"
                placeholder="Enter Outlet manager Email"
                required
                name="email"
              />
            </Grid> */}
            <Grid item xs={12} md={8}>
              <CustomTextFieldFormik
                label="Address"
                placeholder="Enter Address"
                // required
                name="address"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomTextFieldFormik
                label="PAN Card number"
                placeholder="Enter PAN Card number"
                required
                name="pancard"
              />
            </Grid>
          </Grid>

          <StackRow
            justifyContent="flex-end"
            sx={{
              marginTop: "auto",
            }}
          >
            <CustomButton
              // type="submit"
              variant="outlined"
              sx={{
                fontWeight: "400",
                mt: 2,
                mr: 2,
              }}
              onClick ={handlenBack}
            >
              Back
            </CustomButton>
            <CustomButton
              type="submit"
              sx={{
                fontWeight: "400",
                mt: 2,
              }}
            >
              Save
            </CustomButton>
          </StackRow>
        </Stack>
      </Form>
    </Formik>
  );
};

export default OutletManager;
