import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";
import ItemCard from "../../../components/pageUI/ItemCard";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import CustomDataGrid from "../../../components/pageUI/CustomDataGrid";
import CustomCheckbox from "../../../components/formUI/CustomCheckbox";
import CustomSearchTextField from "../../../components/formUI/CustomSearchTextField";

const CustomMenuAdd = ({
  url,
  header,
  rowId,
  selected,
  onChange = () => {},
  searchItem = false,
  parentCategory = [],
  parentSubCategory,
  itemsTab = false,
  subCategoryTab = false,
  categoryTab = false,
  handler = () => {},
  alert = () => {},
}) => {
  const { user } = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");

  const getFilterData = (data) => {
    let _data = data;
    if (parentSubCategory && parentSubCategory.length > 0) {
      _data = data.map((e) => {
        let matchFound = false;

        for (const parent of parentSubCategory) {
          if (parent.categoryid === e.item_categoryid) {
            matchFound = true;
            break;
          }
        }

        if (matchFound) {
          return { ...e, disabled: false };
        } else {
          return { ...e, disabled: true };
        }
      });
    }
    if (parentCategory && parentCategory.length > 0) {
      // setData(_data);
      _data = data.map((e) => {
        let matchFound = false;
        for (const parent of parentCategory) {
          if (parent.parent_category_id == e.parent_category_id) {
            matchFound = true;
            break;
          }
        }

        if (matchFound) {
          return { ...e, disabled: false };
        } else {
          return { ...e, disabled: true };
        }
      });
    }
    if (itemsTab && !parentSubCategory.length) {
      _data = data.map((e) => ({ ...e, disabled: true }));
    }
    if (subCategoryTab && !parentCategory.length) {
      _data = data.map((e) => ({ ...e, disabled: true }));
    }
    if (itemsTab) {
      _data = _data.map((e) => {
        if (!e.item_categoryid) {
          return { ...e, disabled: false };
        }
        return e
      });
    }
    if (search) {
      _data = _data?.filter((row) =>
        row?.itemname.toLowerCase().includes(search.toLowerCase())
      );
    }
    return _data;
  };

  const columns = useMemo(() => [
    {
      field: "select",
      headerName: "",
      maxWidth: 50,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <CustomCheckbox
            disabled={row.disabled || false}
            onClick={() => row.disabled && alert(row)}
            label=""
            checked={selected?.find((f) => f[rowId] == row[rowId])}
            onChange={(e) => {
              const isTrue = e.target.checked;
              if (isTrue) {
                onChange((prev) => {
                  handler([...prev, row]);
                  return [...prev, row];
                });
              } else {
                onChange((prev) => {
                  handler(prev?.filter((r) => r[rowId] != row[rowId]));
                  return prev?.filter((r) => r[rowId] != row[rowId]);
                });
              }
            }}
          />
        </>
      ),
    },
    {
      field: "category",
      headerName: `${header}`,
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          center
          sx={{
            height: 36,
          }}
          title={row?.parentCategoryName || row.itemname || row.categoryname}
          img={
            row.parent_category_image_url ||
            row.item_image_url ||
            row.category_image_url
          }
        />
      ),
    },
  ]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        {searchItem && (
          <CustomSearchTextField
            placeholder="Search Item"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ marginBottom: "10px", width: "100%", maxWidth: "100%" }}
          />
        )}
        <CustomDataGrid
          rowId={`${rowId}`}
          name={rowId}
          url={url + user?.primaryOutletId}
          height={"500px"}
          columns={columns}
          labelName={rowId}
          noActions
          filterFn={getFilterData}
        />
      </Box>
    </motion.div>
  );
};

export default CustomMenuAdd;
