import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OTPVerification, OTPVerificationButton } from "./ReadyOrders";
import {
  useSmallScreen,
  isMobile,
  useMediumScreen,
} from "../../../utils/mediaQueries";

export default function ConfirmationModal({ modalData }) {
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const modalStyle = {
    position: "fixed",
    inset: "0",
    zIndex: "1000",
    display: "grid",
    placeItems: "center",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(0px)",
  };

  const containerStyle = {
    position: "relative",
    width: `${isSmallScreen ? "auto" : "650px"}`,
    height: `auto`,
    borderRadius: "0.5rem",
    backgroundColor: "white",
    color: "black",
    padding: "1.5rem",
    animation: "modalFadeIn 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)",
    transformOrigin: "center",
    boxShadow:
      "0px 10px 10px rgba(15, 23, 42, 0.05), 0px 20px 25px -5px rgba(15, 23, 42, 0.1)",
  };

  const text1Style = {
    fontSize: "2rem",
    fontWeight: "600",
  };

  const text2Style = {
    marginTop: "-1.5rem",
    marginLeft: "4px",
    color: "#828282",
    fontSize: "1.2rem",
  };

  const buttonContainerStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    marginTop: "3rem",
  };

  const buttonStyle = {
    width: "auto",
    minWidth: "160px",
    height: "3.2rem",
    backgroundColor: "#4c44f4",
    color: "white",
    fontWeight: "600",
    padding: "0.8rem 1.5rem",
    borderRadius: "0.375rem",
    border: "none",
    cursor: "pointer",
    textAlign: "center",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  };

  const button2Style = {
    width: "auto",
    minWidth: "160px",
    height: "3.2rem",
    backgroundColor: "white",
    color: "black",
    fontWeight: "600",
    padding: "0.8rem 1.5rem",
    borderRadius: "0.375rem",
    border: "1.5px solid #afafaf",
    cursor: "pointer",
    textAlign: "center",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  };

  return (
    <div style={modalStyle}>
      <div style={containerStyle}>
        <IconButton
          style={{ position: "absolute", top: "1rem", right: "1rem" }}
          onClick={modalData?.btn2Handler}
        >
          <CloseIcon />
        </IconButton>
        <p style={text1Style}>{modalData?.text1}</p>
        <p style={text2Style}>{modalData?.text2}</p>
        <div>
          {modalData?.codOrder && (
            <div style={{ display: "flex", gap: "1rem" }}>
              {" "}
              <OTPVerification
                row={modalData?.row}
                verifyOtp={modalData?.verifyOtp}
                chipColor={modalData?.chipColor}
                stopLoader={modalData?.stopLoader}
                setStopLoader={modalData?.setStopLoader}
              />
              <OTPVerificationButton
                row={modalData?.row}
                verifyOtp={modalData?.verifyBypassOtp}
                chipColor={modalData?.chipColor}
                stopLoader={modalData?.stopLoader}
                setStopLoader={modalData?.setStopLoader}
                confirmationModal={modalData?.confirmationModal}
                setConfirmationModal={modalData?.setConfirmationModal}
                bypass={modalData?.bypass}
              />
            </div>
          )}
        </div>
        {!modalData?.codOrder && (
          <div style={buttonContainerStyle}>
            <button
              onClick={modalData?.btn1Handler}
              style={buttonStyle}
              className="buttonHover"
            >
              {modalData?.btn1Text}
            </button>
            <button
              style={button2Style}
              onClick={modalData?.btn2Handler}
              className="button2Hover"
            >
              {modalData?.btn2Text}
            </button>
          </div>
        )}
      </div>
      <style>
        {`
          @keyframes modalFadeIn {
            0% {
              opacity: 0;
              transform: scale(0.8);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }

          .buttonHover:hover {
            opacity:90%;
          }

          .buttonHover:active {
            transform: translateY(0);
          }

           .button2Hover:hover {
            opacity:90%;
            
          }

          .button2Hover:active {
            transform: translateY(0);
          }
        `}
      </style>
    </div>
  );
}
