import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";
import PageHeader from "../PageHeader";
import CustomButton from "../../formUI/CustomButton";
import CustomDataGrid from "../CustomDataGrid";
import ItemCard from "../ItemCard";
import Text from "../../typographyUI/Text";
import CustomChip from "../CustomChip";
import StaffDialog from "../../layoutUI/StaffDialog";
import { useGetApis } from "../../../hooks/useGetApis";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import axios from "axios";
import ServerPaginationDataGrid from "../ServerPaginationDataGrid";
import CustomSwitch from "../../formUI/CustomSwitch";

const Staff = () => {
  const { staffGetUrl, subCategoryGetUrl } = useGetApis();

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [dep, setDep] = useState(Math.random());

  const columns = useMemo(() => [
    {
      field: "name",
      headerName: "Name & Email",
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "50%",
          }}
          // img={row.category_image_url}
          title={row.name}
          subTitle={row.email}
          center
          sx={{
            height: 36,
          }}
        />
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      minWidth: 220,
      sortable: false,
      renderCell: ({ row }) => <Text>{row.mobile}</Text>,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 160,
      sortable: false,
      renderCell: ({ row }) => <Text>{row.roleId.role}</Text>,
      // renderCell: ({ row }) =>
    },
    {
      field: "access",
      headerName: "Access",
      flex: 1,
      minWidth: 160,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          backgroundColor={row.isActive ? "#F0FDF4" : "#FFFBEB"}
          textColor={row.isActive ? "#15803D" : "#B45309"}
          label={row.isActive ? "Active" : "Pending"}
        />
      ),
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <StaffDialog
          onSuccess={() => setDep(Math.random())}
          width={"650px"}
          title="Update Staff"
          subTitle={"Update User for Restaurant Management"}
          // defaultData={{
          //   name: "Arbaj",
          //   email: "email",
          //   mobile_number: "124",
          //   role: "1",
          // }}
          defaultData={row}
          buttonComp={(props) => (
            <Text
              color="green"
              bold
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              {...props}
            >
              Edit
            </Text>
          )}
        ></StaffDialog>
      ),
    },
  ]);

  const columns2 = useMemo(() => [
    {
      field: "category",
      headerName: "Sub Categories",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          img={row.category_image_url}
          title={row.categoryname}
          center
          sx={{
            height: 36,
          }}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomSwitch
          value={row.status}
          label="In Stock"
          onChange={(e) => {
            // mutatePut({ ...row, status: e });
          }}
        />
      ),
    },
  ]);

  return (
    <Box>
      <CustomDataGrid
        dep={dep}
        rowId={"outletStaffAuthUId"}
        name="staff-table"
        // url={staffGetUrl + (user.outletId || user.restaurantId)}
        url={
          process.env.REACT_APP_BACKEND_BASE_URL +
          `staff/staff/getStaff/` +
          `${user?.outletId ? user?.outletId : user?.restaurantId}`
        }
        // height={"750px"}
        columns={columns}
        labelName="Staff"
        noActions
        header={({ refetch }) => {
          return (
            <PageHeader
              secondary
              title="Staff"
              subTitle={"View and update your store details."}
              rightUi={
                <StaffDialog
                  refetch={refetch}
                  buttonComp={(props) => (
                    <CustomButton {...props}>Add Staff</CustomButton>
                  )}
                  width={"650px"}
                  title="Add Staff"
                  subTitle={"Add User for Restaurant Management"}
                />
              }
            />
          );
        }}
        noDataScreen
      />
    </Box>
  );
};

export default Staff;
