import React from "react";

const LogoutSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 13.3332V14.3332C13.3334 16.5423 11.5425 18.3332 9.33338 18.3332H6.50004C4.2909 18.3332 2.50004 16.5423 2.50004 14.3332V5.6665C2.50004 3.45737 4.2909 1.6665 6.50004 1.6665H9.33337C11.5425 1.6665 13.3334 3.45737 13.3334 5.6665V6.6665"
        stroke="#334155"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.8334 12.5L17.6263 10.7071C18.0168 10.3166 18.0168 9.68342 17.6263 9.29289L15.8334 7.5"
        stroke="#334155"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17.5 10L7.5 10"
        stroke="#334155"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LogoutSvg;
