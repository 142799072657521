import { Box, Divider, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import PaperBox from "../../components/layoutUI/PaperBox";
import StackRow from "../../components/layoutUI/StackRow";
import Text from "../../components/typographyUI/Text";
import Lead from "../../components/typographyUI/Lead";
import Headline4 from "../../components/typographyUI/Headline4";
import CustomChip from "../../components/pageUI/CustomChip";
import CustomButton from "../../components/formUI/CustomButton";
import LeftIcon from "../../assets/icons/LeftIcon.svg";
import cssClasses from "./Kot.module.css";
import PageHeader from "../../components/pageUI/PageHeader";
import Headline3 from "../../components/typographyUI/Headline3";
import NoRowsSvg from "../../assets/images/no-rows.png";

const Kot = () => {
  const KotData = useSelector((state) => state.kot.kot);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        flexWrap={"wrap"}
        gap={"1.71rem"}
      >
        <PageHeader
          secondary
          title={"KOT"}
          subTitle={"Your all KOTs are here"}
          bottomBorder
        />
        {KotData.map((order) => (
          <Box key={order?.order_id} className={cssClasses.cardBoxContainer}>
            <KotCard key={order?.order_id} order={order} />
          </Box>
        ))}
        {!KotData.length && (
          <Box
            sx={{
              display: "grid",
              placeContent: "center",
              margin: "auto",
              height: "70vh",
            }}
          >
            <Stack spacing={0} alignItems={"center"}>
              <img src={NoRowsSvg} />
              <Headline3>No KOTs available right now</Headline3>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Kot;

export function convertTo12HourTime(inputTime, addMinutes) {
  const timeParts = inputTime.split(":");
  let hours = parseInt(timeParts[0]);
  let minutes = parseInt(timeParts[1]);

  // Add the specified number of minutes
  minutes += addMinutes;

  // Adjust hours if minutes overflow
  if (minutes >= 60) {
    hours += Math.floor(minutes / 60);
    minutes %= 60;
  }

  const period = hours >= 12 ? "PM" : "AM";

  const hours12 = hours % 12 || 12;
  const formattedTime = `${hours12}:${
    minutes < 10 ? "0" + minutes : minutes
  } ${period}`;
  return formattedTime;
}
export const KotCard = ({ order }) => {
  const { user } = useSelector((state) => state.auth);

  function convertTo12HourFormat(time, preparationTime, addTime) {
    // Remove seconds
    time = time.split(":").slice(0, 2).join(":");

    // Convert time to total minutes
    let [hours, minutes] = time.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes;

    // Adjust time based on the boolean value
    if (addTime) {
      totalMinutes += preparationTime + 15;
    }

    // Calculate the new time
    let newHours = Math.floor(totalMinutes / 60) % 24;
    let newMinutes = totalMinutes % 60;

    // Determine AM or PM
    let period = newHours >= 12 ? "PM" : "AM";

    // Adjust hours for 12-hour format
    if (newHours > 12) {
      newHours -= 12;
    } else if (newHours === 0) {
      newHours = 12;
    }

    // Format the time in 12-hour format
    let time12Hour = `${newHours}:${
      newMinutes < 10 ? "0" : ""
    }${newMinutes} ${period}`;

    return time12Hour;
  }

  const handlePrint = (order) => {
    var content = document.createElement("div");
    content.innerHTML = getHPrintHTML(order);

    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    // var pri = document.createElement('iframe');
    // pri = pri.contentWindow;

    pri.document.open();
    pri.document.write(content.innerHTML);
    // pri.document.write('<style type="text/css">@media print { @page { size: 80mm; margin: 0; } body { margin: 1.6cm; } }</style>');
    pri.document.write(`
    <style type="text/css">
      @media print {
        @page {
            margin-top: 0;
            margin-bottom: 0;
        }
        body {
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 !important;
        }
      }
    </style>
    `);

    pri.document.close();
    pri.focus();
    pri.print();
  };
  function getHPrintHTML(order) {
    const itemsHTML = order?.menu_item.map(
      (item) =>
        `<div>
          <div style="float:left; margin-bottom:1px;">
          ${
            order?.additional_instruction
              ? "[note] : " + order?.additional_instruction
              : ""
          }
          </div><br/>
          <div style="float:left; font-weight: bold;">${item?.itemname} ${
          item?.variation?.variation_name
            ? ` ${item?.variation?.variation_name}`
            : ""
        } x ${item?.quantity}  
          </div>
          <div style="float:right; font-weight: bold;">
            ₹${
              item?.variation?.variation_price
                ? item?.variation?.variation_price
                : item?.calculated_price
            }
          </div>
          <div style="clear:both"></div>
           ${
             item?.addons?.length
               ? item?.addons
                   ?.map(
                     (addon) =>
                       `<div>
                    <div style="float:left;">${addon?.name}</div>
                    <div style="float:right;">₹${addon?.price}</div>
                    <div style="clear:both"></div>
                  </div>`
                   )
                   .join("")
               : ""
           }
        </div>`
    );

    const _html = `
      <div style="border:1px solid lightgray; height:auto; width: 65mm; margin:auto; padding:5px">
        <div style="text-align:center; font-size:18px; font-weight:600">${
          user?.outletName
        }</div>
        <div style="text-align:center">${user?.address}</div>
        <div style="text-align:center">Mobile: + 91 ${user?.mobile}</div>
        <div style="text-align:center">Email: ${user?.email}</div>
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
    
        <div>
          <div style="float:left">${order?.order_schedule_date}</div>
          <div style="float:right">${convertTo12HourFormat(
            order?.order_schedule_time,
            order?.preparation_time,
            order?.schedule_now
          )}</div>
          <div style="clear:both"></div>
        </div>
        <div style="clear: both;border-bottom: 1px dotted black; margin-block:5px"></div>
    
        <div>Order ID: #${order?.order_sequence_id}</div>
        
        <div style="margin-top:30px;">
          ${itemsHTML.join("")}
          <div style="clear:both"></div>
        </div>
         
        <div style="clear:both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div>
            <div style="float:left">Subtotal + Taxes:</div>
            <div style="float:right">₹ ${order?.base_price}</div>
          </div>
          ${
            order?.is_delivery || order?.is_pick_up
              ? `<div style="clear:both">
                   <div style="float:left">Packaging Charges:</div>
                   <div style="float:right">₹ ${
                     order?.packaging_charge || 0
                   }</div>
                 </div>`
              : ""
          }
          ${
            order?.is_delivery && !order?.is_pick_up
              ? `<div style="clear: both">
                   <div style="float:left">Delivery Charge:</div>
                   <div style="float:right">₹ ${
                     order?.deliverycharge || 0
                   }</div>
                 </div>`
              : ""
          }
          <div style="display: ${
            order?.isgstapplied ? `block` : `none`
          }; clear:both">
            <div style="float:left">Taxes:</div>
            <div style="float:right">₹ ${order?.food_gst || 0}</div>
          </div>
          <div style="clear: both">
            <div style="float:left">Convenience Fee:</div>
            <div style="float:right">₹ ${
              order?.convenience_total_amount?.toFixed(2) || 0
            }</div>
          </div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div style="float:left">Total:</div>
          <div style="float:right">₹ ${order?.amount?.toFixed(2)}</div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
      </div>
    `;

    return _html;
  }
  return (
    <PaperBox
      sx={{
        height: "100%",
        padding: "1.71429rem 1.42857rem 1.42857rem 1.42857rem",
        display: "flex",
        flexDireaction: "column",
        flex: "1",
        "& > .MuiBox-root": {
          padding: 0,
          display: "flex",
          flexDireaction: "column",
          flex: "1",
        },
      }}
    >
      <Stack
        height={"100%"}
        gap={"1.14286rem"}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
      >
        <StackRow between>
          <Headline4
            sx={{
              fontFamily: "Inter",
              fontSize: "1.28571rem",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "1.78571rem",
              letterSpacing: "-0.01286rem",
            }}
          >
            #{order?.order_sequence_id}
          </Headline4>
          <Text
            sx={{
              fontFamily: "Inter",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.42857rem",
              letterSpacing: "-0.01rem",
            }}
          >
            {order.schedule_now
              ? convertTo12HourTime(
                  order?.order_schedule_time,
                  order.preparation_time + 15
                )
              : convertTo12HourTime(order?.order_schedule_time, 0)}
          </Text>
        </StackRow>
        <StackRow between>
          <Lead
            sx={{
              fontFamily: "Inter",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.42857rem",
              letterSpacing: "-0.01rem",
            }}
          >
            Order Type:
          </Lead>
          <CustomChip
            label={
              order?.is_dine_in
                ? "Dine In"
                : order?.is_pick_up
                ? "Pick Up"
                : order?.is_delivery
                ? "Delivery"
                : "Unknown"
            }
            sx={{
              color: "var(--error-900, #7F1D1D)",
              borderRadius: "3.57143rem",
              background: "var(--error-50, #FEF3F2)",
              fontFamily: "Inter",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "1.42857rem",
              letterSpacing: "-0.01rem",
              marginRight: "0 !important",
            }}
          />
        </StackRow>
        <StackRow between>
          <Lead
            sx={{
              fontFamily: "Inter",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.42857rem",
              letterSpacing: "-0.01rem",
            }}
          >
            Payment:
          </Lead>
          <CustomChip
            label={"Online"}
            sx={{
              borderRadius: "3.57143rem",
              background: "var(--primary-50, #F9F5FF)",
              color: "var(--primary-900, #42307D)",
              fontFamily: "Inter",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "1.42857rem",
              letterSpacing: "-0.01rem",
              marginRight: "0 !important",
            }}
          />
        </StackRow>
        <Divider sx={{ margin: "1rem 0" }} />
        <Text
          sx={{
            color: "var(--gray-500, #64748B)",
            fontFamily: "Inter",
            fontSize: "1rem",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "1.42857rem",
            letterSpacing: "-0.01rem",
          }}
        >
          Items:
        </Text>
        <Stack flex={"1"}>
          {order?.menu_item?.map((item) => (
            
            <StackRow between key={item?.itemname + item?.quantity}>
              <Text
                sx={{
                  color: "var(--gray-800, #1E293B)",
                  fontFamily: "Inter",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "1.42857rem",
                  letterSpacing: "-0.01rem",
                  display: "block",
                }}
              >
                {item?.itemname} x {item?.quantity}
              </Text>
              <Text
                sx={{
                  color: "var(--gray-900, #0F172A)",
                  textAlign: "right",
                  fontFamily: "Inter",
                  fontSize: "1.14286rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "1.57143rem",
                  letterSpacing: "-0.01143rem",
                }}
              >
                {"₹" + item?.calculated_price || 0}
              </Text>
            </StackRow>
          ))}
        </Stack>

        <Divider sx={{ margin: "1rem 0" }} />
        <Stack flex={"1 1 100%"}>
          <StackRow between>
            <Text
              sx={{
                color: "var(--gray-800, #1E293B)",
                fontFamily: "Inter",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.42857rem",
                letterSpacing: "-0.01rem",
              }}
            >
              {order?.isgstapplied ? "Subtotal:" : "Subtotal + Taxes:"}
            </Text>
            <Text
              sx={{
                color: "var(--gray-900, #0F172A)",
                textAlign: "right",
                fontFamily: "Inter",
                fontSize: "1.14286rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "1.57143rem",
                letterSpacing: "-0.01143rem",
              }}
            >
              ₹ {order?.base_price}
            </Text>
          </StackRow>
          {(order.is_delivery || order?.is_pick_up) && (
            <>
              {" "}
              <StackRow between>
                <Text
                  sx={{
                    color: "var(--gray-800, #1E293B)",
                    fontFamily: "Inter",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "1.42857rem",
                    letterSpacing: "-0.01rem",
                  }}
                >
                  Packaging Charges:
                </Text>
                <Text
                  sx={{
                    color: "var(--gray-900, #0F172A)",
                    textAlign: "right",
                    fontFamily: "Inter",
                    fontSize: "1.14286rem",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "1.57143rem",
                    letterSpacing: "-0.01143rem",
                  }}
                >
                  ₹ {order?.packaging_charge || 0}
                </Text>
              </StackRow>
              {order.is_delivery && !order?.is_pick_up && (
                <StackRow between>
                  <Text
                    sx={{
                      color: "var(--gray-800, #1E293B)",
                      fontFamily: "Inter",
                      fontSize: "1rem",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "1.42857rem",
                      letterSpacing: "-0.01rem",
                    }}
                  >
                    Delivery Charge:
                  </Text>
                  <Text
                    sx={{
                      color: "var(--gray-900, #0F172A)",
                      textAlign: "right",
                      fontFamily: "Inter",
                      fontSize: "1.14286rem",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "1.57143rem",
                      letterSpacing: "-0.01143rem",
                    }}
                  >
                    ₹ {order?.deliverycharge || 0}
                  </Text>
                </StackRow>
              )}
            </>
          )}
          {order?.isgstapplied && (
            <StackRow between>
              <Text
                sx={{
                  color: "var(--gray-800, #1E293B)",
                  fontFamily: "Inter",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "1.42857rem",
                  letterSpacing: "-0.01rem",
                }}
              >
                Taxes:
              </Text>
              <Text
                sx={{
                  color: "var(--gray-900, #0F172A)",
                  textAlign: "right",
                  fontFamily: "Inter",
                  fontSize: "1.14286rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "1.57143rem",
                  letterSpacing: "-0.01143rem",
                }}
              >
                ₹ {order?.food_gst || 0}
              </Text>
            </StackRow>
          )}
          <StackRow between>
            <Text
              sx={{
                color: "var(--gray-800, #1E293B)",
                fontFamily: "Inter",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.42857rem",
                letterSpacing: "-0.01rem",
              }}
            >
              Convenience Fee:
            </Text>
            <Text
              sx={{
                color: "var(--gray-900, #0F172A)",
                textAlign: "right",
                fontFamily: "Inter",
                fontSize: "1.14286rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "1.57143rem",
                letterSpacing: "-0.01143rem",
              }}
            >
              ₹ {order?.convenience_total_amount?.toFixed(2) || 0}
            </Text>
          </StackRow>
          <StackRow between>
            <Text
              sx={{
                color: "var(--gray-800, #1E293B)",
                fontFamily: "Inter",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "1.42857rem",
                letterSpacing: "-0.01rem",
              }}
            >
              Total:
            </Text>
            <Text
              sx={{
                color: "var(--gray-900, #0F172A)",
                textAlign: "right",
                fontFamily: "Inter",
                fontSize: "1.14286rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "1.57143rem",
                letterSpacing: "-0.01143rem",
              }}
            >
              ₹ {(order?.amount).toFixed(2)}
            </Text>
          </StackRow>
        </Stack>
        <StackRow isFlexEnd>
          {/* <CustomButton
            variant="outlined"
            sx={{
              color: "var(--gray-700, #334155)",
              fontFamily: "Inter",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "1.42857rem",
              letterSpacing: "-0.01rem",
              borderRadius: "0.57143rem",
              border: "1px solid var(--gray-300, #CBD5E1)",
              background: "var(--generic-white, #FFF)",
            }}
          >
            Save
          </CustomButton> */}
          <CustomButton
            startIcon={<img src={LeftIcon} style={{ width: 20, height: 20 }} />}
            sx={{
              borderRadius: "0.57143rem",
              background: "var(--primary-600, #4F46E5)",
              boxShadow: "0px 1px 2px 0px rgba(15, 23, 42, 0.06)",
              color: "var(--generic-white, #FFF)",
              fontFamily: "Inter",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "1.42857rem",
              letterSpacing: "-0.01rem",
            }}
            onClick={() => handlePrint(order)}
          >
            Print
          </CustomButton>
        </StackRow>
      </Stack>
      <iframe
        id="ifmcontentstoprint"
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
          visibility: "hidden",
        }}
      ></iframe>
    </PaperBox>
  );
};
