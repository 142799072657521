import React, { useEffect, useState } from "react";
import { Avatar, Box, Divider, Drawer, Stack } from "@mui/material";
import Text from "../components/typographyUI/Text";
import StackRow from "../components/layoutUI/StackRow";
import { deepOrange } from "@mui/material/colors";
import { Email, Phone } from "@mui/icons-material";
import PaperBox from "../components/layoutUI/PaperBox";
import ItemCard from "../components/pageUI/ItemCard";
import StarCount from "../components/formUI/StarCount";
import { useGetApis } from "../hooks/useGetApis";
import axios from "axios";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import CustomLoader from "../components/layoutUI/CustomLoader";
import Close from "../assets/icons/close.png";
import CrossIcon from "../assets/icons/CrossIcon.png";

const moment = require("moment-timezone");

const CustomerDetails = ({
  customerData = {},
  setShowCustomerDetails = () => {},
}) => {
  const { customerDetails } = useGetApis();

  const { user } = useSelector((state) => state.auth);

  
  const { data, isLoading, isFetching } = useQuery(["customer-details"], () =>
    axios.get(
      customerDetails + user?.outletId + "/" + customerData?.customerauthuid
    )
  );

  const [review, setReview] = useState([]);

  function formatDateTime(inputDateTime) {
    const date = new Date(inputDateTime);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours || 12;

    const formattedDateTime = `${day} ${month} ${year}, ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;

    return formattedDateTime;
  }

  useEffect(() => {
    if (data?.data?.data) {
      setReview([...data?.data?.data.map((m) => m?.Review).flat()]);
    }
  }, [data]);

  return (
    <>
      {isLoading || isFetching ? (
        <CustomLoader />
      ) : (
        <>
          <div
            onClick={() => setShowCustomerDetails(false)}
            style={{
              borderRadius: "3.57143rem",
              right: "52.42857rem",
              top: "1rem",
              position: "fixed",
              zIndex: 999999,
              width: "2.5rem",
              height: "2.5rem",
              background: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <img
              src={CrossIcon}
              alt=""
              style={{ width: "1.71429rem", height: "1.71429rem" }}
            />
          </div>
          <Drawer
            anchor="right"
            open={true}
            onClose={() => setShowCustomerDetails(false)}
            sx={{
              "& .MuiPaper-root": {
                // padding : '2rem'
                // width: "50rem",
              },
            }}
          >
            <Stack sx={{ width: "51.42857rem" }}>
              <StackRow
                between
                sx={{
                  borderBottom: "1px solid var(--gray-200, #E2E8F0)",
                  background: "var(--gray-50, #F8FAFC)",
                  padding: "2.28571rem",
                }}
              >
                <StackRow center>
                  <Avatar
                    sx={{
                      bgcolor: deepOrange[500],
                      width: "5.71429rem",
                      height: "5.71429rem",
                      // padding: "1.42857rem",
                      borderRadius: "5.71429rem",
                      marginRight: "1rem",
                    }}
                    src={customerData?.photo}
                  >
                    {customerData?.customername?.[0]}
                  </Avatar>
                  <Stack gap={"0.2rem"}>
                    <Text
                      sx={{
                        color: "var(--gray-900, #0F172A)",
                        fontFamily: "Inter",
                        fontSize: "1.71429rem",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        letterSpacing: "-0.01714rem",
                      }}
                    >
                      {customerData?.customername}
                    </Text>
                    <StackRow>
                      <Email />
                      <Text
                        sx={{
                          color: "var(--gray-600, #475569)",
                          fontFamily: "Inter",
                          fontSize: "1rem",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "1.42857rem",
                          letterSpacing: "-0.01rem",
                        }}
                      >
                        {customerData?.email}
                      </Text>
                    </StackRow>
                    <StackRow>
                      <Phone />{" "}
                      <Text
                        sx={{
                          color: "var(--gray-600, #475569)",
                          fontFamily: "Inter",
                          fontSize: "1rem",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "1.42857rem",
                          letterSpacing: "-0.01rem",
                        }}
                      >
                        {customerData?.mobile}
                      </Text>
                    </StackRow>
                  </Stack>
                </StackRow>
                <Text
                  sx={{
                    color: "var(--gray-900, #0F172A)",
                    fontFamily: "Inter",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "1.42857rem",
                    letterSpacing: "-0.01rem",
                  }}
                >
                  {customerData?.campusname}, {customerData?.city}
                </Text>
              </StackRow>
              <Text
                sx={{
                  color: "var(--gray-700, #334155)",
                  fontFamily: "Inter",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "1.42857rem",
                  letterSpacing: "-0.01rem",
                  paddingLeft: "2.28571rem",
                  paddingTop: "2.28571rem",
                }}
              >
                Reviews ({review?.length || 0})
              </Text>
              <StackRow gap={"1.42857rem"} sx={{ padding: "2.28571rem" }}>
                {!review.length ? (
                  <Text sx={{ margin: "auto" }}>No reviews</Text>
                ) : (
                  review?.map((order, index) =>
                    order?.message ? (
                      <PaperBox sx={{ width: "18.75rem" }}>
                        <Text
                          sx={{
                            color: "var(--gray-900, #0F172A)",
                            fontFamily: "Inter",
                            fontSize: "0.85714rem",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "1.28571rem",
                            letterSpacing: "-0.00857rem",
                          }}
                        >
                          {order?.message}
                        </Text>
                        <StarCount starCount={order?.star} />
                      </PaperBox>
                    ) : null
                  )
                )}
              </StackRow>
              <Divider />
              <Text
                sx={{
                  color: "var(--text-color-text-secondary, #8C8994)",
                  fontFamily: "Inter",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "1.42857rem",
                  letterSpacing: "-0.01rem",
                  paddingLeft: "2.28571rem",
                  paddingTop: "2.28571rem",
                }}
              >
                Last 5 Orders
              </Text>
              <Stack gap={"2.28571rem"} sx={{ padding: "2.28571rem" }}>
                {data?.data?.data?.map((item) => (
                  <StackRow between>
                    <Stack>
                      <Text
                        sx={{
                          color: "var(--text-color-text-primary, #071731)",
                          fontFamily: "Poppins",
                          fontSize: "1.28571rem",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "1.57143rem",
                        }}
                      >
                        Order ID #{item?.orderSequenceId}
                      </Text>
                      <Text
                        sx={{
                          color: "var(--text-color-text-secondary, #8C8994)",
                          fontFamily: "Poppins",
                          fontSize: "0.85714rem",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "0.85714rem",
                        }}
                      >
                        Total Price ₹{item?.totalPrice.toFixed(2)}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text
                        sx={{
                          color: "var(--gray-400, #94A3B8)",
                          fontFamily: "Inter",
                          fontSize: "0.85714rem",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "1.28571rem",
                          letterSpacing: "-0.00857rem",
                        }}
                      >
                        Order Date & Time
                      </Text>
                      <Text
                        sx={{
                          color: "var(--gray-700, #334155)",
                          fontFamily: "Inter",
                          fontSize: "1rem",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "1.42857rem",
                          letterSpacing: "-0.01rem",
                        }}
                      >
                        {item?.Order_Schedule?.map((time) =>
                          formatDateTime(
                            `${time.scheduleDate}T${time.scheduleTime}`
                          )
                        )}
                      </Text>
                    </Stack>
                  </StackRow>
                ))}
              </Stack>
            </Stack>
          </Drawer>
        </>
      )}
    </>
  );
};

export default CustomerDetails;
