import { Field } from "formik";
import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const CustomCheckboxWithFormik = ({
  label = "Add label prop",
  labelSx,
  name,
  formikValue,
  ...otherProps
}) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <FormControlLabel
          sx={{
            "& .MuiTypography-root": {
              color: "#334155",
              fontWeight: "500",
              ...labelSx,
            },
          }}
          control={<Checkbox {...field} checked={field.value}  />}
          label={label}
          {...otherProps}
        />
      )}
    </Field>
  );
};

export default CustomCheckboxWithFormik;
