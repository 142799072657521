import { Box, Fade, Typography } from "@mui/material";
import React from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Fade in={value == index}  timeout={1000}>
          <div>
            <Box>
              <Box>{children}</Box>
            </Box>
          </div>
        </Fade>
      )}
    </div>
  );
}
export default TabPanel;
