import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import CustomButton from "../../../components/formUI/CustomButton";
import StackRow from "../../../components/layoutUI/StackRow";
import PaperBox from "../../../components/layoutUI/PaperBox";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import CustomTextFieldFormik from "../../../components/formUI/CustomTextFieldFormik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import {
//   convertObjectToFormData,
//   subCategoryGetSingleUrl,
//   subCategoryImageUrl,
//   subCategoryPutUrl,
//   subCategoryUrl,
// } from "../../../utils/urls";
import UpliadImageFieldWithoutFormik from "../../../components/formUI/UpliadImageFieldWithoutFormik";
import { motion } from "framer-motion";
import CustomLoader from "../../../components/layoutUI/CustomLoader";
import { useGetApis } from "../../../hooks/useGetApis";

const SubCategoriesAdd = () => {
  const {
    convertObjectToFormData,
    subCategoryGetSingleUrl,
    subCategoryImageUrl,
    subCategoryPutUrl,
    subCategoryUrl,
  } = useGetApis();
  const { user } = useSelector((state) => state.auth);
  const initialData = {
    restaurantId: user.restaurantId,
    outletId: user.outletId,
    categoryname: "",
  };
  const [formData, setFormData] = useState(initialData);
  const navigate = useNavigate();
  const { cId } = useParams();
  const [img, setImg] = useState(null);
  const [isLoadingFormData, setIsLoadingFormData] = useState(null);
  const fetchData = () => {
    setIsLoadingFormData(true);
    axios
      .get(subCategoryGetSingleUrl + cId)
      .then((res) => {
        
        setFormData(res.data.data[0]);
        setIsLoadingFormData(false);
        setImg(res.data.data[0].category_image_url);
      })
      .catch((err) => setIsLoadingFormData(false));
  };
  useEffect(() => {
    cId && fetchData();
  }, []);

  const { mutate, isLoading } = useMutation(
    (data) => {
      if (cId) {
        const url = subCategoryPutUrl + data.categoryid;
        return axios.post(url, data);
      } else {
        return axios.post(subCategoryUrl, data);
      }
    },
    {
      onSuccess: (res) => {
        const categoryid = res.data.data[0]?.categoryid;
        typeof img != "string" && photoMutate({ file: img, categoryid });
        typeof img == "string" && toast.success("Sub Category Updated");
        typeof img == "string" && navigate(-1);
      },
      onError: (res) => {
        toast.error(res.response.data.error || "Something Went Wrong");
      },
    }
  );
  const { mutate: photoMutate, isLoading: isLoading2 } = useMutation(
    (data) =>
      axios.post(subCategoryImageUrl, convertObjectToFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => {
        
        toast.success("Sub Category " + (cId ? "Updated" : "Created"));
        navigate(-1);
      },
      onError: (res) => {
        toast.error(res.response.data.error || "Something Went Wrong");
      },
    }
  );
  return cId && isLoadingFormData ? (
    <CustomLoader />
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          if (!img) return toast.error("Image is required!");
          
          delete values.img;
          mutate(values);
        }}
        validationSchema={Yup.object().shape({
          categoryname: Yup.string().required("Sub Category Name is required"),
        })}
      >
        <Form>
          <Box>
            <PageHeader
              showBack
              secondary
              title={"Add new Menu category"}
              subTitle={"View and update your store details"}
              rightUi={
                <CustomButton loading={isLoading || isLoading2} type="submit">
                  Save
                </CustomButton>
              }
            />
            <StackRow gap={"32px"}>
              <PaperBox
                shadowed
                padding={"0 24px 16px 24px"}
                sx={{
                  flex: "1",
                  overflow: "auto",
                  height: "auto",
                  maxWidth: "800px",
                  margin: "auto",
                }}
              >
                <Box
                  id="menu-media"
                  sx={{
                    pt: "24px",
                    pb: 3,
                  }}
                >
                  <PageHeader secondary title={"Sub-Category Media"} />
                  <Stack spacing={4}>
                    <UpliadImageFieldWithoutFormik
                      defaultValue={formData.category_image_url}
                      name="img"
                      onChange={setImg}
                      uploadImg={true}
                    />
                    <CustomTextFieldFormik
                      name="categoryname"
                      label="Sub Category Name "
                      placeholder="Enter Sub Category Name"
                      required
                    />
                  </Stack>
                </Box>
              </PaperBox>
            </StackRow>
          </Box>
        </Form>
      </Formik>
    </motion.div>
  );
};

export default SubCategoriesAdd;
