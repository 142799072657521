import React, { useMemo, useState } from "react";
import PageHeader from "../components/pageUI/PageHeader";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import Text from "../components/typographyUI/Text";
import StackRow from "../components/layoutUI/StackRow";
import CustomSearchTextField from "../components/formUI/CustomSearchTextField";
import CustomButton from "../components/formUI/CustomButton";
import axios from "axios";
import { saveAs } from "file-saver";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ExcelJS from "exceljs";
import DateRangePicker from "../components/pageUI/filters/DateRangePicker";

const CashAndCarry = () => {
  const [search, setSearch] = useState("");
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const extractDate = (dateTimeString) => {
    const [year, month, day] = dateTimeString.split("T")[0].split("-");
    return `${day}-${month}-${year}`;
  };
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const normalizeString = (str) =>
    str?.trim()?.toLowerCase()?.replace(/\s+/g, " ");

  const getFilterData = (data) => {
    let _data = data;
    if (search) {
      const searchTerm = normalizeString(search);
      _data = _data?.filter((row) =>
        normalizeString(row?.customerName)?.includes(searchTerm)
      );
    }
    return _data;
  };

  const columns = useMemo(() => [
    {
      field: "servedDate",
      headerName: "Served Date",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{extractDate(row?.servedDate)}</Text>,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.customerName}</Text>,
    },

    // {
    //   field: "email",
    //   headerName: "Email",
    //   flex: 1,
    //   minWidth: 300,
    //   sortable: false,
    //   renderCell: ({ row }) => <Text bold>{row?.email}</Text>,
    // },
    {
      field: "mobile",
      headerName: "Mobile number",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text>{row?.mobile}</Text>,
    },

    {
      field: "meal",
      headerName: "Meal Type",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <Text>
          {row?.mealTypeId == 1
            ? "Breakfast"
            : row?.mealTypeId == 2
            ? "Lunch"
            : row?.mealTypeId == 3
            ? "High Tea"
            : "Dinner"}
        </Text>
      ),
    },
    {
      field: "mealprice",
      headerName: "Meal Price",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text>₹ {row?.mealPrice}</Text>,
    },
  ]);

  console.log({ startDate, endDate });

  //   const generateExcel = (_jsonData = []) => {
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet("CashAndCarryCustomers");

  //     // Adding header row
  //     if (_jsonData.length > 0) {
  //       const headers = Object.keys(_jsonData[0]);
  //       worksheet.addRow(headers);
  //     }

  //     // Adding data rows
  //     _jsonData.forEach((data) => {
  //       worksheet.addRow(Object.values(data));
  //     });

  //     // Apply bold font to the header row
  //     worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
  //       cell.font = { bold: true };
  //     });

  //     // Adjusting column widths
  //     worksheet.columns.forEach((column) => {
  //       column.width = 20;
  //     });

  //     workbook.xlsx
  //       .writeBuffer()
  //       .then((excelBuffer) => {
  //         const data = new Blob([excelBuffer], {
  //           type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //         });
  //         saveAs(data, "Students_Data.xlsx");
  //       })
  //       .catch((error) => {
  //         console.error("Error generating Excel file:", error);
  //       });
  //   };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <PageHeader
          secondary
          title={"Cash and Carry Customers"}
          subTitle={"View and update your customer details"}
        />
      </Box>

      <StackRow
        gap={"10px"}
        style={{ display: "flex" }}
        between
        marginBottom="10px"
      >
        {/* <LocationFilter /> */}
        <CustomSearchTextField
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <StackRow center gap="14px">
          <DateRangePicker
            setFrom={setStartDate}
            setTo={setEndDate}
            fromDate={startDate}
            toDate={endDate}
          />
          {/* <CustomButton
            textColor="#4F46E5"
            bgColor="#EEF2FF"
            variant="outlined"
            startIcon={<CloudDownloadOutlinedIcon />}
            onClick={() => {
              setLoading(true);
              axios
                .get(
                  process.env.REACT_APP_BACKEND_BASE_URL +
                    `mess/mwl/getWhitelistedUsersExcel/${outletIdNew}`
                )
                .then((res) => {
                  // console.log("dataaa ",res?.data?.data);
                  generateExcel(res?.data?.data);
                })
                .catch((error) => {})
                .finally(() => setLoading(false));
            }}
          >
            {loading ? "Generating..." : "Generate Excel"}
          </CustomButton> */}
        </StackRow>
      </StackRow>

      {outletIdNew && (
        <CustomDataGrid
          name="cnccustomer-table"
          height={"490px"}
          columns={columns}
          rowId="customerAuthUID"
          url={
            process.env.REACT_APP_BACKEND_BASE_URL +
            `mess/cnc/getCNC/${outletIdNew}?fromDate=${startDate}&toDate=${endDate}`
          }
          labelName={"CNC"}
          noActions={true}
          filterFn={getFilterData}
        />
      )}
    </motion.div>
  );
};

export default CashAndCarry;
