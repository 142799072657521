import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
// const BACKEND_BASE_URL = 'http://localhost:8000/';

async function pushDataToPetpooja(data) {
  return await axios.post(
    BACKEND_BASE_URL + "petpooja/pushMenu/pushData",
    data
  );
}
async function getPetpoojaMenu(data) {
  return await axios.post(
    BACKEND_BASE_URL + "petpooja/pushMenu/fetchMenu",
    data
  );
}

async function getPetpoojaMenuForSelection(data) {
  return await axios.post(
    BACKEND_BASE_URL + "petpooja/pushMenu/fetchMenuCard",
    data
  );
}

async function addMenuFromPetPooja(data) {
  return await axios.post(
    BACKEND_BASE_URL + "outlet/menu/addMenu",
    data
  );
}
async function updatePetPoojaConfigs(data, outletId) {
  return await axios.post(
    BACKEND_BASE_URL + `outlet/updatePetPooja/${outletId}`,
    data
  );
}

export { pushDataToPetpooja, getPetpoojaMenu, getPetpoojaMenuForSelection, addMenuFromPetPooja, updatePetPoojaConfigs };
