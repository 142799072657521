import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState, useCallback } from "react";
import LargeText from "../typographyUI/LargeText";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import CustomDialog from "../layoutUI/CustomDialog";
import PageHeader from "../pageUI/PageHeader";
import Text from "../typographyUI/Text";
import StackRow from "../layoutUI/StackRow";
import CustomButton from "./CustomButton";
import SmallText from "../typographyUI/SmallText";
import AddPng from "../../assets/icons/Add.png";
import { useGetApis } from "../../hooks/useGetApis";
import { useSelector } from "react-redux";
import axios from "axios";
import PDFViewerFromFile from "./PDFViewerFromFile";
import ImageIcon from "../../assets/images/imageIcon.jpg";
import PdfIcon from "../../assets/images/pdfIcon.png";
import {useSmallScreen} from "../../utils/mediaQueries";

const NewFileUploadComponent = ({
  setFile: _setFile = () => {},
  label = "Add Label Please",
  // file1='',
  defaultImg,
  url = "",
  title = "Add title please",
  text = "Add text here",
  warningMessage = null,
  isAcceptFile = () => false,
  imageUrl,
}) => {
  const isSmallScreen = useSmallScreen();
  const { convertObjectToFormData } = useGetApis();
  const restaurantData = useSelector((state) => state.auth.user);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(defaultImg || null);

  const onDrop = useCallback((acceptedFiles) => {
    const imageFiles = acceptedFiles.filter(
      (file) => file.type.startsWith("image/") || isAcceptFile(file)
    );
    let file = imageFiles[0];
    if (imageFiles.length === 0) {
      toast.warning(warningMessage || "Please select image files only.");
      return;
    }

    setFile(imageFiles[0]);
    _setFile(imageFiles[0]);
    

    if (url) {
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + url,
        convertObjectToFormData({
          file: imageFiles[0],
          outletId: restaurantData?.outletId,
        }),
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      const imageUrl = `data:${file.type};base64,${base64String}`;
      setImg(imageUrl);
    };

    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  return (
    <>
      <Typography
        color="black"
        fontWeight={500}
        sx={{
          mb: 1,
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          cursor: "pointer",
          padding: "32px 16px",
          border: "1px dashed var(--gray-500, #64748B)",
          borderRadius: "8px",
          background: isDragActive ? `whitesmoke` : "transparent",
          transform: isDragActive && "scale(0.95)",
          transition: "0.4s",
          position: "relative",
        }}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Box
            sx={{
              position: "absolute",
              transform: "scale(0.8)",
              right: 0,
              top: 10,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {img && (
              <CustomDialog
                buttonComp={(props) => (
                  <CustomButton
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onClick();
                    }}
                  >
                    View File
                  </CustomButton>
                )}
              >
                {({ onClose }) => (
                  <>
                    <PageHeader secondary title={title} onClose={onClose} />
                    <Box
                      sx={{
                        width: isSmallScreen ? "100%" : 550,
                      }}
                    >
                      {isAcceptFile?.(file) ? (
                        <iframe
                          src={
                            imageUrl || URL.createObjectURL(file) + "#toolbar=0"
                          }
                          title="PDF Viewer"
                          width="100%"
                          height="700px"
                        ></iframe>
                      ) : (
                        <img
                          src={img}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "20px",
                          }}
                        />
                      )}

                      {/* <PDFViewerFromFile file={file} /> */}
                    </Box>
                  </>
                )}
              </CustomDialog>
            )}
          </Box>
          <Stack justifyContent={"center"} gap={"4px"} alignItems={"center"}>
            <img
              src={
                !(img || file)
                  ? AddPng
                  : isAcceptFile?.(file)
                  ? PdfIcon
                  : ImageIcon
              }
              style={{
                width: 48,
                height: 48,
                marginBottom: "14px",
              }}
            />
            <SmallText>
              {file?.name || isAcceptFile?.(file)
                ? "Upload PNG, JPG, PDF max size 5MB each."
                : "Upload PNG, JPG, max size 5MB each."}
            </SmallText>
            <StackRow>
              <Text color="#0F172A" bold>
                {text}, or
              </Text>
              <Text
                color="#4F46E5"
                bold
                sx={{
                  cursor: "pointer",
                }}
              >
                click to browse
              </Text>
            </StackRow>
          </Stack>
        </div>
      </Box>
    </>
  );
};

export default NewFileUploadComponent;
