import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  outletId: null,
};

export const outletIdSlice = createSlice({
  name: "outletIdSlice",
  initialState,
  reducers: {
    setOutletId: (state, action) => {
      state.outletId = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOutletId } = outletIdSlice.actions;

export default outletIdSlice.reducer;
