import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

async function createStaff(data){
    return await axios.post(BACKEND_BASE_URL+"staff/staff/createStaff",data)
}
async function createRole(data){
    return await axios.post(BACKEND_BASE_URL+"staff/createRole",data)
}
async function updateSatff(id,data){
    return await axios.post(BACKEND_BASE_URL+"staff/staff/updateStaff/"+id,data)
}

async function getRestaurantRole(id){
    return await axios.get(BACKEND_BASE_URL+`staff/role/getRoleByOutletId/`+id)
}

async function updatePassword(data){
    return await axios.post(BACKEND_BASE_URL+"admin/updateAdminPassword",data)
}

export  {
    createStaff,
    createRole,
    updateSatff,
    getRestaurantRole,
    updatePassword
}