import { Box } from "@mui/material";
import React from "react";
import LargeText from "../typographyUI/LargeText";

const FormHeader = ({ children, ...otherProps }) => {
  return (
    <Box
      pb={1}
      mb={2}
      sx={{
        borderBottom: "1px #E2E8F0 solid",
      }}
      {...otherProps}
    >
      <LargeText color="#94A3B8">{children}</LargeText>
    </Box>
  );
};

export default FormHeader;
