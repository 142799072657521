import React, { useEffect, useState } from "react";
import CustomStepper from "./CustomStepper";
import TabPanel from "./TabPanel";
import { Box, Stack, Typography } from "@mui/material";
import StackRow from "../StackRow";
import TabPanelUI from "./TabPanelUI";
import FormHeader from "../../formUI/FormHeader";
import CustomChip from "../../pageUI/CustomChip";
import { Add, Remove } from "@mui/icons-material";
import OnboardDaysFields from "../../pageUI/outlets/OnboardDaysFields";
import RestaurentDetails from "../../pageUI/outlets/onboardForms/RestaurentDetails";
import OutletManager from "../../pageUI/outlets/onboardForms/OutletManager";
import EstablishmentDetails from "../../pageUI/outlets/onboardForms/EstablishmentDetails";
import CustomButton from "../../formUI/CustomButton";
import { useMutation, useQuery } from "react-query";
import axios, { Axios } from "axios";
// import { useGetApis } from "../../../hooks/useGetApis";
import {
  categoryListUrl,
  createOutletUrl,
  outletUrl,
  updateOutlet,
} from "../../../utils/urls";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Verification from "../../pageUI/outlets/onboardForms/Verification";
import MoreInformation from "../../pageUI/settings/MoreInformation";
import { useGetApis } from "../../../hooks/useGetApis";
import OnboardingSuccess from "../../pageUI/outlets/onboardForms/OnboardingSuccess";
import Verificatio from "../../pageUI/outlets/onboardForms/Verification";
import CustomCheckbox from "../../formUI/CustomCheckbox";
import CustomCheckboxForLink from "../../formUI/CustomCheckboxForLink";
import CustomLoader from "../CustomLoader";
import PublishSvg from "../../../assets/images/food.gif";

const TabNavigator = () => {
  // States
  const { outletId } = useParams();
  const {isOutletDuplicateActive} = useSelector((state) => state.duplicateOutletId);
  const outletID= outletId;
  const { getOutletData } = useGetApis();
  const { data } = useQuery(
    ["getoutletData", outletId],
    () => outletId && axios.get(getOutletData + outletId)
  );
  const DATA = data?.data?.data;

  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const [tabIndex, setTabIndex] = useState(outletId ? -1 : 0);
  const [initialState, setInitialState] = useState({
    outletData: {
      outletName: "",
      email: "",
      password: "",
      mobile: "",
      GSTIN: "",
      campusId: "",
      cityId: "",
      restaurantId: "",
      address: "",
      isActive: "",
      openTime: "",
      closeTime: "",
      isDineIn: true,
      isPickUp: true,
      isDelivery: true,
      isBoth: false,
      isVeg: true,
      isNonVeg: false,
      isBothFood: false,
      accountNumber: "",
      BankName: "",
      IFSCCode: "",
      hasMess:""
    },
    outletAdminId: {
      name: "",
      mobile: "",
      email: "",
      address: "",
      pancard: "",
    },
    Restaurant_category: [],
    Timing: [
      {
        dayId: "56da23db-c17f-4808-8a84-7d9ce397136c",
        openTime: "",
        closeTime: "",
      },
    ],
  });

  const [verificationData, setVerificationData] = useState({
    email: "",
    mobile: "",
  });
  const [outletData, setOuletData] = useState(initialState.outletData);

  //

  const { convertObjectToFormData, cityListUrl } = useGetApis();

  const [outletAdminId, setOutletAdminId] = useState(
    initialState.outletAdminId
  );
  const [categories, setCategories] = useState(
    initialState.Restaurant_category || []
  );
  useEffect(() => {
    if (DATA) {
      setOuletData(DATA);
      setOutletAdminId(DATA?.outletAdminId);
      setCategories(DATA?.Restaurant_category?.map((c) => c.categoryId));
      setVerificationData({ email: DATA?.email, mobile: String(DATA?.mobile) });
      setTabIndex(0);
    } else {
      setOuletData(user);
    }
  }, [DATA, user]);
  const [dates, setDates] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    user?.Restaurant_category &&
      !outletId &&
      setCategories(user?.Restaurant_category?.map((c) => c.categoryId));
  }, []);

  const [timing, setTiming] = useState([]);
  const [documents, setDocuments] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [campusId, setCampusId] = useState(null);
  const [warning, setWarning] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const getDocuments = (data) => {
    setDocuments(data);
  };

  // Handlers and Functions

  const { mutate, isLoading } = useMutation(
    (data) => {
    console.log("duplicate outlet data ",data)
      return outletId 
      ? (isOutletDuplicateActive 
          ? axios.post(createOutletUrl, data) 
          : axios.post(updateOutlet + outletId, data)) 
      : axios.post(createOutletUrl, data);
    
    },
    {
      onSuccess: async (res) => {
        toast.success(outletID ? isOutletDuplicateActive ? "Outlet Duplicated" : "Outlet Updated" : "Outlet Created");
        const outletId = res.data.data.outletId;
        
        // navigate(-1);
        if (documents[1]) {
          try {
            const response = await axios.post(
              process.env.REACT_APP_BACKEND_BASE_URL +
                "outlet/upsertFssaiLicensePhoto",
              convertObjectToFormData({
                file: documents[1],
                outletId: outletId,
              }),
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } catch (error) {}
        }

        if (documents[0]) {
          try {
            const response = await axios.post(
              process.env.REACT_APP_BACKEND_BASE_URL +
                "outlet/upsertHeaderImage",
              convertObjectToFormData({
                file: documents[0],
                outletId: outletId,
              }),
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } catch (error) {}
        }
        tabIndexHandler(5);
      },
      onError: (res) => {
        toast.error(res.response.data.error || "Something Went Wrong");
      },
    }
  );

  const { data: categoryList } = useQuery(
    "categories-list",
    () => axios.get(categoryListUrl),
    {}
  );

  const { data: cityList } = useQuery("city-List", () =>
    axios.get(cityListUrl)
  );
  // handlers
  const tabIndexHandler = (index) => index <= 5 && setTabIndex(index);

  const handlenBack = () => {
    tabIndexHandler(3);
  };

  // functions
  const onSubmit = async () => {
    // if (selectedDay?.length === 0) {
    //   setWarning(true);
    //   toast.warning("Please add at least 1 day");
    //   return;
    // }
    let _dates = dates.filter((d) => d.isSelect);
    if (_dates?.length === 0) {
      setWarning(true);
      toast.warning("Please add at least 1 day");
      return;
    }
    let allDaysTiming = dates.find((d) => d.day == "All Days");

    const data = {
      ...outletData,
      ...verificationData,
      outletAdminId,
      Restaurant_category: categories,
      bankDetailsId: {
        accountNumber: outletData.accountNumber,
        BankName: outletData.BankName,
        IFSCCode: outletData.IFSCCode,
        bankId: outletData.bankId,
      },
      // Timing: dates.map((t) => ({
      //   openTime: t.startDate,
      //   closeTime: t.endDate,
      //   day: t.day,
      //   dayId: t.dayId,
      // })),
      logo: user?.logo,
      Timing: dates,
      primaryOutletId: user?.outletId,
      isPrimaryOutlet: user?.outletId ? false : true,
    };
    let postbody = {
      outletName: outletData?.outletName,
      mobile: verificationData?.mobile,
      campusId: outletData?.campusId,
      cityId: outletData.cityId,
      restaurantId: outletData?.restaurantId,
      address: outletData.address,
      isDineIn: outletData.isDineIn,
      isPickUp: outletData.isPickUp,
      isDelivery: outletData.isDelivery,
      isVeg: outletData.isVeg,
      isNonVeg: outletData.isNonVeg,
      hasMess:outletData?.hasMess || "",
      outletAdminId: {
        outletAdminId: outletAdminId.outletAdminId,
        name: outletAdminId.name,
        mobile: outletAdminId.mobile,
        email: outletAdminId.email,
        address: outletAdminId.address,
        pancard: outletAdminId.pancard,
      },
      Restaurant_category: categories,
      bankDetailsId: {
        accountNumber: outletData.accountNumber,
        BankName: outletData.BankName,
        IFSCCode: outletData.IFSCCode,
        bankId: outletData.bankId,
      },
      Timing: dates,
    };

    mutate(outletId ? isOutletDuplicateActive ? data : postbody : data); //outlet edit = postbody else data

    console.log("Post body ",data)

    if (allDaysTiming) data.dates = [];
    if (allDaysTiming) {
      data.openTime = allDaysTiming.startDate;
      data.closeTime = allDaysTiming.endDate;
    }
    data.restaurantId = user.restaurantId;
    // data.outletAdminId = user.restaurantAdminId;
  };
  return (
    <Box sx={{ maxWidth: "1000px", m: "auto", mt: "48px"}}>
      <CustomStepper activeStep={tabIndex} 
      // onChange={tabIndexHandler} 
      />
      <TabPanelUI
        index={0}
        tabIndex={tabIndex}
        // sx={display : }
        tabIndexHandler={tabIndexHandler}
        onSubmit={onSubmit}
      >
        <TabPanel index={-1} value={tabIndex}>
          <Box
            style={{
              display: "grid",
              placeContent: "center",
              minHeight: "545.22px",
            }}
          >
            {/* <CustomLoader /> */}
            <img
              src={PublishSvg}
              style={{
                width: "300px",
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel index={0} value={tabIndex}>
          {/* <OnboardingSuccess
            activeStep={5}
            restaurantInfoData={outletData}
            sx={{
              // display: activeStep == 5 ? "flex" : "none",
            }}
          /> */}
          <Verificatio
            onSetState={setVerificationData}
            formData={verificationData}
            tabIndexHandler={tabIndexHandler}
          />
        </TabPanel>
        <TabPanel index={1} value={tabIndex}>
          <RestaurentDetails
            formData={outletData}
            onSetState={setOuletData}
            tabIndexHandler={tabIndexHandler}
            getDocuments={getDocuments}
            cityList={cityList}
            campusId={campusId}
            cityId={cityId}
            setCityId={setCityId}
            setCampusId={setCampusId}
          />
        </TabPanel>
        <TabPanel index={2} value={tabIndex}>
          <OutletManager
            formData={outletAdminId}
            onSetState={setOutletAdminId}
            tabIndexHandler={tabIndexHandler}
          />
        </TabPanel>
        <TabPanel index={3} value={tabIndex}>
          <EstablishmentDetails
            formData={outletData}
            onSetState={setOuletData}
            tabIndexHandler={tabIndexHandler}
          />
        </TabPanel>
        <TabPanel index={4} value={tabIndex}>
          <Stack
            sx={{
              minHeight: "550px",
            }}
          >
            <FormHeader>Type of Categories Select:</FormHeader>
            <StackRow gap={"12px"} mb={1} flexWrap={"wrap"}>
              {categoryList?.data?.data?.map((category) => {
                const isCurrent = categories?.includes(category?.categoryId);
                return (
                  <CustomChip
                    onClick={() =>
                      categories?.includes(category?.categoryId)
                        ? setCategories((p) => {
                            return [
                              ...p?.filter((c) => c !== category?.categoryId),
                            ];
                          })
                        : setCategories((p) => {
                            return [...p, category?.categoryId];
                          })
                    }
                    key={category?.categoryId}
                    label={category.category}
                    sx={{
                      border: "1px #94A3B8 solid",
                      cursor: "pointer",
                      background: isCurrent && "#2e7d32",
                      color: isCurrent && "white",
                      "&:hover": {
                        background: isCurrent ? "#EF4444" : "#2e7d32",
                        color: isCurrent ? "white" : "white",
                        "& svg": {
                          fill: isCurrent ? "white" : "white",
                        },
                      },
                      "& svg": {
                        transition: "0.3s",
                        fill: isCurrent && "white",
                        "&:active": {
                          transform: "scale(0.8)",
                        },
                        "&:hover": {},
                      },
                    }}
                    icon={isCurrent ? <Remove /> : <Add />}
                  />
                );
              })}
            </StackRow>
            {categories.length === 0 && (
                      <Typography variant="body2" color="error">
                        Please select at least one category
                      </Typography>
                    )}
                  
            {/* <OnboardDaysFields
              tabIndexHandler={tabIndexHandler}
              selectedState={timing}
              setSelectedState={setTiming}
            /> */}
            <MoreInformation
              column={6}
              handler={setDates}
              setSelectedDay={setSelectedDay}
              warning={warning}
              timeDataFromUpdateOutlet={DATA?.Timing}
            />
            {/* <CustomCheckboxForLink
              label={"I agree to"}
              onChange={(e) =>
                e.target.checked
                  ? setDisableSubmit(false)
                  : setDisableSubmit(true)
              }
            /> */}
            <CustomCheckbox
              label={
                <span>
                  I agree to the{" "}
                  <Link
                    style={{
                      color: "#007BFF",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    target="_blank"
                    to="https://zyxwtaeuvipslarwyrbe.supabase.co/storage/v1/object/public/documents/policy-files/MealPe_Terms%20of%20Use_AGC_Sept%2005,%202023.pdf"
                  >
                    Terms of Use
                  </Link>{" "}
                  and{" "}
                  <Link
                    style={{
                      color: "#007BFF",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    target="_blank"
                    to="https://zyxwtaeuvipslarwyrbe.supabase.co/storage/v1/object/public/documents/policy-files/MealPe_Privacy%20Policy_AGC_Sept%2005,%202023.pdf"
                  >
                    Privacy Policy
                  </Link>
                </span>
              }
              onChange={(e) =>
                e.target.checked
                  ? setDisableSubmit(false)
                  : setDisableSubmit(true)
              }
            />
            <StackRow
              justifyContent="flex-end"
              sx={{
                marginTop: "auto",
              }}
            >
              <CustomButton
                // type="submit"
                variant="outlined"
                sx={{
                  fontWeight: "400",
                  mt: 2,
                  mr: 2,
                }}
                onClick={handlenBack}
              >
                Back
              </CustomButton>
              <CustomButton
                loading={isLoading}
                disabled={disableSubmit || !categories.length}
                onClick={() => {
                  onSubmit();
                }}
                sx={{
                  fontWeight: "400",
                  mt: 2,
                }}
              >
                Save & Submit
              </CustomButton>
            </StackRow>
          </Stack>
        </TabPanel>
        <TabPanel index={5} value={tabIndex}>
          <OnboardingSuccess
            activeStep={5}
            restaurantInfoData={outletData}
            sx={
              {
                // display: activeStep == 5 ? "flex" : "none",
              }
            }
          />
        </TabPanel>
      </TabPanelUI>
    </Box>
  );
};

export default TabNavigator;
