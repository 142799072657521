import React from "react";
import TabNavigator from "../../components/layoutUI/TabNaigator";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";

const OutletOnboard = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{overflow: 'auto'}}
    >
      <div>
        <TabNavigator />
      </div>
    </motion.div>
  );
};

export default OutletOnboard;
