import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormHeader from "../../../formUI/FormHeader";
import { Field, Form, Formik, useField, useFormik } from "formik";
import CustomButton from "../../../formUI/CustomButton";
import * as Yup from "yup";
import CustomTextFieldFormik from "../../../formUI/CustomTextFieldFormik";
import CustomSelectFormik from "../../../formUI/CustomSelectFormik";
import CustomAutoComplete from "../../../formUI/CustomAutoComplete";
import StackRow from "../../../layoutUI/StackRow";
import { useQuery } from "react-query";
import axios from "axios";
import { campusListUrl } from "../../../../utils/urls";
import NewFileUploadComponent from "../../../formUI/NewFileUploadComponent";
import { useSelector } from "react-redux";
import { getBankList } from "../../../../Services/generalService";
import { useParams } from "react-router-dom";
import {useMediumScreen,useSmallScreen} from "../../../../utils/mediaQueries"
const RestaurentDetails = ({
  formData,
  onSetState,
  tabIndexHandler,
  getDocuments,
  cityList,
}) => {
  const [cityId, setCityId] = useState(formData?.cityId || null);
  const { outletId } = useParams();
  const {isOutletDuplicateActive} = useSelector((state) => state.duplicateOutletId);
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();

  const { data: campusList } = useQuery(
    ["campus-List", cityId, formData?.cityId],
    () =>
      (formData.cityId || cityId) &&
      axios.get(campusListUrl + (cityId || formData.cityId))
  );
  const outletData = useSelector((state) => state.auth.user);
  const [header, setHeader] = useState(null);
  const [license, setLicense] = useState(null);
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    getBankList().then((res) => {
      setBankList(res?.data?.data);
    });
  }, []);
  const handlenBack = () => {
    tabIndexHandler(0);
  };

  const passwordValidation = outletId
    ? Yup.string().notRequired()
    : Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
          "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
        );
  return (
    <Formik
      initialValues={{
        ...outletData,
        ...formData,
        bankId: outletId
          ? formData?.bankId || formData?.bankDetailsId?.bankId
          : "",
        IFSCCode: outletId
          ? formData?.IFSCCode || formData?.bankDetailsId?.IFSCCode
          : "",
        accountNumber: outletId
          ? formData?.accountNumber || formData?.bankDetailsId?.accountNumber
          : "",
        password: "",
        campusId: outletId
          ? {
              id: formData?.campusId?.campusId,
              value: formData?.campusId?.campusId,
              name: formData?.campusId?.campusName,
            }
          : {},
        cityId: outletId
          ? {
              id: formData?.campusId?.cityId?.cityId,
              value: formData?.campusId?.cityId?.cityId,
              name: formData?.campusId?.cityId?.city,
            }
          : {},
      }}
      onSubmit={(values) => {
        onSetState((prev) => ({
          ...prev,
          ...values,
          cityId: values.cityId.id,
          campusId: values.campusId.id,
        }));

        tabIndexHandler(2);
        getDocuments([header, license]);
      }}
      validationSchema={Yup.object().shape({
        outletName: Yup.string()
          .required("Outlet Name is required")
          .min(3, "Outlet Name must be at least 3 characters")
          .max(30, "Outlet Name can't be more than 30 characters"),
        address: Yup.string().required("Address is required"),
        password: passwordValidation,
        bankId: Yup.string().required("Bank Name is required"),
        campusId: Yup.object().required("Campus is required"),
        cityId: Yup.object().required("City is required"),
        IFSCCode: Yup.string()
          .required("IFSC Code is required")
          .matches(/^[A-Za-z]{4}\d{7}$/, "Invalid IFSC code"),
        accountNumber: Yup.string()
          .required("Bank Account Number is required")
          .matches(/^[0-9]{9,18}$/, "Invalid bank account number"),
        hasMess: Yup.string().required("Outlet type is required")
        // email: Yup.string()
        //   .email("Invalid email")
        //   .required("Email is required"),
        // mobile: Yup.string()
        //   .required("Mobile number is required")
        //   .matches(mobilePattern, "Invalid mobile number"),
      })}
    >
      {(formik) => (
        <Form>
          <Stack
            sx={{
              minHeight: "550px",
            }}
          >
            <FormHeader>Restaurant Basic Details:</FormHeader>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomTextFieldFormik
                  label="Restaurant Name"
                  placeholder="Enter your Restaurant Name"
                  required={true}
                  name={"outletName"}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Field type="radio" name="hasMess" value="true" checked={formData?.hasMess == true}/>
                  <label htmlFor="messmanager">Mess</label>
                </Box>

                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Field type="radio" name="hasMess" value="false" checked={formData?.hasMess == false}/>
                  <label htmlFor="foodorder">Outlet</label>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutoComplete
                  label="Select City"
                  placeholder="Select"
                  required
                  name="cityId"
                  menuList={
                    cityList?.data?.data?.map((c) => ({
                      id: c.cityId,
                      value: c.cityId,
                      name: `${c.city}, ${c.state}, ${c.country}`,
                    })) || []
                  }
                  onChange={(e, val) => {
                    setCityId(e.target.value.id);
                  }}
                  defaultValue={
                    outletId
                      ? {
                          id: formData?.campusId?.cityId?.cityId,
                          name: formData?.campusId?.cityId?.city,
                          value: formData?.campusId?.cityId?.cityId,
                        }
                      : ""
                  }
                />
                {/* <CustomSelectFormik
                label="Select City"
                placeholder="Select"
                required
                name="cityId"
                menuList={
                  cityList?.data?.data?.map((c) => ({
                    id: c.cityId,
                    value: c.cityId,
                    name: `${c.city}, ${c.state}, ${c.country}`,
                  })) || []
                }
                onChange={(e) => setCityId(e.target.value)}
              /> */}
              </Grid>
              <Grid item xs={12} md={6} key={cityId}>
                {/* <CustomSelectFormik
                disabled={!cityId}
                label="Select Campus"
                placeholder="Select"
                required
                name="campusId"
                menuList={
                  campusList?.data?.data?.map((c) => ({
                    id: c.campusId,
                    value: c.campusId,
                    name: c.campusName,
                  })) || []
                }
                // onChange={(e) =>{ setCampusId(e.target.value) }}
              /> */}
                <CustomAutoComplete
                  disabled={!cityId}
                  label="Select Campus"
                  placeholder="Select"
                  required
                  name="campusId"
                  menuList={
                    campusList?.data?.data?.map((c) => ({
                      id: c.campusId,
                      value: c.campusId,
                      name: c.campusName,
                    })) || []
                  }
                  // key={dep}
                  defaultValue={
                    outletId
                      ? {
                          id: formData?.campusId?.campusId,
                          name: formData?.campusId?.campusName,
                          value: formData?.campusId?.campusId,
                        }
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomTextFieldFormik
                  label="Restaurant Address"
                  placeholder="Enter your Restaurant Address"
                  required
                  name="address"
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
              <CustomTextFieldFormik
                label="Email"
                placeholder="Enter your Email"
                required
                name="email"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextFieldFormik
                label="Mobile number"
                placeholder="Enter your Mobile number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                name="mobile"
              />
            </Grid> */}
              {outletId ? isOutletDuplicateActive ? (
                <Grid item xs={12} md={12}>
                  <CustomTextFieldFormik
                    label="Password"
                    placeholder="Enter your Password"
                    required
                    name="password"
                  />
                </Grid>
              ): "" : (<Grid item xs={12} md={12}>
                  <CustomTextFieldFormik
                    label="Password"
                    placeholder="Enter your Password"
                    required
                    name="password"
                  />
                </Grid>)}
              {/* <Grid item xs={12} md={3}>
              <CustomSelectFormik
                label="Select City"
                placeholder="Select"
                required
                name="cityId"
                menuList={
                  cityList?.data?.data?.map((c) => ({
                    id: c.cityId,
                    value: c.cityId,
                    name: `${c.city}, ${c.state}, ${c.country}`,
                  })) || []
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomSelectFormik
                label="Select Campus"
                placeholder="Select"
                required
                name="campusId"
                menuList={
                  campusList?.data?.data?.map((c) => ({
                    id: c.campusId,
                    value: c.campusId,
                    name: c.campusName,
                  })) || []
                }
              />
            </Grid> */}
              {/* Rest of the form fields */}
            </Grid>
            <FormHeader mt={"36px"}>Restaurant Docments:</FormHeader>
            <Grid container spacing={2}>
              <Grid item xs={isSmallScreen || isMediumScreen ? 12 :6}>
                <NewFileUploadComponent
                  setFile={setHeader}
                  label={"Upload Restaurant Header"}
                  defaultImg={outletId ? formData?.headerImage : ""}
                  title={"Upload Restaurant Header"}
                  text={"Upload your Restaurant picture"}
                />
              </Grid>
              <Grid item xs={isSmallScreen || isMediumScreen ? 12 :6}>
                <NewFileUploadComponent
                  setFile={setLicense}
                  label={"Upload FSSAI License"}
                  defaultImg={outletId ? formData?.FSSAI_License : ""}
                  title={"Upload License"}
                  text={"Upload License here"}
                  isAcceptFile={(file) => file?.type === "application/pdf"}
                  warningMessage="Please select Image or PDF for FSSAI License"
                />
              </Grid>
            </Grid>
            <FormHeader mt="36px">Restaurant Bank Details:</FormHeader>
            <Grid container spacing={2}>
              <Grid xs={12} md={4} item>
                <CustomSelectFormik
                  label="Select Bank Name"
                  placeholder="Select"
                  required
                  name="bankId"
                  menuList={bankList?.map((bank, index) => ({
                    id: bank.bankId,
                    value: bank.bankId,
                    name: bank.name,
                  }))}
                />
              </Grid>
              <Grid xs={12} md={4} item>
                <CustomTextFieldFormik
                  label="IFSC Code"
                  placeholder="Enter your IFSC Code"
                  required
                  name="IFSCCode"
                />
              </Grid>
              <Grid xs={12} md={4} item>
                <CustomTextFieldFormik
                  label="Enter Bank Account Number"
                  placeholder="Bank Account Number"
                  required
                  name="accountNumber"
                />
              </Grid>
            </Grid>

            {/* Additional form fields */}
            <StackRow
              justifyContent="flex-end"
              sx={{
                marginTop: "auto",
              }}
            >
              <CustomButton
                // type="submit"
                variant="outlined"
                sx={{
                  fontWeight: "400",
                  mt: 2,
                  mr: 2,
                }}
                onClick={handlenBack}
              >
                Back
              </CustomButton>
              <CustomButton
                type="submit"
                sx={{
                  fontWeight: "400",
                  mt: 2,
                }}
              >
                Save
              </CustomButton>
            </StackRow>
          </Stack>
        </Form>
      )}

      {/* </form> */}
    </Formik>
  );
};

export default RestaurentDetails;
