import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { Box, LinearProgress, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import Headline2 from "../typographyUI/Headline2";
import Text from "../typographyUI/Text";
import Headline3 from "../typographyUI/Headline3";
import NoRowsSvg from "../../assets/images/no-rows.png";
import axios from "axios";
import CustomMenu from "../layoutUI/CustomMenu";
import EditSvg from "../svgComponents/EditSvg";
import DeleteSvg from "../svgComponents/DeleteSvg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomDialog from "../layoutUI/CustomDialog";
import StackRow from "../layoutUI/StackRow";
import CustomButton from "../formUI/CustomButton";
import Lead from "../typographyUI/Lead";

const SERVER_OPTIONS = {
  useCursorPagination: false,
};

// const { useQuery, ...data } = createFakeServer({}, SERVER_OPTIONS);

export default function CustomDataGrid({
  sx = {},
  name = "",
  columns = [],
  height,
  labelName,
  url = "",
  rowId,
  deleteUrl,
  dummyRows,
  noActions = false,
  slotsProps = {},
  getData = () => {},
  mappingData = (data) => data,
  dep = null,
  header = () => {},
  search,
  getLoading = () => {},
  filterFn = (data) => {
    return data;
  },
  noDataScreen = false,
  deleteOption = false,
  menuOnClick = null,
  noEditAction = false,
  deleteItem = false,
  setTotalItems = () => {},
  ...otherProps
}) {
  if (!name) alert("Pass name prop in CustomDataGrid");
  const handlePageChange = (params) => {};


  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [rows, setRows] = React.useState([]);

  const navigate = useNavigate();
  const { data, isLoading, isFetching, refetch } = useQuery(
    [name, url, dep],
    () => url && axios.get(url),
    {
      onSuccess: (res) => {
        getData(res?.data?.data);
        setRows(res?.data?.data);
        // console.log("Row data ",res?.data?.data)
      },
    }
  );

  const { mutate } = useMutation(
    (data) => {
      axios.post(deleteUrl + row[rowId]);
    },
    {
      onSuccess: (res) => {
        setShowDeleteModal(false);
        refetch();
        toast.success(res?.data?.msg || "Deleted Successfully");
      },
    }
  );

  const { mutate: deleteOffer } = useMutation(
    (data) => {
      return axios.post(deleteUrl, data);
    },
    {
      onSuccess: () => {
        toast.success("Offer deleted successfully!");
        refetch();
      },
    }
  );


  //
  useEffect(() => {
    setTotalItems(dummyRows?.length || rows?.length);
  }, [dummyRows, rows]);
  useEffect(() => {
    getLoading?.(isLoading || isFetching);
  }, [isLoading, isFetching]);
  const LOADING =
    !isLoading &&
    (data?.data?.data?.length == 0 || dummyRows?.length == 0) &&
    !noDataScreen;
  const _sx = {
    height: height || "580px",
    border: "none",
    ...sx,
    "&  .MuiDataGrid-menuIcon,.MuiDataGrid-columnSeparator": {
      display: "none",
      cursor: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      borderRadius: 1,
      border: "1px #E2E8F0 solid",
      borderRadius: "9px",
      background: "#F8FAFC",
      color: "#64748B",
      fontSize: "14px",
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        borderBottom: "1px solid #EAECF0",
        background: "#FFFFFF",
        borderRadius: "1rem",
      },
      "& .MuiDataGrid-row.Mui-selected:hover": {
        borderBottom: "1px solid #EAECF0",
        backgroundColor: "#FFFFFF",
      },
    },
  };

  let _menuList = [{ id: 1, icon: <EditSvg />, label: "Edit" }];
  if (deleteOption)
    _menuList.push({
      id: 2,
      icon: <DeleteSvg />,
      label: "Delete",
    });
  // return <NoData name={labelName} />;
  return (
    <div style={{ width: "100%" }}>
      {header({ refetch })}
      {LOADING ? (
        <NoData name={labelName} />
      ) : (
        <DataGrid
          getRowId={(row) => row[rowId] || "id"}
          loading={isLoading || isFetching}
          rows={filterFn(
            dummyRows ||
              mappingData(data?.data?.data)?.map((row, index) => ({
                ...row,
              })) ||
              []
          )}
          columns={
            noActions
              ? columns
              : [
                  ...columns,
                  {
                    field: "action",
                    headerName: " ",
                    minWidth: 80,
                    sortable: false,
                    renderCell: ({ row }) => (
                      <CustomMenu
                        width={"140px"}
                        menuList={_menuList}
                        menuOnClick={({ id, label }) => {
                          menuOnClick?.({ id, label }, row);
                          if (id == 1) {
                            !noEditAction && navigate(String(row[rowId]));
                          }
                          if (id == 2) {
                            if (deleteItem && id == 2) {
                              setShowDeleteModal(true);
                              setRow(row);
                            } else {
                              deleteOffer({ ...row, isDelete: true });
                            }
                          }
                        }}
                      />
                    ),
                  },
                ]
          }
          disableRowSelectionOnClick
          slots={{
            loadingOverlay: LinearProgress,
            toolbar: slotsProps?.toolbar,
          }}
          sx={_sx}
          onPageChange={handlePageChange}
          // paginationMode="server"
          {...otherProps}
        />
      )}
      {showDeleteModal && (
        <CustomDialog
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          height="500px"
        >
          <Box>
            <Stack gap={"1rem"}>
              <Lead>Are you sure do you want to delete?</Lead>
              <StackRow isFlexEnd>
                <CustomButton
                  variant="outlined"
                  sx={{
                    color: "var(--gray-700, #334155)",
                    fontFamily: "Inter",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "1.42857rem",
                    letterSpacing: "-0.01rem",
                    borderRadius: "0.57143rem",
                    border: "1px solid var(--gray-300, #CBD5E1)",
                    background: "var(--generic-white, #FFF)",
                    marginRight: "1rem",
                  }}
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  sx={{
                    borderRadius: "0.57143rem",
                    background: "var(--primary-600, #4F46E5)",
                    boxShadow: "0px 1px 2px 0px rgba(15, 23, 42, 0.06)",
                    color: "var(--generic-white, #FFF)",
                    fontFamily: "Inter",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "1.42857rem",
                    letterSpacing: "-0.01rem",
                  }}
                  onClick={() => mutate()}
                >
                  Delete
                </CustomButton>
              </StackRow>
            </Stack>
          </Box>
        </CustomDialog>
      )}
    </div>
  );
}

const NoData = ({ name = "add in prop 'name'" }) => {
  return (
    <Box
      sx={{
        display: "grid",
        placeContent: "center",
        height: "70vh",
      }}
    >
      <Stack spacing={0} alignItems={"center"}>
        <img src={NoRowsSvg} />
        <Headline3>No {name} for now</Headline3>
      </Stack>
    </Box>
  );
};
