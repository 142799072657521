import { useSelector } from "react-redux";

export const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const restaurentApis = {
  packageingChargesUrl: baseUrl + "restaurent/updatePackagingCharge/",
  loginUrl: baseUrl + "restaurent/restaurentLogin",
  outletUrl: baseUrl + "outlet/getOutletListByRestaurantId/",
  createOutletUrl: baseUrl + "outlet/createOutlet",
  campusListUrl: baseUrl + "campus/getAllCampusList",
  categoryListUrl: baseUrl + "category/getCategoryList",
  dayUrl: baseUrl + "days/getDaysList",
  cityListUrl: baseUrl + "city/getCities",

  // sub-category
  subCategoryUrl: baseUrl + "restaurent/createCategory",
  subCategoryGetUrl: baseUrl + "restaurent/category/",
  subCategoryGetSingleUrl: baseUrl + "restaurent/getCategoryById/",
  subCategoryPutUrl: baseUrl + "restaurent/updateCategory/",
  subCategoryDeleteUrl: baseUrl + "restaurent/deleteCategory/",
  subCategoryImageUrl: baseUrl + "restaurent/upsertCategoryImage",

  // category
  categoryUrl: baseUrl + "restaurent/createParentCategory",
  categoryGetUrl: baseUrl + "restaurent/getParentCategory/",
  categoryGetSingleUrl: baseUrl + "restaurent/getParentCategoryById/",
  categoryPutUrl: baseUrl + "restaurent/updateParentCategory/",
  categoryDeleteUrl: baseUrl + "restaurent/deleteParentCategory/",
  categoryImageUrl: baseUrl + "restaurent/upsertParentCategoryImage",

  // item
  itemUrl: baseUrl + "restaurent/addMenu",
  itemGetUrl: baseUrl + "restaurent/getItemList/",
  itemGetSingleUrl: baseUrl + "restaurent/getItem/",
  itemPutUrl: baseUrl + "restaurent/updateMenu/",
  itemDeleteUrl: baseUrl + "restaurent/deleteItem/",
  itemImageUrl: baseUrl + "restaurent/upsertMenuItemImage",
  deleteItemUrl: baseUrl + 'outlet/menu/deleteMenuItem/',

  // attributes
  attributeUrl: baseUrl + "outlet/menu/getAttributes",
  spiceLevelUrl: baseUrl + "common/getSpiceLevel",
  dietaryRestrictionsUrl: baseUrl + "common/getDietaryRestrictions",
  taxationUrl: baseUrl + "restaurent/updateTaxCharge",

  //staff

  staffGetUrl: baseUrl + "staff/getStaff/restaurentstaff/",

  //order 
  orderGetUrl: baseUrl+"order/order/getCurrentOrder/",
  orderUpcomingGetUrl: baseUrl+"order/order/getUpcomingOrder/",
  getOrderbyOrderId: baseUrl + "order/order/getOrder/",
  getHistoryOrders: baseUrl + "order/order/getHistoryOrders/",
  getCancelledOrders: baseUrl + "order/order/getCancelledOrders/",
  getLiveOrders: baseUrl + "order/order/getLiveOrders/",
  getReadyOrders: baseUrl + "order/order/getReadyOrders/",
  getAllOrders: baseUrl + "order/order/getAllOrder/",
  getPendingOrders: baseUrl + "order/order/getPendingOrder/",
  toggelVisibility: baseUrl + "order/order/toggleVisibility",
  removeAllFlag: baseUrl + "order/order/removeAllFlag",

  //petpooja orders
  petpoojaOrders: baseUrl + 'order/order/getHistoryPetPoojaOrders/',

  //settlement
  settlemenUrl: baseUrl + "settlement/restaurentSettlement/getOrderSettlement",
  settlementDashboard: baseUrl + "settlement/restaurentSettlement/getOutletMiniDashboard",

  //Customer
  customerDetails :  baseUrl + "order/order/lastFiveOrders/",


  //outlet
  deactiveOutlet: baseUrl + 'outlet/updateOutlet/',
  getOutletData:  baseUrl + 'outlet/getOutletData/',

  updateAdmin: baseUrl + 'admin/updateOutletAdmin/',
  pushMenuData: baseUrl + 'outlet/pushMenuData/',
  

  //offers
  getOffersByOutletId: baseUrl + 'outlet/offer/getOfferByOutletId?outletId=',
  updateOffer: baseUrl + 'outlet/offer/updateOffer',
  addOffer: baseUrl + 'outlet/offer/createOffer',

  // role


  convertObjectToFormData: function (data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];
      formData.append(key, value);
    });



    return formData;
  },
};
const outletApis = {
  packageingChargesUrl: baseUrl + "outlet/updatePackagingCharge/",
  loginUrl: baseUrl + "restaurent/restaurentLogin",
  outletUrl: baseUrl + "outlet/getOutletListByRestaurantId/",
  createOutletUrl: baseUrl + "outlet/createOutlet",
  campusListUrl: baseUrl + "campus/getAllCampusList",
  categoryListUrl: baseUrl + "category/getCategoryList",
  dayUrl: baseUrl + "days/getDaysList",
  cityListUrl: baseUrl + "city/getCities",

  // sub-category
  subCategoryUrl: baseUrl + "outlet/menu/createCategory",
  subCategoryGetUrl: baseUrl + "outlet/menu/category/",
  subCategoryGetSingleUrl: baseUrl + "outlet/menu/getCategoryById/",
  subCategoryPutUrl: baseUrl + "outlet/menu/updateCategory/",
  subCategoryDeleteUrl: baseUrl + "outlet/menu/deleteCategory/",
  subCategoryImageUrl: baseUrl + "outlet/menu/upsertCategoryImage",

  // category
  categoryUrl: baseUrl + "outlet/menu/createParentCategory",
  categoryGetUrl: baseUrl + "outlet/menu/getParentCategory/",
  categoryGetSingleUrl: baseUrl + "outlet/menu/getParentCategoryById/",
  categoryPutUrl: baseUrl + "outlet/menu/updateParentCategory/",
  categoryDeleteUrl: baseUrl + "outlet/menu/deleteParentCategory/",
  categoryImageUrl: baseUrl + "outlet/menu/upsertParentCategoryImage",

  // item
  itemUrl: baseUrl + "outlet/menu/addMenu",
  itemGetUrl: baseUrl + "outlet/menu/getItemList/",
  itemGetSingleUrl: baseUrl + "outlet/menu/getItem/",
  itemPutUrl: baseUrl + "outlet/menu/updateMenu/",
  itemDeleteUrl: baseUrl + "outlet/menu/deleteItem/",
  itemImageUrl: baseUrl + "outlet/menu/upsertMenuItemImage",
  deleteItemUrl: baseUrl + 'outlet/menu/deleteMenuItem/',

  //addons
  getAddonGroupForOutlet: baseUrl + "outlet/menu/getAddonGroupForOutlet/",
  getCategoriesWithItems:baseUrl +"outlet/menu/getCategoriesWithItems/",
  deleteAddonGroup:baseUrl + "outlet/menu/deleteAddonGroup/",
  getAddonGroupItemsForOutlet: baseUrl +"outlet/menu/getAddonGroupItemsForOutlet/",
  deleteAddonGroupItem:baseUrl+"outlet/menu/deleteAddonGroupItem/",
  createAddonGroupItem:baseUrl + "outlet/menu/createAddonGroupItem",
  addAddonGroup: baseUrl+"outlet/menu/addAddonGroup",
  getAddonGroupById:baseUrl+"outlet/menu/getAddonGroup/",
  updateAddonGroup:baseUrl+"outlet/menu/updateAddonGroup/",
  getAddonGroupItem:baseUrl+"outlet/menu/getAddonGroupItem/",
  updateAddonGroupItems:baseUrl+"outlet/menu/updateAddonGroupItems/",

  //variations
  getVariationsForOutlet:baseUrl + "outlet/menu/getVariationsForOutlet/",
  deleteVariation:baseUrl+"outlet/menu/deleteVariation/",
  addVariation:baseUrl+"outlet/menu/addVariation",
  getVariationById:baseUrl+"outlet/menu/getVariation/",
  updateVariation:baseUrl+"outlet/menu/updateVariation/",

  //Mess Manager
  getSingleMenu:baseUrl + "mess/weeklyMenu/getSingleMenu/",
  addWeeklyMenu:baseUrl + "mess/weeklyMenu/addWeeklyMenu",
  getMessDashboardData: baseUrl + "mess/dashboard/getDashboardData/",
  setMealTime: baseUrl+"mess/weeklyMenu/setMealTime",
  getWeeklyMenu:baseUrl+"mess/weeklyMenu/getWeeklyMenu/",
  deleteMessMenu:baseUrl+"mess/weeklyMenu/deleteMenu/",
  getMealTime:baseUrl+"mess/weeklyMenu/getMealTime/",
  serveMeal:baseUrl+"mess/rsvp/serveMeal",

  // attributes
  attributeUrl: baseUrl + "outlet/menu/getAttributes",
  spiceLevelUrl: baseUrl + "common/getSpiceLevel",
  dietaryRestrictionsUrl: baseUrl + "common/getDietaryRestrictions",
  taxationUrl: baseUrl + "outlet/updateTaxCharge",


  updateAdmin: baseUrl + 'admin/updateOutletAdmin/',


  //staff

  staffGetUrl: baseUrl + "staff/getStaff/restaurentstaff/",

  //order

  orderGetUrl: baseUrl+"order/order/getCurrentOrder/",
  orderUpcomingGetUrl: baseUrl+"order/order/getUpcomingOrder/",
  getOrderbyOrderId: baseUrl + "order/order/getOrder/",
  getHistoryOrders: baseUrl + "order/order/getHistoryOrders/",
  getCancelledOrders: baseUrl + "order/order/getCancelledOrders/",
  getLiveOrders: baseUrl + "order/order/getLiveOrders/",
  getReadyOrders: baseUrl + "order/order/getReadyOrders/",
  getAllOrders: baseUrl + "order/order/getAllOrder/",
  getPendingOrders: baseUrl + "order/order/getPendingOrder/",
  toggelVisibility: baseUrl + "order/order/toggleVisibility",
  removeAllFlag: baseUrl + "order/order/removeAllFlag",

  //petpooja orders
  petpoojaOrders: baseUrl + 'order/order/getHistoryPetPoojaOrders/',

  //outlet
  deactiveOutlet: baseUrl + 'outlet/updateOutlet/',
  getOutletData:  baseUrl + 'outlet/getOutletData/',

  pushMenuData: baseUrl + 'outlet/pushMenuData/',

  //settlment
  settlemenUrl: baseUrl + "settlement/restaurentSettlement/getOrderSettlement",
  settlementDashboard: baseUrl + "settlement/restaurentSettlement/getOutletMiniDashboard",

  //Customer
  customerDetails :  baseUrl + "order/order/lastFiveOrders/",

  //offers
  getOffersByOutletId: baseUrl + 'outlet/offer/getOfferByOutletId?outletId=',
  updateOffer: baseUrl + 'outlet/offer/updateOffer',
  addOffer: baseUrl + 'outlet/offer/createOffer',





  convertObjectToFormData: function (data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];
      formData.append(key, value);
    });

    return formData;
  },
};

// Create the custom hook
export function useGetApis(isRestaurant = true) {
  const { user } = useSelector((state) => state.auth);
  // const isResto = !!user?.restaurantId;
  const isOutlet = !!user?.outletId;

  return !isOutlet ? restaurentApis : outletApis;
}
