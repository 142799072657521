import axios from "axios";

async function createRole(postBody) {
    return await axios.post(process.env.REACT_APP_BACKEND_BASE_URL+'staff/role/createRole', postBody)
}
async function updateRole(roleId, postbody){
    return await axios.post(process.env.REACT_APP_BACKEND_BASE_URL+'staff/role/updateRole/'+roleId, postbody)
}
async function updateAdmin(adminId, data) {
    return await axios.post(process.env.REACT_APP_BACKEND_BASE_URL + 'admin/updateOutletAdmin/' + adminId, data).then(()=>data)
}
export {
    createRole, updateRole, updateAdmin
}