import React from "react";

const EditSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9372 3.31993C12.0107 2.24477 13.7513 2.24345 14.8248 3.31698L16.4339 4.92603C17.4982 5.9904 17.5086 7.71415 16.4572 8.79292L9.14124 16.2992C8.4351 17.0237 7.46669 17.4323 6.45575 17.4322L4.63313 17.4321C3.35455 17.4321 2.334 16.3668 2.38777 15.0883L2.4671 13.2023C2.50662 12.2627 2.89729 11.372 3.56172 10.7066L10.9372 3.31993ZM13.7646 4.37885C13.2766 3.89088 12.4854 3.89148 11.9975 4.38019L10.9361 5.44312L14.3252 8.83216L15.3842 7.74557C15.8621 7.25522 15.8574 6.4717 15.3736 5.9879L13.7646 4.37885ZM4.62198 11.7668L9.87588 6.50498L13.2777 9.90684L8.06825 15.2518C7.64456 15.6865 7.06351 15.9317 6.45695 15.9316L4.63433 15.9316C4.20814 15.9315 3.86795 15.5764 3.88588 15.1503L3.96521 13.2643C3.98892 12.7005 4.22332 12.1661 4.62198 11.7668ZM17.0957 17.3711C17.5097 17.3711 17.8454 17.0352 17.8454 16.6208C17.8454 16.2065 17.5097 15.8706 17.0957 15.8706H11.9943C11.5802 15.8706 11.2446 16.2065 11.2446 16.6208C11.2446 17.0352 11.5802 17.3711 11.9943 17.3711H17.0957Z"
        fill="#0F172A"
      />
    </svg>
  );
};

export default EditSvg;
