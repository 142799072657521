import React from "react";
import SmallText from "../typographyUI/SmallText";
import { Box } from "@mui/material";

const SmallChip = ({ label }) => {
  return (
    <Box
      sx={{
        background: "#EEF2FF",
        borderRadius: "18px",
        padding: "2px 8px",
        maxWidth: "fit-content",
      }}
    >
      <SmallText color={"#6366F1"}>{label}</SmallText>
    </Box>
  );
};

export default SmallChip;
