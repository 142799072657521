import { Box } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import PageHeader from '../components/pageUI/PageHeader'
import PaperBox from '../components/layoutUI/PaperBox'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query';
import {
  Button,
  FormControlLabel,
  Stack,
  Checkbox,
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import CustomTextFieldFormik from "../components/formUI/CustomTextFieldFormik";
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import Text from '../components/typographyUI/Text'
import { useGetApis } from '../hooks/useGetApis'
import CustomButton from '../components/formUI/CustomButton'

const WeekCheckbox = ({ week, values, setFieldValue }) => (
    <FormControlLabel
      control={
        <Checkbox
          checked={Array.isArray(values.selectedWeeks) && values.selectedWeeks.includes(week)}
          onChange={(event) => {
            const selectedWeeks = Array.isArray(values.selectedWeeks) ? [...values.selectedWeeks] : [];
            if (event.target.checked) {
              selectedWeeks.push(week);
            } else {
              const index = selectedWeeks.indexOf(week);
              if (index !== -1) {
                selectedWeeks.splice(index, 1);
              }
            }
            setFieldValue("selectedWeeks", selectedWeeks);
          }}
          name={week}
        />
      }
      label={`Week ${week.charAt(week.length - 1)}`}
    />
  );
  

const EditMessMenu = () => {
    const outletIdNew = useSelector((state) => state.outledId.outletId);
    const navigate = useNavigate();
    const {menuid1,menuid2,menuid3,menuid4} = useParams();

    const {getSingleMenu,addWeeklyMenu} = useGetApis();

    const weeks = ["1", "2", "3", "4","5","6"];

    const { data: data1 } = useQuery(['menu', menuid1], async () => {
        const response = await fetch(`${getSingleMenu}${menuid1}`);
        
        return response.json();
    });

    const { data: data2 } = useQuery(['menu', menuid2], async () => {
        const response = await fetch(`${getSingleMenu}${menuid2}`);
        return response.json();
    });

    const { data: data3 } = useQuery(['menu', menuid3], async () => {
        const response = await fetch(`${getSingleMenu}${menuid3}`);
        return response.json();
    });

    const { data: data4 } = useQuery(['menu', menuid4], async () => {
        const response = await fetch(`${getSingleMenu}${menuid4}`);
        return response.json();
    });

    console.log({data1,data2,data3,data4});

    if(!data1 || !data2 || !data3 || !data4){
        return <Text>Loading...</Text>
    }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <KeyboardBackspaceIcon
          sx={{ cursor: "pointer", marginTop: "0.3rem", fontSize: "2rem" }}
          onClick={() => navigate("/messmenu")}
        />
        <PageHeader secondary title={"Edit Menu"} />
      </Box>

      <PaperBox
        shadowed
        padding={"0 24px"}
        sx={{
          flex: "1",
          overflow: "auto",
          height: "72vh",
          maxWidth: "1250px",
          margin: "auto",
        }}
      >
        <Box id="menu-media" sx={{ pt: "24px", pb: 3 }}>
          <PageHeader secondary title={"Menu"} />
          <Formik
            initialValues={{
              dayOfWeek:data1?.data?.dayOfWeek,
              breakfast: data1?.data?.menuDescription || "",
              breakfastPrice:data1?.data?.price || "",
              lunch: data2?.data?.menuDescription || "",
              lunchPrice: data2?.data?.price || "",
              dinner: data4?.data?.menuDescription || "",
              dinnerPrice: data4?.data?.price || "",
              highTea: data3?.data?.menuDescription || "",
              highTeaPrice: data3?.data?.price || "",
              selectedWeeks: data1?.data?.weekNumber ? [String(data1?.data?.weekNumber)] : [],
              outletId: outletIdNew,
            }}
            validate={(values) => {
              const errors = {};
              
              if (!values.breakfast) {
                errors.breakfast = "Breakfast is required";
              }
              if (!values.breakfastPrice) {
                errors.breakfastPrice = "Breakfast Price is required";
              }
              if (!values.lunch) {
                errors.lunch = "Lunch is required";
              }
              if (!values.lunchPrice) {
                errors.lunchPrice = "Lunch Price is required";
              }
              if (!values.dinner) {
                errors.dinner = "Dinner is required";
              }
              if (!values.dinnerPrice) {
                errors.dinnerPrice = "Dinner Price is required";
              }
              if (!values.highTea) {
                errors.highTea = "High Tea is required";
              }
              if (!values.highTeaPrice) {
                errors.highTeaPrice = "High Tea Price is required";
              }
              if (values.selectedWeeks.length === 0) {
                errors.selectedWeeks = "At least one week must be selected";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              console.log("menu ", values);
              setSubmitting(true);
              try {
                const result = await axios.post(
                  `${addWeeklyMenu}`,
                  values
                );
                console.log("menu ", result);

                if (result?.data?.success) {
                  setSubmitting(false);
                  resetForm();
                  
                  toast.success("Menu Updated Successfully");
                  navigate("/messmenu");
                }
              } catch {
                console.log("Error while creating menu");
              }
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    marginTop: "16px",
                  }}
                >
                 

                  <CustomTextFieldFormik
                    label="Breakfast"
                    placeholder="Enter Breakfast"
                    required
                    name="breakfast"
                  />
                  <CustomTextFieldFormik
                    label="Breakfast Price"
                    placeholder="Enter Breakfast Price"
                    required
                    name="breakfastPrice"
                  />
                  <CustomTextFieldFormik
                    label="Lunch"
                    placeholder="Enter Lunch"
                    required
                    name="lunch"
                  />
                   <CustomTextFieldFormik
                    label="Lunch price"
                    placeholder="Enter Lunch Price"
                    required
                    name="lunchPrice"
                  />
                   <CustomTextFieldFormik
                    label="High Tea"
                    placeholder="Enter High Tea"
                    required
                    name="highTea"
                  />
                   <CustomTextFieldFormik
                    label="High Tea Price"
                    placeholder="Enter High Tea Price"
                    required
                    name="highTeaPrice"
                  />
                  <CustomTextFieldFormik
                    label="Dinner"
                    placeholder="Enter Dinner"
                    required
                    name="dinner"
                  />
                   <CustomTextFieldFormik
                    label="Dinner Price"
                    placeholder="Enter Dinner Price"
                    required
                    name="dinnerPrice"
                  />
                 
                  <Box>
                    {weeks.map((week) => (
                      <WeekCheckbox
                        key={week}
                        week={week}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ))}
                    <ErrorMessage
                      name="selectedWeeks"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Box>

                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      style={{
                        height: "35px",
                        width: "5rem",
                      }}
                    >
                      Update
                    </CustomButton>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </PaperBox>
    </motion.div>
  )
}

export default EditMessMenu
