import { Typography } from "@mui/material";
import React from "react";
import { useSmallScreen } from "../../utils/mediaQueries";

const Lead = ({ children, sx, ...otherProps }) => {
  const isSmallScreen = useSmallScreen();
  return (
    <Typography color={"#475569"} fontSize={isSmallScreen ? "1.5rem" :"1.2rem"} sx={sx} {...otherProps}>
      {children}
    </Typography>
  );
};

export default Lead;
