import React from "react";
import touchMp3 from "../../../../assets/images/touch.mp3";
import success from "../../../../assets/images/success.gif";
import success2 from "../../../../assets/images/s2.png";
import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../formUI/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const OnboardingSuccess = ({ sx, activeStep, restaurantInfoData }) => {
    // 
  const navigate = useNavigate();
  const audioRef = useRef(null);
  const { outletId } = useParams();


  const handlePlayClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  const handleSubmit = () => {
    navigate("/outlets");
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (activeStep == 5) {
        setShow(true);
      }
    }, 1700);
    setTimeout(() => {
        if (activeStep == 5) {
          handlePlayClick();
        }
      }, 1500);
  }, [activeStep]);

  const currentTime = new Date();

  // Format the time as "dd MMM yyyy, hh:mm a"
  const formattedTime = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  }).format(currentTime);

  return (
    <Grid
      sx={sx}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      mt={"64px"}
    >
      <audio ref={audioRef} src={touchMp3} />
      <Grid item>
        <Box
          sx={{
            position: "relative",
            height: "265px",
          }}
        >
          <img
            src={success}
            alt="success"
            style={{
              // position : 'absolute',
              top: 0,
              // left : 0
            }}
          />
          <img
            src={success2}
            alt="success"
            style={{
              position: "absolute",
              top: "99px",
              left: "160px",
              opacity: show ? "1" : "0",
              transition: "0.3s",
              filter: "hue-rotate(-13deg)",
            }}
          />
        </Box>
      </Grid>
      <Grid
        container
        spacing={"20px"}
        mt={"80px"}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "32px",
              letterSpacing: "-0.24px",
            }}
          >{restaurantInfoData.outletName} {outletId ? "Updated Successfully" :  "Created Successfully"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "20px",
              letterSpacing: "-0.14px",
              color: "var(--gray-light-500, #71717A)",
            }}
          >
            {/* 27 Jun 2030, 10:15 am */}
            {formattedTime}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "22px",
              letterSpacing: "-0.16px",
              color: "var(--gray-light-500, #71717A)",
            }}
          >
            Successful
          </Typography>
        </Grid>
      </Grid>
      <Grid item mt={"51px"}>
        <CustomButton
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          sx={{
            fontSize: {
              xs: "0.8rem",
              sm: "1rem",
              margin: "0.6rem",
              cursor: "pointer",
            },
          }}
          textContent="Back to home"
        />
      </Grid>
    </Grid>
  );
};

export default OnboardingSuccess;
