import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isOutletDuplicateActive: false,
  checkIsMess: JSON.parse(localStorage.getItem("checkIsMess")),
};

export const duplicateOutletIdSlice = createSlice({
  name: "duplicateOutlet",
  initialState,
  reducers: {
    setOutletDuplicate: (state, action) => {
      state.isOutletDuplicateActive = action.payload;
    },
    setCheckIsMess: (state, action) => {
      localStorage.setItem("checkIsMess", JSON.stringify(action.payload));
      state.checkIsMess = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOutletDuplicate, setCheckIsMess } =
  duplicateOutletIdSlice.actions;

export default duplicateOutletIdSlice.reducer;
