import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import PaperBox from "../components/layoutUI/PaperBox";
import Headline1 from "../components/typographyUI/Headline1";
import Lead from "../components/typographyUI/Lead";

import PublishSvg from "../assets/images/food.gif";
import StackRow from "../components/layoutUI/StackRow";
import Title from "../components/typographyUI/Title";
import Text from "../components/typographyUI/Text";
import CustomButton from "../components/formUI/CustomButton";
import Headline3 from "../components/typographyUI/Headline3";
import Headline4 from "../components/typographyUI/Headline4";
import { useNavigate } from "react-router-dom/dist";
import completePng from "../assets/images/complete.png";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PetPoojaDialog from "../components/layoutUI/PetPoojaDialog";
import { useDispatch, useSelector } from "react-redux";
import { updateOutlet } from "../Services/outletService";
import { toast } from "react-toastify";
import { authLogin } from "../Redux Store/Slices/auth";
import FetchMenu from "../components/pageUI/outlets/onboardForms/FetchMenu";
import CustomDialog from "../components/layoutUI/CustomDialog";

const Publish = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  return (
    <Box>
      <PaperBox
        shadowed={true}
        noBorder={true}
        sx={{
          maxWidth: "900px",
          m: "auto",
          mt: "2rem",
        }}
        boxSx={{ px: 6 }}
      >
        <Headline1>Complete your Restaurant setup...</Headline1>
        <Lead
          sx={{
            mb: "56px",
          }}
        >
          Use high quality images and product descriptions to have a great
          looking product <br /> page. Let's get started.
        </Lead>
        <Grid container spacing={"24px"}>
          <Grid item xs={7}>
            <Stack spacing={"38px"}>
              <VerticalLinearStepper setShowModal={setShowModal} />
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <img
              src={PublishSvg}
              style={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
        {showModal && (
          <FetchMenu showModal={showModal} setShowModal={setShowModal} />
        )}
      </PaperBox>
    </Box>
  );
};

export default Publish;

export const PublishCard = ({ isCurrent, onClick, index }) => {
  return (
    <StackRow gap="14px">
      <Box
        sx={{
          fontSize: "20px",
          display: "grid",
          placeContent: "center",
          borderRadius: "50%",
          border: `1px ${isCurrent ? "#4F46E5" : "#94A3B8"} solid`,
          color: `${isCurrent ? "#4F46E5" : "#94A3B8"}`,
          width: "40px",
          height: "40px",
          flex: "0 0 40px",
          position: "relative",
          top: "8px",
          background: index == 1 && !isCurrent ? completePng : "none",
        }}
      >
        <img
          src={completePng}
          style={{
            display: index == 1 && !isCurrent ? "block" : "none",
          }}
        />
        <span
          style={{
            fontWeight: "600",
          }}
        >
          {index == 1 && !isCurrent ? "complete" : index}
        </span>
      </Box>
      <Stack>
        <Headline4 fontWeight="600">Complete your profile!</Headline4>
        <Text
          sx={{
            mb: "1rem",
          }}
        >
          Start adding products to your online store now!
        </Text>
        {isCurrent && (
          <CustomButton
            sx={{
              maxWidth: "fit-content",
            }}
            onClick={onClick}
          >
            Complete Profile
          </CustomButton>
        )}
      </Stack>
    </StackRow>
  );
};

export function VerticalLinearStepper({ setShowModal }) {
  const outletDetails = useSelector((state) => state.auth.user);
  const [activeStep, setActiveStep] = React.useState(0);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const steps = [
    {
      label: "Complete your profile!",
      description: `Start adding products to your online store now!`,
      btnText: "Complete Profile",
      bntOnClick: () => navigate("settings#general"),
    },
    {
      label: "Add your first product",
      description: "Start adding products to your online store now!",
      btnText: "Add Menu",
      btnText1: "Add Menu from Parent",
      bntOnClick: () => navigate("/menu/items"),
      bntOnClick1: () => {
        return "showModal";
      },
    },
    {
      label: "Publish Now!",
      description: `Start adding products to your online store now!`,
      btnText: "Publish Now",
      bntOnClick: () => publishingOutlet(),
    },
  ];

  useEffect(() => {
    setActiveStep(Number(outletDetails?.publishProcessingStep));
  }, [outletDetails?.publishProcessingStep]);

  async function publishingOutlet() {
    try {
      let postData = {
        isPublished: true,
      };
      if (outletDetails?.publishProcessingStep === 2) {
        postData.publishProcessingStep = 3;
      }
      const updateOutletResponse = await updateOutlet(
        postData,
        outletDetails?.outletId
      );

      toast.success("Outlet published successfully.");
      dispatch(authLogin({ ...outletDetails, ...postData }));
      // closeHandler(true);
    } catch (error) {
      if (typeof error?.response?.data?.error === "string") {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error("Something went wrong please try again");
      }
    }
  }

  return (
    <Box
      sx={{
        maxWidth: 400,
      }}
    >
      {/* <PetPoojaDialog
        open={show}
        // buttonComp={(props) => <CustomButton {...props}>Add Role</CustomButton>}
        width={"650px"}
        title="Petpooja"
        subTitle={"Connect Petpooja with Mealpe for better performance."}
      /> */}
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          "& .MuiStepIcon-text": { fill: "white!important", color: "white" },
          "& .MuiStepLabel-iconContainer": {
            transform: "scale(1.8)",
            mr: 2,
            position: "relative",
            top: "1.2rem",
          },
          "& .MuiStepContent-root": {
            borderLeft: "none",
          },
          "& .MuiStepConnector-line": {
            opacity: "0",
          },
          "& .MuiStepLabel-root": {
            alignItems: "flex-start",
          },
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <Headline4 fontWeight="600"> {step.label}</Headline4>
              <Lead
                sx={{
                  mb: "1rem",
                  fontSize: "14px",
                }}
              >
                {step.description}
              </Lead>
            </StepLabel>
            <StepContent>
              <Box sx={{ mb: 2 }}>
                <StackRow gap={1}>
                  <CustomButton
                    onClick={() => {
                      step.bntOnClick();
                    }}
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {step.btnText}
                  </CustomButton>
                  {index == 1 && !outletDetails?.isPrimaryOutlet && (
                    <>
                      <Text sx={{ alignSelf: "center", fontSize: "0.8rem" }}>
                        OR
                      </Text>
                      <CustomButton
                        onClick={() => {
                          let value = step.bntOnClick1();
                          if (value == "showModal") {
                            setShowModal(true);
                          }
                        }}
                        sx={{
                          // width:'230px',
                          whiteSpace: "nowrap",
                        }}
                      >
                        {step.btnText1}
                      </CustomButton>
                    </>
                  )}
                  {/* <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button> */}
                </StackRow>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
    </Box>
  );
}
