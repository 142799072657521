import { Typography } from "@mui/material";
import React from "react";

const CustomLink = ({ children, sx, ...otherProps }) => {
  return (
    <Typography
      color={"primary"}
      sx={{
        fontSize: 14,
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default CustomLink;
