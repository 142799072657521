import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import Title from "../typographyUI/Title";
import LargeText from "../typographyUI/LargeText";

const CustomTextField = ({ inputSx, label, sx, required, startAdornment= null, ...otherProps }) => {
  return (
    <Stack spacing={0.5}>
      <LargeText fontWeight={"500"} color={"#0F172A"}>
        {label}{" "}
        <span
          style={{
            display: required ? "inline" : "none",
            color: "#EF4444",
          }}
        >
          *
        </span>
      </LargeText>
      <TextField
        autoComplete="off"
        InputProps={{startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        )}}
        sx={{
          "& .MuiInputBase-root": {
            "&:hover": {
              borderColor: "#CBD5E1",
            },
            boxShadow: "0px 1px 2px 0px rgba(15, 23, 42, 0.06)",
            borderRadius: "10px",
            "& > input": {
              padding: "11px",
              "&::placeholder": {
                color: " #94A3B8",
                opacity: "1",
              },
            },
            ...inputSx,
          },
          "& fieldset": {
            borderColor: "#CBD5E1",
          },
          ...sx,
        }}
      
        // autoComplete="off"
        {...otherProps}
      />
    </Stack>
  );
};

export default CustomTextField;
