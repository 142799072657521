import React, { useEffect, useState } from "react";
import PaperBox from "../../layoutUI/PaperBox";
import StackRow from "../../layoutUI/StackRow";
import Text from "../../typographyUI/Text";
import { Stack, Tooltip, Zoom, styled, tooltipClasses } from "@mui/material";
import SmallText from "../../typographyUI/SmallText";

import InfoPng from "../../../assets/images/Info.png";
import Headline1 from "../../typographyUI/Headline1";
import GrowthSvg from "../../svgComponents/GrowthSvg";
import axios from "axios";
import { useSelector } from "react-redux";

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem",
    boxShadow: "0px 10px 15px -3px rgba(15, 23, 42, 0.07), 0px 4px 6px 0px rgba(15, 23, 42, 0.05)",
    borderRadius: "0.6rem",
    padding: "0.6rem 0.9rem",
    minHeight: "auto",
    maxHeight: "auto",
    height: "auto",
  },
}));


const DashboardCard = ({ sx, name, amount, growth, noRupeeSymbol = false, tooltipText }) => {
  const outletData = useSelector((state) => state.auth.user);
  // 
  const currencyCode = "INR";

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: currencyCode,
  });

  const formattedCurrency = formatter.format(amount);
  // 

  function intToString(value = 0) {
    if (value === null || value === 0) {
      return 0;
    }
    let _value = value.toString().split(".")[0];
    var suffixes = ["", "K", "M", "B", "T"];
    var suffixNum = _value >= 1000 ? Math.floor(("" + value.toString().split(".")[0]).length / 3) : 0;
    var shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value));
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }


  return (
    <PaperBox padding={"1.7rem"} sx={sx}>
      <StackRow gap={1} alignItems="center">
        <Text color={"#334155"} fontWeight={500} fontSize={"1.4rem"}>
          {name}
        </Text>
        <LightTooltip
          TransitionComponent={Zoom}
          sx={{
            "& .MuiTooltip-popper": {
              background: "red",
            },
            "& 	*": {
              // background: "red",
            },
          }}
          title={
            <Stack sx={{ background: "white" }}>
              <SmallText>{tooltipText}</SmallText>
            </Stack>
          }
        >
          <img
            src={InfoPng}
            style={{
              width: "1.3rem",
              height: "1.3rem",
            }}
          />
        </LightTooltip>
      </StackRow>
      <Headline1 fontSize={"2.6rem"}>
        {/* {(noRupeeSymbol ? formattedCurrency.slice(1).slice(0,-3) :"₹" + amount?.toLocaleString("en-IN")) || 0} */}
        {noRupeeSymbol
          ? (formattedCurrency ? formattedCurrency.slice(1).slice(0, -3) : 0)
          : "₹" + (amount ? amount.toLocaleString("en-IN") : 0)
        }
      </Headline1>
      {/* <StackRow>
        <GrowthSvg />
        <Text fontWeight={500} color={"#15803D"}>
          {growth}%
        </Text>
        <Text fontWeight={500}>from last 7 days</Text>
      </StackRow> */}
    </PaperBox>
  );
};

export default DashboardCard;
