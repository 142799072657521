import { Box } from "@mui/material";
import React from "react";
import StackRow from "../layoutUI/StackRow";
import LeftArrowSvg from "../svgComponents/LeftArrowSvg";
import LargeText from "../typographyUI/LargeText";
import { useNavigate } from "react-router-dom";
import Headline1 from "../typographyUI/Headline1";

const PageHeader = ({
  showBack,
  subTitle,
  title,
  rightUi,
  bottomBorder,
  mb,
  secondary,
  sx,
  onClose = null,
  ...otherProps
}) => {
  const navigate = useNavigate();
  return (
    <StackRow
      mb={mb || "20px"}
      {...otherProps}
      alignItems="center"
      sx={{
        cursor: showBack ? "pointer" : "default",
        width: "100%",
        "& .back-wrapper svg": {
          transition: "0.3s",
          position: "relative",
          left: "-0",
        },
        "&:hover .back-wrapper svg": {
          left: "-4px",
        },
        pb: "16px",
        borderBottom: bottomBorder && "1px solid var(--gray-200, #E2E8F0)",
        ...sx,
      }}
    >
      {showBack && (
        <StackRow
          onClick={() => showBack && navigate(-1)}
          className="back-wrapper"
          gap={1}
          sx={{
            alignSelf: "flex-start",
            paddingTop: "4px",
          }}
        >
          <LeftArrowSvg />
          {!title && <LargeText>Back</LargeText>}
        </StackRow>
      )}
      <Box onClick={() => showBack && navigate(-1)}>
        <Headline1
        component='p'
          fontWeight={secondary ? 600 : 700}
          fontSize={secondary ? 20 : 30}
        >
          {title}
        </Headline1>
        <LargeText component='span' fontSize={secondary ? 14 : 16}>{subTitle}</LargeText>
        <img
        onClick={onClose}
        src={require('../../assets/icons/close.png')} style={{
          width : '2rem',
          position : 'absolute',
          top : '1.5rem',
          right : '1.5rem',
          cursor : 'pointer',
          display : onClose ? 'block' : 'none'
        }}/>
      </Box>
      <Box ml="auto">{rightUi}</Box>
    </StackRow>
  );
};

export default PageHeader;
