import { configureStore } from "@reduxjs/toolkit";

//* import reducer ( Slices files )
import authReducer from "../Redux Store/Slices/auth";
import loaderReducer from "../Redux Store/Slices/loaderSlice";
import outletIdReducer  from "./Slices/outletId";
import pendingOrderReducer from "./Slices/realtimeOrder";
import kotReducer from "./Slices/realtimeKot"
import duplicateOutletReducer from "./Slices/duplicateOutlet";

const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
    outledId : outletIdReducer,
    pendingOrders : pendingOrderReducer,
    kot : kotReducer,
    duplicateOutletId : duplicateOutletReducer,
  },
  devTools: true,
});

export default store;
