import { Star } from "@mui/icons-material";
import React from "react";

const StarCount = ({ starCount }) => {
  const stars = Array.from({ length: 5 }, (_, index) => index + 1);

  return (
    <div>
      {stars.map((star) => (
        <span key={star}>
          {star <= starCount ? (
            <Star style={{ color: "#FBBF24", fontSize: 14 }} />
          ) : (
            <Star style={{ color: "#F1F5F9", fontSize: 14 }} />
          )}
        </span>
      ))}
    </div>
  );
};

export default StarCount;
