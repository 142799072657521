import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { Box, LinearProgress, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import Headline2 from "../typographyUI/Headline2";
import Text from "../typographyUI/Text";
import Headline3 from "../typographyUI/Headline3";
import NoRowsSvg from "../../assets/images/no-rows.png";
import axios from "axios";
import CustomMenu from "../layoutUI/CustomMenu";
import EditSvg from "../svgComponents/EditSvg";
import DeleteSvg from "../svgComponents/DeleteSvg";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useState } from "react";
import CustomDialog from "../layoutUI/CustomDialog";
import Lead from "../typographyUI/Lead";
import StackRow from "../layoutUI/StackRow";
import CustomButton from "../formUI/CustomButton";
import { toast } from "react-toastify";
const SERVER_OPTIONS = {
  useCursorPagination: false,
};

// const { useQuery, ...data } = createFakeServer({}, SERVER_OPTIONS);

export default function ServerPaginationDataGrid({
  sx = {},
  name = "QUERY_NAME",
  columns = [],
  // count = 0,
  // getDataByPage = async () =>
  // getDataByPage = async () => alert("Provide getDataByPage function;"),
  // getRefetchFn = () => {},
  // dependecies = {},
  dummyRows,
  depStr = "",
  height,
  labelName,
  url = "",
  id,
  getData = () => {},
  noActions = false,
  isSort = true,
  query: _query = null,
  orderStatusId = null,
  startDate = "",
  endDate = "",
  sortType = "",
  searchText = "",
  filterFn = (data) => {
    return data;
  },
  deleteOption = false,
  deleteUrl = "",
  loading = false,
  isAuthId,
  setSelectedOrders,
}) {
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  // const { isLoading, rows, pageInfo } = useQuery(paginationModel);

  // let isLoading = false;
  // let pageInfo = {
  //   totalRowCount: count,
  // };
  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCount` from being undefined during the loading
  const [rowCount, setRowCount] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let URL;

  // console.log("isAuthId ",isAuthId)

  if (isAuthId) {
    URL = `${url}?page=${paginationModel.page + 1}&perPage=${10}&sort=${
      isSort ? "true" : "false"
    }&${queryString.stringify(
      _query
    )}&orderStatusId=${orderStatusId}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&sortType=${sortType}&visibility=${false}`;
    //if visibility false so write logic send orders which flag is not false
  } else {
    URL = `${url}?page=${paginationModel.page + 1}&perPage=${10}&sort=${
      isSort ? "true" : "false"
    }&${queryString.stringify(
      _query
    )}&orderStatusId=${orderStatusId}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&sortType=${sortType}&visibility=${true}`;
    //if visibility query not send so return all the orders which have flag true and false
  }

  const { isLoading, isFetching, refetch } = useQuery(
    [
      name,
      paginationModel.page,
      url,
      isSort,
      _query,
      orderStatusId,
      startDate,
      endDate,
      searchText,
      sortType,
      depStr,
    ],
    () => {
      return url && axios.get(URL);
    },
    {
      onSuccess: (res) => {
        // console.log("onsuccess ",res?.data?.data?.filter((vis) => vis.visibility !== false));
        getData(
          isAuthId
            ? res?.data?.data?.filter((vis) => vis.visibility !== false)
            : res?.data?.data
        );
        // console.log("visibility ",res?.data?.data)
        {
          labelName == "Order History" &&
            setSelectedOrders(
              res?.data?.data
                ?.filter((vis) => vis.visibility === false)
                .map((order) => order.order_id)
            );
        }
        setRowCount(res?.data?.meta?.totalCount);
        setRows(
          isAuthId
            ? res?.data?.data?.filter((vis) => vis.visibility !== false)
            : res?.data?.data
        );
        // setRows(res.data.data);
      },
    }
  );
  // getRefetchFn(refetch);
  const { mutate } = useMutation(
    (data) => {
      axios.post(deleteUrl + row[id]);
    },
    {
      onSuccess: (res) => {
        refetch();
        setShowDeleteModal(false);
        toast.success("Deleted Successfully");
      },
    }
  );
  useEffect(() => {
    // setRange({ from: page * size, to: (page + 1) * size - 1 });
    refetch();
  }, [paginationModel]);
  //
  // console.clear();
  //
  //

  // useEffect(
  // )

  //
  //

  // useEffect(
  //   () => {

  //    const fetchData =  async () => {
  //       setRowCount(count)
  //       let __rows = await getDataByPage({ from: 0, to: 12 })
  //
  //     }
  //     fetchData()
  //     return () => {}
  //   }

  //   ,[page,rowCount,size]
  // )
  // React.useEffect(() => {
  //   setRowCount((prevRowCountState) =>
  //     pageInfo?.totalRowCount !== undefined
  //       ? pageInfo?.totalRowCount
  //       : prevRowCountState,
  //   );
  // }, [pageInfo?.totalRowCount, setRowCount]);

  // useEffect(() =>

  const handleDelete = () => {};
  return (
    <div style={{}}>
      {/* {rows.length == 0 ? (
        <NoData name={labelName} />
      ) : ( */}
      <DataGrid
        getRowId={(row) => row[id]}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        localeText={{
          noRowsLabel: labelName
            ? `No ${labelName} available`
            : "No Data Available",
        }}
        noRowsLabel="No Settlements"
        loading={isLoading || isFetching || loading}
        sx={{
          // "& .MuiTablePagination-selectLabel, .MuiTablePagination-selectLabel":
          //   {
          //     display: "none",
          //     "& + *": {
          //       display: "none",
          //     },
          //   },
          height: height || "600px",
          border: "none",
          ...sx,
          "&  .MuiDataGrid-menuIcon,.MuiDataGrid-columnSeparator": {
            display: "none",
            cursor: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderRadius: 1,
            border: "1px #E2E8F0 solid",
            borderRadius: "9px",
            background: "#F8FAFC",
            color: "#64748B",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              borderBottom: "1px solid #EAECF0",
              background: "#FFFFFF",
              borderRadius: "1rem",
            },
            "& .MuiDataGrid-row.Mui-selected:hover": {
              borderBottom: "1px solid #EAECF0",
              backgroundColor: "#FFFFFF",
            },
          },
        }}
        rows={filterFn(dummyRows || rows || [])}
        columns={
          noActions
            ? columns
            : [
                ...columns,
                {
                  field: "action",
                  headerName: " ",
                  minWidth: 80,
                  sortable: false,
                  renderCell: ({ row }) => (
                    <CustomMenu
                      width={"140px"}
                      menuList={[
                        { id: 1, icon: <EditSvg />, label: "Edit" },
                        deleteOption && {
                          id: 2,
                          icon: <DeleteSvg />,
                          label: "Delete",
                        },
                      ]}
                      menuOnClick={({ label, id: _id }) => {
                        if (_id == 1) {
                          //
                          navigate(String(row[id]));
                        }
                        if (_id == 2) {
                          setShowDeleteModal(true);
                          setRow(row);
                        }
                      }}
                    />
                  ),
                },
              ]
        }
        rowCount={rowCount || dummyRows?.length || rows?.length || 0}
        pageSizeOptions={[10]}
        pageSize={10}
        // onPageSizeChange={(size) => setSize(size)}
        paginationModel={paginationModel}
        page={paginationModel.page}
        onPageChange={(page) => {
          setPage(page);
          // setRange({from : page * 100, to : page  * 100})
          // setRange({from : range.to + 1, to : range.to + size})

          return page;
        }}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        initialState={{ pageSize: 10 }}
      />
      {/* )} */}
      {showDeleteModal && (
        <CustomDialog
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          height="500px"
        >
          <Box>
            <Stack gap={"1rem"}>
              <Lead>Are you sure do you want to delete?</Lead>
              <StackRow isFlexEnd>
                <CustomButton
                  variant="outlined"
                  sx={{
                    color: "var(--gray-700, #334155)",
                    fontFamily: "Inter",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "1.42857rem",
                    letterSpacing: "-0.01rem",
                    borderRadius: "0.57143rem",
                    border: "1px solid var(--gray-300, #CBD5E1)",
                    background: "var(--generic-white, #FFF)",
                    marginRight: "1rem",
                  }}
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  sx={{
                    borderRadius: "0.57143rem",
                    background: "var(--primary-600, #4F46E5)",
                    boxShadow: "0px 1px 2px 0px rgba(15, 23, 42, 0.06)",
                    color: "var(--generic-white, #FFF)",
                    fontFamily: "Inter",
                    fontSize: "1rem",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "1.42857rem",
                    letterSpacing: "-0.01rem",
                  }}
                  onClick={() => mutate()}
                >
                  Delete
                </CustomButton>
              </StackRow>
            </Stack>
          </Box>
        </CustomDialog>
      )}
    </div>
  );
}

const NoData = ({ name = "add in prop 'name'" }) => {
  return (
    <Box
      sx={{
        display: "grid",
        placeContent: "center",
        height: "70vh",
      }}
    >
      <Stack spacing={0} alignItems={"center"}>
        <img src={NoRowsSvg} />
        <Headline3>Create your first {name} now</Headline3>
        <Text>
          Showcase your products or services to the customers by creating
          beautiful categories.
        </Text>
      </Stack>
    </Box>
  );
};
