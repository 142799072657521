import { Box, Dialog, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../formUI/CustomButton";
import PaperBox from "./PaperBox";
import PageHeader from "../pageUI/PageHeader";
import CParticles from "../pageUI/settings/CParticles";

const CustomDialog = ({
  open: _open = false,
  setOpen: _setOpen = () => {},
  buttonComp,
  width,
  children,
  onClose,
  sx,
  dontClose = false,
}) => {
  const [open, setOpen] = useState(_open || false);
  useEffect(() => {
    setOpen(_open);
  }, [_open]);
  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => {
          !dontClose && setOpen(!open);
          !dontClose && _setOpen(!open);
          !dontClose && onClose?.();
        }}
        sx={{
          "& .MuiDialog-paper": {
            background: "transparent",
            width: width || "auto",
            ...sx,
          },
        }}
      >
        <PaperBox padding={"24px"}>
          {typeof children == "function"
            ? children({
                onClose: () => {
                  setOpen(!open);
                  _setOpen(!open);
                },
              })
            : children}
        </PaperBox>
      </Dialog>
      {buttonComp?.({
        onClick: () => {
          setOpen(!open);
          _setOpen(!open);
        },
      })}
    </Box>
  );
};

export default CustomDialog;

{
  /* <PaperBox padding={"24px"}>
<PageHeader
  secondary
  title={"Upload Excel"}
  subTitle={"Add Menu Via Upload Excel"}
/>
</PaperBox> */
}
