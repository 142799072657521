import { Box, Drawer, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Header from "./Header";
import SideNavbar from "./SideNavbar";
import { useLocation } from "react-router-dom";
const LayoutWrapper = ({ children }) => {
  const location = useLocation();
  const matches = useMediaQuery("(max-width: 1200px)");
  const [open, setOpen] = useState(false);
  return (
    <>
      <Header sideBarHandler={() => setOpen(!open)} />
      <Box
        display={"flex"}
        flexDirection={"row"}
        flexWrap={"nowrap"}
        flex={1}
        overflow={"hidden"}
      >
        {!matches ? (
          <SideNavbar />
        ) : (
          <Drawer
            anchor={"left"}
            open={open}
            onClose={() => setOpen(!open)}
            sx={{ "& .css-oe3u8g-MuiStack-root": {width: '28rem'}}}
          >
            <SideNavbar sideBarHandler={() => setOpen(!open)} />
          </Drawer>
        )}

        <Box
          overflow={"auto"}
          flex={1}
          sx={{
            p: 4,
            // pb : 0
            // flex : '0 0 calc(100vw - 20rem)'
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default LayoutWrapper;
