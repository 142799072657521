import axios from "axios";
import { async } from "q";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

async function acceptOrder(orderId){
    return await axios.post(BACKEND_BASE_URL + 'order/order/acceptOrder/' + orderId)
}
async function rejectOrder(orderId){
    return await axios.post(BACKEND_BASE_URL + 'order/order/rejectOrder/' + orderId)
}

export{

    acceptOrder,
    rejectOrder,
}