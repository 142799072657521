import React from 'react';
import cssClasses from "./Loading.module.css";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from "@mui/material/Modal";
import { useSelector } from 'react-redux';

export default function Loading() {
    const isLoading = useSelector((state) => state.loader.isLoading);
    return (
        <Modal
            open={isLoading}
            sx={[{
                ".MuiBackdrop-root": {
                    background: "rgba(193, 201, 210, 0.7)"
                }
            }]}
            disableAutoFocus={true}
        >
            <Box className={cssClasses.modalWrapper}>
                <div className={cssClasses.content}>
                    <CircularProgress style={{ color: "#7F56D9", verticalAlign: "middle" }} />
                    <p style={{ fontSize: "1.25rem" }}>Loading...</p>
                </div>
            </Box>
        </Modal>
    )
}

