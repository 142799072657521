import React from "react";

const StepperActivatedSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#4F46E5" />
      <rect x="2" y="2" width="20" height="20" rx="10" fill="#4F46E5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.77479 13.9668L16.3748 7.24454C17.0415 6.56677 18.0526 7.35565 17.2192 8.24454C17.2192 8.24454 10.8859 16.2001 10.5526 16.6557C10.4529 16.8164 10.3139 16.9491 10.1486 17.0411C9.98332 17.1332 9.79729 17.1815 9.60812 17.1815C9.41896 17.1815 9.23293 17.1332 9.06765 17.0411C8.90237 16.9491 8.76333 16.8164 8.66368 16.6557L6.5859 13.1001C6.48031 12.9072 6.44714 12.6829 6.49238 12.4676C6.53761 12.2524 6.65827 12.0604 6.83259 11.9264C7.00691 11.7923 7.22341 11.7249 7.44304 11.7364C7.66267 11.7478 7.87096 11.8374 8.03034 11.989L9.77479 13.9668Z"
        fill="white"
      />
    </svg>
  );
};

export default StepperActivatedSvg;
