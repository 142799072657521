import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  useMediaQuery,
} from "@mui/material";
import classes from "./AddRestaurants.module.css";

import { useNavigate, useParams } from "react-router-dom";
import Headline2 from "../../typographyUI/Headline2";
import CustomButton from "../../formUI/CustomButton";
import { useQuery } from "react-query";
import axios from "axios";
import { dayUrl } from "../../../utils/urls";
import FormHeader from "../../formUI/FormHeader";
import CustomCheckbox from "../../formUI/CustomCheckbox";
import { useSelector } from "react-redux";
import CustomLoader from "../../layoutUI/CustomLoader";
import StackRow from "../../layoutUI/StackRow";

const MoreInformation = ({
  ownerInfoData,
  restaurantData,
  restaurantInfoData,
  eData,
  sx,
  column = 4,
  handler = () => {},
  warning,
  setSelectedDay = () => {},
  timeDataFromUpdateOutlet,
}) => {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery("days-list", () => axios.get(dayUrl));

  // const timeData = useSelector((state) => state.auth.user.Timing);
  const { outletIdofEditOutlet } = useParams();

  let {
    Timing: timeData = [],
    outletId,
    restaurantId,
  } = useSelector((state) => state.auth.user);
  timeData = timeDataFromUpdateOutlet?.length ? timeDataFromUpdateOutlet : timeData;
  // const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [localTimingData, setLocalTimingData] = useState(
    (timeData &&
      JSON.parse(JSON.stringify(timeData))?.map((m) => ({
        ...m,
        isSelect: Boolean(m?.openTime && m?.closeTime),
      }))) ||
      []
  );
  const [selected, setSelected] = useState(
    localTimingData
      ?.filter((e) => Boolean(e?.openTime && e?.closeTime))
      ?.map((e) => e?.dayId) || []
  );

  // useEffect(() => {
  //   setLocalTimingData(timeData || []);
  //   handler(timeData || []);
  // }, [timeData]);
  useEffect(() => {
    let _data = data?.data?.data?.map((m) => ({
      ...m,
      isSelect: Boolean(m?.openTime && m?.closeTime),
    }));
    // if (timeDataFromUpdateOutlet)
    //   setLocalTimingData(
    //     timeDataFromUpdateOutlet.map((t) => ({
    //       ...t,
    //       isSelect: !!(t?.openTime || t?.closeTime),
    //     }))
    //   );
    // else
     timeData.length == 0 && setLocalTimingData(_data);
    // timeData.length == 0 && handler(localTimingData)
  }, [data]);
 
  useEffect(() => {
    setSelectedDay(selected || []);
  }, [selected]);
  useEffect(() => {
    handler(localTimingData);
  }, [localTimingData]);
  const everydayCheckboxStyle = {
    display: "inline-block",
    verticalAlign: "middle",
    width: "18px",
    height: "21px",
  };

  const timeFieldStyle = {
    // width: "90px",
    width: "95%",
    height: "34px",
    borderRadius: "7px",
    paddingLeft: "5px",
    margin: "0 2px 0 2px",
    border: "1px solid #CBD5E1",
  };

  const timeFieldStyleFromTo = {
    width: "150px",
    height: "40px",
    borderRadius: "7px",
    paddingLeft: "5px",
    marginRight: "9px",
    border: "1px solid #CBD5E1",
  };
  const [daysData, setDaysData] = useState([]);
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } =
    daysData;

  const [openTime, setOpenTime] = useState(null);
  const [closeTime, setCloseTime] = useState(null);
  const [allDaySelected, setAllDaySelected] = useState(false);

  const handleCategoryFromTimeChange = (e) => {
    setOpenTime(e.target.value);
  };
  const handleCategoryToTimeChange = (e) => {
    setCloseTime(e.target.value);
  };

  const [getDays, setGetDays] = useState([]);
  const [getCategories, setGetCategories] = useState([]);
  // const [allTime, setAllTime] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]); // State to store checked items

  // useEffect(() => {
  //   getAllDays()
  //     .then((res) => {
  //       let tempArr = [];
  //       res.data.data.map((e) =>
  //         tempArr.push({
  //           name: e.day,
  //           value: e.dayId,
  //         })
  //       );
  //       setGetDays(tempArr);
  //     })
  //     .catch((err) =>

  //   getAllCategories()
  //     .then((res) => {
  //       let tempArr = [];
  //       res.data.data.map((e) =>
  //         tempArr.push({
  //           label: e.category,
  //           id: e.categoryId,
  //         })
  //       );
  //       setGetCategories(tempArr);
  //     })
  //     .catch((err) =>
  // }, []);

  // const [formData, setFormData] = useState({
  //   days: {
  //     monday: {
  //       dayId: "monday",
  //       openTime: "",
  //       closeTime: "",
  //       isChecked: data?.data?.data[0]?.day === "Monday",
  //     },
  //     tuesday: {
  //       dayId: "tuesday",
  //       openTime: "",
  //       closeTime: "",
  //       isChecked: data?.data?.data[1]?.day === "Tuesday",
  //     },
  //     wednesday: {
  //       dayId: "wednesday",
  //       openTime: "",
  //       closeTime: "",
  //       isChecked: false,
  //     },
  //     thursday: {
  //       dayId: "thursday",
  //       openTime: "",
  //       closeTime: "",
  //       isChecked: false,
  //     },
  //     friday: {
  //       dayId: "friday",
  //       openTime: "",
  //       closeTime: "",
  //       isChecked: false,
  //     },
  //     saturday: {
  //       dayId: "saturday",
  //       openTime: "",
  //       closeTime: "",
  //       isChecked: false,
  //     },
  //     sunday: {
  //       dayId: "sunday",
  //       openTime: "",
  //       closeTime: "",
  //       isChecked: false,
  //     },
  //     // Add other days here...
  //   },
  // });

  // const handleCheckboxChange = (dayId) => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     days: {
  //       ...prevState.days,
  //       [dayId]: {
  //         ...prevState.days[dayId],
  //         isChecked: !prevState.days[dayId].isChecked,
  //       },
  //     },
  //   }));
  // };

  const handleTimeChange = (e, dayId, field) => {
    const { value } = e.target;

    setLocalTimingData((prevTimingData) =>
      prevTimingData.map((item) =>
        item.dayId === dayId ? { ...item, [field]: value } : item
      )
    );
  };

  const [categoriesInitalState] = useState({});

  const onAllDayChange = (_checkedValue) => {
    // const updatedFormData = JSON.parse(JSON.stringify(formData));
    // Object.keys(updatedFormData.days).forEach((key) => {
    //   updatedFormData.days[key].isChecked = e.target.checked;
    // });

    // setFormData(updatedFormData);
    //
    // if (!allDaySelected) {
    //   const newArray = [];
    //   const updatedLocalTime = data.data.data.map((days) => {
    //     const index = localTimingData?.findIndex(
    //       (f) => f?.dayId === days?.dayId
    //     );
    //     if (index === -1) {
    //       newArray.push({
    //         restaurantId: restaurantId,
    //         outletId: outletId,
    //         dayId: days?.dayId,
    //         openTime: null,
    //         closeTime: null,
    //       });
    //     } else {
    //       newArray.push(localTimingData.find((e) => e?.dayId === days?.dayId));
    //     }
    //   });
    //   setLocalTimingData(newArray);
    //   setAllDaySelected(true);
    //   // setAllTime(false);
    // } else {
    //   // setAllTime(true);
    //   setLocalTimingData([]);
    //   setAllDaySelected(false);
    // }

    //
    //
    if (_checkedValue) {
      let _data = JSON.parse(JSON.stringify(localTimingData));
      _data = _data.map((m) => ({ ...m, isSelect: true }));
      setLocalTimingData([..._data]);
      setSelected(_data.map((e) => e?.dayId));
    } else {
      let _data = JSON.parse(JSON.stringify(localTimingData));
      _data = _data?.map((m) => ({
        ...m,
        openTime: null,
        closeTime: null,
        isSelect: false,
      }));
      setLocalTimingData([..._data]);
      setSelected([]);
    }
  };

  const [selectedCategory, setSelectedCategory] = useState(
    categoriesInitalState
  );

  const { categories } = selectedCategory;

  const [selectedChips, setSelectedChips] = useState([]);

  const handleChipClick = (chipId) => {
    setSelectedChips((prevSelectedChips) =>
      prevSelectedChips.includes(chipId)
        ? prevSelectedChips.filter((id) => id !== chipId)
        : [...prevSelectedChips, chipId]
    );
  };

  // const moreInfoData = {
  //   openTime: openTime,
  //   closeTime: closeTime,
  //   Restaurant_category: selectedChips,
  //   Timing: Object.values(formData?.days || {})
  //     .map((d) => {
  //       let currentDay = getDays.find(
  //         (dObj) => dObj.name.toLowerCase() == d.dayId
  //       );
  //       return {
  //         ...d,
  //         day: d.day,
  //         dayId: currentDay?.value,
  //       };
  //     })
  //     .filter((day) => day.isChecked),
  // };
  const handleChange = (e) => {};
  //

  // const combineData = {
  //   ...restaurantInfoData,
  //   ...ownerInfoData,
  //   ...eData,
  //   ...moreInfoData,
  // };

  //

  const handleSubmit = (combineData) => {
    // presentLoader();
    // createRestaurant(combineData)
    //   .then((res) => {
    //     if (res.data.success) {
    //       successToast("Restaurant Added Successfully");
    //       uploadFSSAILicense(
    //         res.data.data.restaurantId,
    //         restaurantInfoData.FSSAI_License
    //       )
    //         .then((res) => {
    //           dismissLoader();
    //           navigate(-1);
    //
    //         })
    //         .catch((err) =>
    //     }
    //   })
    //   .catch((error) => {
    //     dismissLoader();
    //
    //     errorToast(error.response.data.error);
    //   });
  };

  return (
    <>
      <div>
        <div>
          {/* <div style={{ padding: "10px 0 0.8rem 0" }}>
            <FormHeader>Times:</FormHeader>
            <div className={classes.timeFieldMainS}>
              <div>
                <p style={{ fontSize: "16px", padding: "0 10px 6px 0" }}>
                  From
                </p>
                <label>
                  <input
                    style={timeFieldStyleFromTo}
                    type="time"
                    // value={day.startTime}
                    onChange={(e) =>
                      handleCategoryFromTimeChange(e, "startTime")
                    }
                  />
                </label>
                to
              </div>
              <div>
                <p style={{ fontSize: "16px", padding: "0 10px 6px 0" }}>To</p>
                <label>
                  <input
                    style={timeFieldStyleFromTo}
                    type="time"
                    // value={day.endTime}
                    onChange={(e) => handleCategoryToTimeChange(e, "endTime")}
                  />
                </label>
              </div>
            </div>
          </div> */}
          <div style={{ paddingTop: "10px" }}>
            <FormHeader>Day:</FormHeader>
            <div style={{ padding: "0.7rem 0 0.7rem 0" }}>
              <StackRow>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        //
                        onAllDayChange(e.target.checked);
                        // setAllTime((p) => !p);
                      }}
                      name="All Days"
                      checked={
                        !localTimingData?.some((s) => !s.isSelect) &&
                        localTimingData?.length > 0
                      }
                    />
                  }
                  label="All Days"
                />
                <StackRow
                  center
                  className={classes.DayTimeFieldMain}
                  sx={{
                    display: !localTimingData?.some((s) => !s.isSelect)
                      ? "flex"
                      : "none",
                  }}
                >
                  <label>
                    <input
                      // onInvalid={(e) =>
                      //   e.target.setCustomValidity(
                      //     "Time is required if day is selected"
                      //   )
                      // }
                      onInput={(e) => e.target.setCustomValidity("")}
                      // required
                      style={{ ...timeFieldStyle, width: "120px" }}
                      type="time"
                      // value={
                      //   localTimingData.find((e) => e?.dayId === days?.dayId)
                      //     ?.openTime
                      // }
                      onChange={(e) => {
                        setLocalTimingData(
                          localTimingData.map((day) => ({
                            ...day,
                            openTime: e.target.value,
                          }))
                        );
                      }}
                    />
                  </label>
                  <div>to</div>
                  <Box>
                    <input
                      // onInvalid={(e) =>
                      //   e.target.setCustomValidity(
                      //     "Time s required if day is selected"
                      //   )
                      // }
                      onInput={(e) => e.target.setCustomValidity("")}
                      // required
                      style={{ ...timeFieldStyle, width: "120px" }}
                      type="time"
                      // value={
                      //   localTimingData.find((e) => e?.dayId === days?.dayId)
                      //     ?.closeTime
                      // }
                      // onChange={(e) =>
                      //   handleTimeChange(e, days.dayId, "closeTime")
                      // }
                      onChange={(e) => {
                        setLocalTimingData(
                          localTimingData.map((day) => ({
                            ...day,
                            closeTime: e.target.value,
                          }))
                        );
                      }}
                    />
                  </Box>
                </StackRow>
              </StackRow>
            </div>

            <div className={classes.dayTimeListMain}>
              {/* <Grid container spacing={"20px"}>
    
                {Object.values(formData.days).map((day) => (
                  <Grid item xs={4}>
            
                    <div key={day.dayId} className={classes.dayTimeFieldBox}>
                      <div className={classes.timeFieldMain}>
                        <CustomCheckbox
                          type="checkbox"
                          name={day.dayId}
                          checked={day.isChecked}
                          label={day.dayId}
                          onChange={() => handleCheckboxChange(day.dayId)}
                        />

                        {day.isChecked && (
                          <div className={classes.DayTimeFieldMain}>
                            <label>
                              <input
                                style={timeFieldStyle}
                                type="time"
                                value={day.startTime}
                                onChange={(e) =>
                                  handleTimeChange(e, day.dayId, "startTime")
                                }
                              />
                            </label>
                            to
                            <label>
                              <input
                                style={timeFieldStyle}
                                type="time"
                                value={day.endTime}
                                onChange={(e) =>
                                  handleTimeChange(e, day.dayId, "endTime")
                                }
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                ))}
                
              </Grid> */}

              <Box
                display={"flex"}
                gap={"20px"}
                flexDirection={"row"}
                flexWrap={"wrap"}
              >
                {isLoading ? (
                  <CustomLoader />
                ) : (
                  data?.data?.data?.map((days) => (
                    <Box
                      key={days.day}
                      className={classes.dayTimeFieldBoxWrapper}
                    >
                      <div key={days.day} className={classes.dayTimeFieldBox}>
                        <div className={classes.timeFieldMain}>
                          <CustomCheckbox
                            type="checkbox"
                            name={days.day}
                            labelSx={{
                              width: "90px",
                            }}
                            checked={Boolean(
                              localTimingData?.find(
                                (f) => f?.dayId === days?.dayId
                              )?.isSelect
                            )}
                            label={days.day}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              const updatedSelected = isChecked
                                ? selected.filter((s) => s !== days.dayId)
                                : [...selected, days.dayId];

                              setSelected(updatedSelected);

                              const index = localTimingData?.findIndex(
                                (f) => f?.dayId === days?.dayId
                              );
                              const updatedTimingData = [...localTimingData];

                              if (index > -1) {
                                updatedTimingData[index] = {
                                  ...updatedTimingData[index],
                                  openTime: isChecked
                                    ? null
                                    : updatedTimingData[index].openTime,
                                  closeTime: isChecked
                                    ? null
                                    : updatedTimingData[index].closeTime,
                                  isSelect: isChecked,
                                };

                                // If checkbox is unchecked, set additional properties to null and false
                                if (!isChecked) {
                                  updatedTimingData[index] = {
                                    ...updatedTimingData[index],
                                    openTime: null,
                                    closeTime: null,
                                    isSelect: false,
                                  };
                                }
                              } else {
                                updatedTimingData.push({
                                  outletId: outletId,
                                  dayId: days?.dayId,
                                  openTime: isChecked ? null : null,
                                  closeTime: isChecked ? null : null,
                                  isSelect: isChecked,
                                });
                              }

                              setLocalTimingData(updatedTimingData);
                            }}
                          />
                          {localTimingData?.find(
                            (f) => f?.dayId === days?.dayId
                          )?.isSelect && (
                            <div
                              className={classes.DayTimeFieldMain}
                              style={{ flex: 1, gap: "5px" }}
                            >
                              <Box flex={1}>
                                <input
                                  onInvalid={(e) =>
                                    e.target.setCustomValidity(
                                      "Time is required if day is selected"
                                    )
                                  }
                                  onInput={(e) =>
                                    e.target.setCustomValidity("")
                                  }
                                  required
                                  style={timeFieldStyle}
                                  type="time"
                                  value={
                                    localTimingData.find(
                                      (e) => e?.dayId === days?.dayId
                                    )?.openTime || ""
                                  }
                                  onChange={(e) =>
                                    handleTimeChange(e, days.dayId, "openTime")
                                  }
                                />
                              </Box>
                              <div>to</div>
                              <Box flex={1}>
                                <input
                                  onInvalid={(e) =>
                                    e.target.setCustomValidity(
                                      "Time is required if day is selected"
                                    )
                                  }
                                  onInput={(e) =>
                                    e.target.setCustomValidity("")
                                  }
                                  required
                                  style={timeFieldStyle}
                                  type="time"
                                  value={
                                    localTimingData.find(
                                      (e) => e?.dayId === days?.dayId
                                    )?.closeTime || ""
                                  }
                                  onChange={(e) =>
                                    handleTimeChange(e, days.dayId, "closeTime")
                                  }
                                />
                              </Box>
                            </div>
                          )}
                        </div>
                      </div>
                    </Box>
                  ))
                )}
              </Box>
              {/* {warning && selected.length === 0 && (
                <span
                  style={{
                    color: "red", // or any other color you want for warnings
                    // fontWeight: 'bold',
                    fontSize: "14px", // Set the font size you desire
                  }}
                >
                  Add at least one day
                </span>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreInformation;

const radioButtonStyle = {
  ".MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: "#344054",
    lineHeight: "1.25rem",
  },
  "&.MuiFormControlLabel-root": {
    border: "1px solid",
    borderRadius: "2rem",
    paddingRight: "30px",
    height: "2rem",
    backgroundColor: "#f0f1f3",
  },
};

const customTimeStyle = {
  " .MuiInputBase-root .MuiOutlinedInput-root": {
    width: "130px",
  },
};
