import { Box, Button, Grid, IconButton } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import PageHeader from "../PageHeader";
import StackRow from "../../layoutUI/StackRow";
import CustomSearchTextField from "../../formUI/CustomSearchTextField";
import DateFilter from "../filters/DateFilter";
import CustomFilter from "../filters/CustomFilter";
import StatusFilter from "../filters/StatusFilter";
import CustomDataGrid from "../CustomDataGrid";
import ItemCard from "../ItemCard";
import CustomSwitch from "../../formUI/CustomSwitch";
import Text from "../../typographyUI/Text";
import CustomChip from "../CustomChip";
import { useSelector } from "react-redux";
import { useGetApis } from "../../../hooks/useGetApis";
import { Stack } from "@mui/system";
import moment from "moment";
import CloseButtonPng from "../../../assets/icons/CloseButton.png";
import { acceptOrder, rejectOrder } from "../../../Services/orderService";
import { OrderStatus } from "./OrderStatus";
import { useNavigate } from "react-router-dom";
import CustomLink from "../../formUI/CustomLink";
import ServerPaginationDataGrid from "../ServerPaginationDataGrid";
import CustomMenu from "../../layoutUI/CustomMenu";
import axios from "axios";
import RestoListFilter from "../filters/RestoListFilter";
import CustomTextField from "../../formUI/CustomTextField";
import CustomButton from "../../formUI/CustomButton";
import QRScanner from "../../formUI/QRScanner";
import Verify from "../../../assets/icons/Left Icon.png";
import { toast } from "react-toastify";
import ConfirmationModal from "./ConfirmationModal";
import {
  useSmallScreen,
  isMobile,
  useMediumScreen,
} from "../../../utils/mediaQueries";

const ReadyOrders = ({ getAllOrderDetails }) => {
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const { user } = useSelector((state) => state.auth);
  const { getReadyOrders, getLiveOrders } = useGetApis();
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [dep, setDep] = useState(null);
  const [otp, setOtp] = useState("");
  const [outletId, setOutletId] = useState(user?.outletId);
  const [orderId, setOrderId] = useState([]);
  const [stopLoader, setStopLoader] = useState(false);
  const [sortType, setSortType] = useState("");
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const [qrOrderId, setQrOrderId] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(null);

  const navigate = useNavigate();

  const [chipColor] = useState({
    accepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    pickUp: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    delivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    dispatch: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });

  // const { getOrderbyOrderId } = useGetApis();

  // useEffect(() => {
  //   getOrderItem();
  // }, []);

  // const getOrderItem = async () => {
  //   const item = await fetch(getOrderbyOrderId`/${order_id}`);

  //   const finalItem = await item.json();

  //   console.log("items ", finalItem);
  // };

  const orderDetails = (orderId) => {
    navigate(`/orders/${orderId}`);
  };
  const handleAccept = (orderId) => {
    acceptOrder(orderId)
      .then((res) => {})
      .catch((error) => {});
  };
  const handleReject = (orderId) => {
    rejectOrder(orderId)
      .then((res) => {})
      .catch((error) => {});
  };
  const verifyOtp = (orderId, otp) => {
    axios
      .post(
        process.env.REACT_APP_BACKEND_BASE_URL + "order/order/orderVerifyOTP",
        { otp: otp, orderId: orderId, outletId: outletId }
      )
      .then((res) => {
        setConfirmationModal(null);
        toast.success("Otp verified");
        setDep(Math.random());
        getAllOrderDetails();
      })
      .catch((error) => {
        setConfirmationModal(null);
        toast.error(error?.response?.data?.error);
        setStopLoader(true);
        //
      });
  };

  const bypassOtp = (orderId) => {
    axios
      .post(
        process.env.REACT_APP_BACKEND_BASE_URL + "order/order/orderBypassOTP",
        { orderId: orderId, outletId: outletId }
      )
      .then((res) => {
        toast.success("Otp Bypass Successfully");
        setConfirmationModal(null);
        setDep(Math.random());
        getAllOrderDetails();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
        setStopLoader(true);
        //
      });
  };

  const getFilterData = (data, _orderId) => {
    let _data = [...data];
    // console.log("data ",data)
    if (type)
      _data = _data?.filter((row) =>
        type == "Dining"
          ? row.is_dine_in
          : type == "Pickup"
          ? row.is_pick_up
          : type == "Delivery"
          ? row.is_delivery
          : true
      );
    if (sortType == "Ascending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeA - dateTimeB;
      });
    }
    if (sortType == "Descending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeB - dateTimeA;
      });
    }
    if (qrOrderId.length) {
      _data = _data?.filter((o) => !qrOrderId.includes(o.order_id));
    }
    if (search) {
      _data = _data?.filter((row) => row.order_sequence_id.includes(search));
    }
    return _data;
  };
  const columns = useMemo(() => [
    {
      field: "order_id",
      headerName: "Order ID",
      flex: 1,
      minWidth: 80,
      maxWidth: 130,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.order_sequence_id}</Text>,
    },
    {
      field: "order_items",
      headerName: "Items",
      flex: 1,
      minWidth: 140,
      maxWidth: 130,
      sortable: false,
      renderCell: ({ row }) => (
        <Text bold>
          {row?.menu_item?.map((item) => (
            <div>
              {item?.itemname} x {item?.quantity}
            </div>
          ))}
        </Text>
      ),
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1,
      minWidth: 160,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "50%",
          }}
          img={row?.photo}
          title={row?.customer_name}
          subTitle={`+ ${row?.mobile}`}
          center
          sx={{
            height: 36,
          }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Order Verify",
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            {row?.iscashondelivery ? (
              <Button
                variant="outlined"
                onClick={() =>
                  setConfirmationModal({
                    text1: "Collect cash and verify order",
                    text2: `price : ₹${row?.total_price}`,
                    btn1Text: "Collect",
                    btn2Text: "Cancel",
                    btn1Handler: () => bypassOtp(row?.order_id),
                    btn2Handler: () => setConfirmationModal(null),
                    codOrder: true,
                    row: row,
                    verifyOtp: verifyOtp,
                    verifyBypassOtp: bypassOtp,
                    chipColor: chipColor,
                    stopLoader: stopLoader,
                    setStopLoader: setStopLoader,
                    confirmationModal: confirmationModal,
                    setConfirmationModal: setConfirmationModal,
                    bypass: true,
                  })
                }
              >
                Cash Collection
              </Button>
            ) : (
              <>
                <OTPVerification
                  row={row}
                  verifyOtp={verifyOtp}
                  chipColor={chipColor}
                  stopLoader={stopLoader}
                  setStopLoader={setStopLoader}
                />
                <OTPVerificationButton
                  row={row}
                  verifyOtp={bypassOtp}
                  chipColor={chipColor}
                  stopLoader={stopLoader}
                  setStopLoader={setStopLoader}
                  confirmationModal={confirmationModal}
                  setConfirmationModal={setConfirmationModal}
                  bypass={true}
                />
              </>
            )}
          </div>
        );
      },
    },
    // {
    //   field: "X",
    //   headerName: "X",
    //   flex: 1,
    //   minWidth: 90,
    //   sortable: false,
    //   renderCell: ({ row }) => {
    //     return (
    //       <OTPVerificationButton
    //         row={row}
    //         verifyOtp={bypassOtp}
    //         chipColor={chipColor}
    //         stopLoader={stopLoader}
    //         setStopLoader={setStopLoader}
    //       />
    //     );
    //   },

    // },
    {
      field: "scan",
      headerName: "Scan",
      flex: 1,
      minWidth: 80,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <QRScanner
            setQrOrderId={setQrOrderId}
            outletId={user?.outletId}
            afterScanMethod={(_orderId) => {
              setQrOrderId((pre) => [...pre, _orderId]);
            }}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "Action",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CustomLink
            onClick={() => orderDetails(row?.order_id)}
            sx={{
              cursor: "pointer",
            }}
          >
            View Details
          </CustomLink>
        );
      },
    },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      minWidth: 120,
      maxWidth: 250,
      sortable: false,
      renderCell: ({ row }) => {
        let label, type;

        if (row?.is_dine_in) {
          label = "Dine In";
          type = "dineIn";
        } else if (row?.is_pick_up) {
          label = "Pick Up";
          type = "pickUp";
        } else if (row?.is_delivery) {
          label = "Delivery";
          type = "foodready";
        } else {
          label = "Unknown";
          type = "Unknown";
        }

        return (
          <div>
            <CustomChip
              label={label}
              textColor={chipColor[type]?.textColor}
              backgroundColor={chipColor[type]?.backgroundColor}
            />
            {row?.is_delivery && row?.delivery_addresss && (
              <div
                style={{
                  marginTop: "5px",
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                {row.delivery_addresss}
              </div>
            )}
          </div>
        );
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <CustomChip
    //       label={row.orderId.orderStatusId.text}
    //       textColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]?.textColor
    //       }
    //       backgroundColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]
    //           ?.backgroundColor
    //       }
    //     />
    //   ),
    // },
    {
      field: "order_value",
      headerName: "Order Value",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text bold>₹ {(row?.total_price).toFixed(2)}</Text>
        </>
      ),
    },
    {
      field: "Delivery Time",
      headerName: "Delivery Time",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");
        const formattedTime = parsedTime.format("h:mm A");

        const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");
        let formattedAdjustedTime;

        if (row?.schedule_now) {
          const preparation_time = row?.preparation_time || 0;
          const adjustedTime = parsedTime.add(preparation_time + 15, "minutes");

          // Check if the adjusted time is past 12 AM
          // if (adjustedTime.isAfter(moment('12:00 AM', "h:mm A"))) {
          //   parsedDate2.add(1, 'day'); // Add 1 day to the date
          // }

          formattedAdjustedTime = adjustedTime.format("h:mm A");
        }

        const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");

        return (
          <Stack>
            <Text bold>{formattedDate2}</Text>
            <Text bold>
              {row?.schedule_now ? formattedAdjustedTime : formattedTime}
            </Text>
          </Stack>
        );
      },
    },
  ]);
  return (
    <Box p={0}>
      <PageHeader
        secondary
        title="Ready Orders"
        subTitle={"Your ready orders are here."}
      />
      {isSmallScreen ? (
        <Box sx={{ width: "100%", mb: isSmallScreen ? 2 : 3 }}>
          <Grid container spacing={2} alignItems="center">
            {/* Search Bar */}
            <Grid item xs={12} md={4}>
              <CustomSearchTextField
                placeholder="Search by Order ID"
                value={search}
                onChange={(e) =>
                  setSearch(e.target.value.replace(/[^0-9]/g, ""))
                }
                fullWidth
              />
            </Grid>

            {/* Filters */}
            <Grid item xs={12} md={3}>
              <StatusFilter
                label="Sort By Time"
                menuList={[
                  { id: 1, label: "Ascending" },
                  { id: 2, label: "Descending" },
                ]}
                onClick={(label) => setSortType(label)}
                fullWidth={isSmallScreen}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StatusFilter
                label="Order Type"
                defaultValue={"All"}
                menuList={[
                  { id: null, label: "All" },
                  { id: 1, label: "Dining" },
                  { id: 2, label: "Pickup" },
                  { id: 3, label: "Delivery" },
                ]}
                onClick={(label) => setType(label)}
                fullWidth={isSmallScreen}
              />
            </Grid>
            <Grid item xs={6} md={3}>
            <QRScanner setQrOrderId={setQrOrderId} outletId={user?.outletId} />
            </Grid>
           
          </Grid>
        </Box>
      ) : (
        <StackRow between mb={"24px"}>
          <CustomSearchTextField
            placeholder="Search by Order ID"
            value={search}
            onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
          />
          <StackRow gap="20px" center>
            <StatusFilter
              label="Sort by Time"
              menuList={[
                { id: 1, label: "Ascending" },
                { id: 2, label: "Descending" },
              ]}
              onClick={(label) => setSortType(label)}
            />
            <StatusFilter
              label="Order Type"
              defaultValue={"All"}
              menuList={[
                { id: null, label: "All" },
                { id: 1, label: "Dining" },
                { id: 2, label: "Pickup" },
                { id: 3, label: "Delivery" },
              ]}
              onClick={(label) => setType(label)}
            />
            <QRScanner setQrOrderId={setQrOrderId} outletId={user?.outletId} />
          </StackRow>
        </StackRow>
      )}
      <CustomDataGrid
        name="ready_orders"
        rowId={"order_id"}
        url={getReadyOrders + outletIdNew}
        columns={columns}
        labelName="Ready Order"
        // query={{
        //   orderType: type == "Dining" ? "dinein" : type == "Pickup" ? "pickup" : type ==  "Delivery" ? "delivery" : '',
        //   // orderStatusId: status,
        //   orderSequenceId: search,
        // }}
        // orderStatusId={status}
        //+"?outletId="+user.outletId ||+
        noActions
        dep={dep}
        // mappingData={(data) => {
        //   let _data = data;
        //   if (type)
        //     _data = _data?.filter((row) =>
        //       type == "Dining"
        //         ? row.is_dine_in
        //         : type == "Pickup"
        //         ? row.is_pick_up
        //         : type == "Delivery"
        //         ? row.is_delivery
        //         : true
        //     );
        //     if(sortType == 'Ascending'){
        //       _data = [..._data].sort((a, b) => {
        //         const dateTimeA = new Date(`${a.order_schedule_date}T${a.order_schedule_time}`);
        //     const dateTimeB = new Date(`${b.order_schedule_date}T${b.order_schedule_time}`);
        //     return dateTimeA - dateTimeB;
        //       })
        //     }
        //     if(sortType == 'Descending'){
        //       _data = [..._data].sort((a, b) => {
        //         const dateTimeA = new Date(`${a.order_schedule_date}T${a.order_schedule_time}`);
        //     const dateTimeB = new Date(`${b.order_schedule_date}T${b.order_schedule_time}`);
        //     return dateTimeB - dateTimeA;
        //       })
        //     }
        //   if (search)
        //     _data = _data?.filter((row) =>
        //       row.order_sequence_id.includes(search)
        //     );

        //   return _data;
        // }}
        filterFn={getFilterData}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 300}
      />

      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
    </Box>
  );
};

export default ReadyOrders;

export const OTPVerification = ({
  row,
  verifyOtp,
  chipColor,
  stopLoader,
  setStopLoader,
}) => {
  const [otp, setOtp] = useState("");
  return (
    <StackRow center>
      <CustomTextField
        value={otp}
        sx={{ zoom: "0.8", width: "80px" }}
        onChange={(e) => setOtp(e.target.value)}
      />

      <OrderStatus
        noRejectBtn
        row={row}
        chipColor={chipColor}
        handleAccept={(id) => verifyOtp(id, otp)}
        title="Verify"
        disable={otp.length < 4 || otp.length > 4}
        noChip
        button
        stopLoader={stopLoader}
        setStopLoader={setStopLoader}
      />
    </StackRow>
  );
};

export const OTPVerificationButton = ({
  row,
  verifyOtp,
  chipColor,
  stopLoader,
  setStopLoader,
  setConfirmationModal,
  confirmationModal,
  bypass,
}) => {
  return (
    <StackRow center>
      <OrderStatus
        noRejectBtn
        row={row}
        chipColor={chipColor}
        handleAccept={(id) => verifyOtp(id)}
        title="X"
        noChip
        button
        stopLoader={stopLoader}
        setStopLoader={setStopLoader}
        setConfirmationModal={setConfirmationModal}
        confirmationModal={confirmationModal}
        bypass={bypass}
      />
    </StackRow>
  );
};
