import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import Title from "../typographyUI/Title";
import SearchSvg from "../svgComponents/SearchSvg";

const CustomSearchTextField = ({ label, sx, ...otherProps }) => {
  return (
    <TextField
    autoComplete="off"
      sx={{
        maxWidth: "233px",

        "& .MuiInputBase-root": {
          fontSize: "14px",

          "&:hover": {
            borderColor: "#CBD5E1",
          },
          boxShadow: "0px 1px 2px 0px rgba(15, 23, 42, 0.06)",
          borderRadius: "5px",
          "& > input": {
            padding: "6px 9px",
            paddingLeft: 0,
            fontSize: "14px",
            "&::placeholder": {
              color: " #94A3B8",
              opacity: "1",
            },
          },
        },
        "& fieldset": {
          borderColor: "#CBD5E1",
        },
        ...sx,
      }}
      {...otherProps}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchSvg />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomSearchTextField;
