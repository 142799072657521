import { Box, Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import PageHeader from "../PageHeader";
import CustomButton from "../../formUI/CustomButton";
import CustomTextFieldFormik from "../../formUI/CustomTextFieldFormik";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PaperBox from "../../layoutUI/PaperBox";
import StackRow from "../../layoutUI/StackRow";
import Text from "../../typographyUI/Text";
import PetpoojaImg from "../../../assets/images/image 64.png";
import PetPoojaDialog from "../../layoutUI/PetPoojaDialog";
import CustomChip from "../CustomChip";
import { useSelector } from "react-redux";
import PetPoojaConfigDialog from "../../layoutUI/PetPoojaConfigDialog";

const Integrations = () => {
  const handleSubmit = (values) => {};
  const outletData = useSelector((state) => state.auth.user);

  const [isOpen, setIsOpen] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  return (
    <PaperBox
      padding={"24px"}
      sx={{ width: "300px", borderRadius: "16px" }}
      shadowed
    >
      <StackRow between center>
        <StackRow gap={1} alignItems="center" marginBottom="24px">
          <img src={PetpoojaImg} style={{ width: "48px", height: "48px" }} />
          <Text bold color={"#101828"} fontSize={16}>
            Petpooja
          </Text>
        </StackRow>
        {outletData?.petPoojaRestId && (
          <CustomChip
            sx={{
              borderRadius: "18px",
              // display: "flex",
              // padding: "8px 16px",
              // justifyContent: "flex-end",
              // alignItems: "center",
              gap: "6px",
              fontSize: "14px",
              fontWeight: "500",
              marginBottom: "24px",
              // width: "150px"
            }}
            label={"Connected"}
            textColor="#15803D"
            backgroundColor="#F0FDF4"
          />
        )}
      </StackRow>
      <StackRow>
        <Text color={"#667085"} fontWeight={400} fontSize={14}>
          Next-generation restaurant management software
        </Text>
      </StackRow>
      <Divider
        sx={{
          margin: "18px 0",
        }}
      />
      <StackRow between center>
        {outletData?.petPoojaRestId && (
          <CustomButton
            onClick={() => setShowConfig(true)}
            variant="outlined"
            sx={{
              filter: "grayscale(0.8)",
              display: "block",
            }}
          >
            Show Config
          </CustomButton>
        )}
        {outletData?.petPoojaRestId ? (
          <Text
            color="green"
            bold
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(true)}
          >
            Edit
          </Text>
        ) : (
          <CustomButton
            onClick={() => setIsOpen(true)}
            variant="outlined"
            sx={{
              filter: "grayscale(1)",
              display: "block",
            }}
          >
            Connect
          </CustomButton>
        )}
      </StackRow>

      <PetPoojaDialog
        open={isOpen}
        width={"650px"}
        title="Petpooja"
        subTitle={"Connect Petpooja with Mealpe for better performance."}
        closeHandler={(flag) => {
          setIsOpen(false);
          // if (flag) {
          //   setIsFetchMenuModalOpen(true);
          // }
        }}
        setIsConnected={setIsConnected}
      />
      <PetPoojaConfigDialog
        title="MealPe Petpooja Config Details"
        subTitle={"Configure PetPooja with MealPe configuration details."}
        open={showConfig}
        closeHandler={() => {
          setShowConfig(false);
        }}
      />
    </PaperBox>
  );
};

export default Integrations;
