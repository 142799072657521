import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import {
  Box,
  Stack,
  Button,
  IconButton,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Checkbox,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { motion } from "framer-motion";
import PaperBox from "../../../components/layoutUI/PaperBox";
import { Formik, Form } from "formik";
import CustomTextFieldFormik from "../../../components/formUI/CustomTextFieldFormik";
import { useNavigate, useParams } from "react-router-dom";
import CustomRadiobox from "../../../components/CustomRadiobox";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Text from "../../../components/typographyUI/Text";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import {
  dismissLoader,
  presentLoader,
} from "../../../Redux Store/loaderService";
import { useGetApis } from "../../../hooks/useGetApis";

const EditAddonVariation = () => {
  const {getVariationById,getCategoriesWithItems,updateVariation} = useGetApis();
  const [variation, setVariation] = useState(null); // Changed to null to check if data is loaded
  const [loading, setLoading] = useState(false);
  const [itemsWithCategory, setItemsWithCategory] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const navigate = useNavigate();
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const { aid } = useParams();

  const getVariation = async () => {
    try {
      const response = await axios.get(
        `${getVariationById}${aid}`
      );
      // console.log("Variation Api response ", response?.data?.data);

      if (response?.data?.success) {
        setVariation(response?.data?.data);
        setSelectedItems(response?.data?.data.itemArray || []);
        // console.log("items id ", response?.data?.data?.itemArray);
      }
    } catch (error) {
      console.log("Error while fetching variation by id", error);
    }
  };

  const getItemsWithCategory = async () => {
    setLoading(true);
    try {
      const items = await axios.get(
        `${getCategoriesWithItems}${outletIdNew}`
      );

      // console.log("Get Category with items ", items?.data?.data);
      if (items?.data?.success) {
        setItemsWithCategory(items?.data?.data);
      }
    } catch (error) {
      console.log("Error while items api call");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (aid) {
      getVariation();
    }
  }, [aid]);

  useEffect(() => {
    getItemsWithCategory();
  }, [outletIdNew]);

  if (!variation) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  const handleCheckboxChange = (event, itemid) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemid]);
    } else {
      setSelectedItems(selectedItems.filter((id) => id !== itemid));
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <KeyboardBackspaceIcon
          sx={{ cursor: "pointer", marginTop: "0.3rem", fontSize: "2rem" }}
          onClick={() => navigate("/menu/variation")}
        />
        <PageHeader secondary title={"Edit Variation"} />
      </Box>

      <PaperBox
        shadowed
        padding={"0 24px"}
        sx={{
          flex: "1",
          overflow: "auto",
          height: "72vh",
          maxWidth: "1250px",
          margin: "auto",
        }}
      >
        <Box id="menu-media" sx={{ pt: "24px", pb: 3 }}>
          <PageHeader secondary title={"Variations"} />
          <Formik
            initialValues={{
              name: variation.name || "",
              price: variation.price || "",
              attributeId: variation.attributeId || "",
              status: variation.status || false,
              isDefault: variation.isDefault || false,
              outletId: outletIdNew || "",
              itemArray: variation.itemArray || [],
            }}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Variation name required";
              }
              if (!values.price) {
                errors.price = "Variation price required";
              }
              if (!values.attributeId) {
                errors.attributeId = "Attribute ID required";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // console.log("Form values:", {
              //   ...values,
              //   itemArray: selectedItems,
              // });
              presentLoader();
              try {
                const res = await axios.post(
                  `${updateVariation}${aid}`,
                  { ...values, itemArray: selectedItems }
                );
                // console.log("API response:", res);

                if (res?.data?.success) {
                  toast.success("Variation Updated Successfully");
                  navigate("/menu/variation");
                }
              } catch (error) {
                console.error("API error:", error);
              } finally {
                dismissLoader();
              }
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    marginTop: "16px",
                  }}
                >
                  <CustomTextFieldFormik
                    label="Variation Name"
                    placeholder="Enter Variation Name"
                    required
                    name="name"
                  />
                  <CustomTextFieldFormik
                    label="Variation Price"
                    placeholder="Enter Variation Price"
                    required
                    name="price"
                  />
                  <CustomRadiobox
                    name="attributeId"
                    label="Food Type"
                    options={[
                      { attributeId: 1, attribute: "veg" },
                      { attributeId: 2, attribute: "non-veg" },
                      { attributeId: 3, attribute: "egg" },
                    ].map((a) => ({
                      ...a,
                      value: a.attributeId,
                      label: a.attribute,
                    }))}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.status}
                        onChange={(event) =>
                          setFieldValue("status", event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label={values.status ? "Available" : "Unavailable"}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isDefault}
                        onChange={(event) =>
                          setFieldValue("isDefault", event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label={"Default Variation"}
                  />
                  {loading ? (
                    <Text style={{ textAlign: "center" }}>
                      Fetching items...
                    </Text>
                  ) : (
                    itemsWithCategory?.map((category, index) => (
                      <Accordion key={index} style={{ borderRadius: "1rem" }}>
                        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                          <Typography>{category.categoryname}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {category.items.map((item, idx) => (
                            <FormControlLabel
                              key={idx}
                              control={
                                <Checkbox
                                  checked={selectedItems.includes(item.itemid)}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item.itemid)
                                  }
                                />
                              }
                              label={item.itemname}
                            />
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))
                  )}
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      style={{ height: "35px", width: "5rem" }}
                    >
                      Update
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </PaperBox>
    </motion.div>
  );
};

export default EditAddonVariation;
