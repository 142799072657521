import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
};

export const loaderSlice = createSlice({
  name: "loaderSlice",
  initialState,
  reducers: {
    setLoadingSate: (state, action) => {
      let newState = {
        isLoading: action.payload,
      };
      return Object.assign({}, state, newState);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoadingSate } = loaderSlice.actions;

export default loaderSlice.reducer;
