import { useMediaQuery } from "@mui/material";

// Define your breakpoints
const breakpoints = {
  xs: 0, // extra small devices
  sm: 600, // small devices
  md: 960, // medium devices
  lg: 1280, // large devices
  xl: 1920, // extra large devices
};

// Custom media query hooks for different breakpoints
export const useSmallScreen = () =>
  useMediaQuery(`(max-width:${breakpoints.sm}px)`);
export const useMediumScreen = () =>
  useMediaQuery(
    `(min-width:${breakpoints.sm}px) and (max-width:${breakpoints.md}px)`
  );
export const useLargeScreen = () =>
  useMediaQuery(
    `(min-width:${breakpoints.md}px) and (max-width:${breakpoints.lg}px)`
  );
export const useXLargeScreen = () =>
  useMediaQuery(`(min-width:${breakpoints.lg}px)`);

// Helper function to generate a media query string
export const mediaQuery = (size, type = "min") => {
  return `(${type}-width: ${breakpoints[size]}px)`;
};

export const isMobile = window.innerWidth <= 600;
