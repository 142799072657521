import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import {
  Box,
  Stack,
  Button,
  IconButton,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { disableInstantTransitions, motion } from "framer-motion";
import PaperBox from "../../../components/layoutUI/PaperBox";
import { Formik, Form, Field } from "formik";
import CustomTextFieldFormik from "../../../components/formUI/CustomTextFieldFormik";
import { useNavigate, useParams } from "react-router-dom";
import CustomRadiobox from "../../../components/CustomRadiobox";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Text from "../../../components/typographyUI/Text";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { dismissLoader, presentLoader } from "../../../Redux Store/loaderService";
import { useGetApis } from "../../../hooks/useGetApis";

const EditAddonItem = () => {
  const {getAddonGroupItem,getAddonGroupForOutlet,updateAddonGroupItems} = useGetApis();
  const outletIdNew = useSelector((state) => state.outledId.outletId);

  const { addonidd } = useParams();

  const navigate = useNavigate();

  const [addonItem, setAddonItem] = useState();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [addonGroupList, setAddonGroupList] = useState([]);

  const handleRadioChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const getAddonGroup = async () => {
    try {
      const response = await axios.get(
        `${getAddonGroupItem}${addonidd}`
      );
      // console.log("Addon Api response ", response?.data);

      if (response?.data?.success) {
        const data = response?.data?.data;
        setAddonItem(data);
        setSelectedGroup(data?.addonGroupId);
      }
    } catch (error) {
      console.log("Error while fetching variation by id", error);
    }
  };

  const getAddonGroupList = async () => {
    setLoading(true);
    try {
      const items = await axios.get(
        `${getAddonGroupForOutlet}${outletIdNew}`
      );

      // console.log("get addons groups ", items?.data);
      if (items?.data?.success) {
        setAddonGroupList(items?.data?.data);
      }
    } catch (error) {
      console.log("Error while group api call");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAddonGroupList();
  }, [outletIdNew]);

  useEffect(() => {
    getAddonGroup();
  }, [addonidd]);

  if (!addonItem) {
    return <Text>Loading...</Text>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <KeyboardBackspaceIcon
          sx={{ cursor: "pointer", marginTop: "0.3rem", fontSize: "2rem" }}
          onClick={() => navigate("/menu/addon")}
        />
        <PageHeader secondary title={"Edit Addon Item"} />
      </Box>

      <PaperBox
        shadowed
        padding={"0 24px"}
        sx={{
          flex: "1",
          overflow: "auto",
          height: "72vh",
          maxWidth: "1250px",
          margin: "auto",
        }}
      >
        <Box id="menu-media" sx={{ pt: "24px", pb: 3 }}>
          <PageHeader secondary title={"Addons"} />
          <Formik
            initialValues={{
              name: addonItem?.name || "",
              price: addonItem?.price || "",
              rank: addonItem?.price || "",
              active: addonItem?.active || false,
              attributeId: addonItem?.attributeId || "",
              outletId: outletIdNew,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Group Item name required";
              }
              if (!values.rank) {
                errors.rank = "Rank required";
              }
              if (!values.price) {
                errors.price = "Price required";
              }
              if (!values.attributeId) {
                errors.attributeId = "Attribute is required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // console.log("group items ", {
              //   ...values,
              //   addonGroupId: selectedGroup,
              // });
              presentLoader();
              try {
                const res = await axios.post(
                  `${updateAddonGroupItems}${addonidd}`,
                  {
                    ...values,
                    addonGroupId: selectedGroup,
                  }
                );
                // console.log("API response:", res);
                if (res?.data?.success) {
                  toast.success("AddonGroup Item Updated Successfully");
                  navigate("/menu/addon");
                }
              } catch (error) {
                console.error("API error:", error);
              }finally{
                dismissLoader();
              }
            }}
          >
            {({ isSubmitting, setFieldValue, setValues, values }) => {
              return (
                <Form>
                  <Stack
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      marginTop: "16px",
                    }}
                  >
                    <CustomTextFieldFormik
                      label="Addon Group Item Name"
                      placeholder="Enter Addon Item Name"
                      required
                      name="name"
                    />
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <CustomTextFieldFormik
                        label="Price"
                        placeholder="Enter Price"
                        required
                        name="price"
                      />
                      <CustomTextFieldFormik
                        label="Rank"
                        placeholder="Enter Rank"
                        required
                        name="rank"
                        type="number"
                      />
                    </Box>

                    <Field name="attributeId">
                      {({ field, form: { setFieldValue, values } }) => (
                        <CustomRadiobox
                          {...field}
                          label="Food Type"
                          options={
                            [
                              { attributeId: 1, attribute: "veg" },
                              { attributeId: 2, attribute: "non-veg" },
                              { attributeId: 3, attribute: "egg" },
                            ]?.map((a) => ({
                              ...a,
                              value: a.attributeId,
                              label: a.attribute,
                            })) || []
                          }
                          value={field.value || ""}
                          onChange={(event) =>
                            setFieldValue("attributeId", event.target.value)
                          }
                        />
                      )}
                    </Field>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.active}
                          onChange={(event) =>
                            setFieldValue("active", event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label={values.active ? "Active" : "Deactive"}
                    />

                    <Divider />

                    <Text
                      bold
                      style={{ fontSize: "1.5rem", marginTop: "1rem" }}
                    >
                      Addon Groups
                    </Text>
                    {loading ? (
                      <text>fetching addon groups...</text>
                    ) : (
                      <Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            value={selectedGroup}
                            onChange={handleRadioChange}
                          >
                            {addonGroupList?.map((group) => (
                              <FormControlLabel
                                key={group.id}
                                value={group.id}
                                control={<Radio />}
                                label={<Typography>{group?.name}</Typography>}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    )}
                    <Box
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isSubmitting}
                        style={{
                          height: "35px",
                          width: "5rem",
                        }}
                      >
                        Update
                      </Button>
                    </Box>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </PaperBox>
    </motion.div>
  );
};

export default EditAddonItem;
