import { Box, Tab, Typography } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Staff from "../../components/pageUI/settings/Staff";
import General from "../../components/pageUI/settings/General";
import Password from "../../components/pageUI/settings/Password";
import Profile from "../../components/pageUI/settings/Profile";
import Email from "../../components/pageUI/settings/Email";
import Role from "../../components/pageUI/settings/Role";
import Integrations from "../../components/pageUI/settings/Integrations";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { useLocation, useNavigate } from "react-router-dom";
import Subscription from "../../components/pageUI/settings/Subscription";
import {useSmallScreen} from "../../utils/mediaQueries";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const isSmallScreen = useSmallScreen();
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: isSmallScreen ? 0 : 3,pb : 0,pt:isSmallScreen ? 3 : 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Settings = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const [tabs] = useState([
    "General",
    "Profile",
    "Role",
    "Staff",
    "Password",
    "Integrations",
    // "Subscription & Offers",
    // "Plans",
    // "Billing",
    // "Notifications Settings",
    // "Advanced",
  ]);

  
  const handleChange = (_, newValue) => {
    setValue(newValue);
    navigate('#'+tabs[newValue].toLocaleLowerCase().replaceAll(' ',''))
  };
  useEffect(()=>{
    
    const currentTab = tabs.findIndex(t => `#${t}`.toLowerCase().replaceAll(' ','') == location.hash)
    
    setValue(currentTab == -1 ? 0 : currentTab)
  },[])


  return (
    
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          scrollButtons="auto"
          allowScrollButtonsMobile
          variant="scrollable"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab} style={{fontSize: "1rem"}} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <General />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Profile />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Role />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Staff />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Password />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Integrations />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={6}>
        <Subscription />
      </CustomTabPanel> */}

      {/* <CustomTabPanel value={value} index={8}>
        <Email />
      </CustomTabPanel> */}
    </Box>

  );
};

export default Settings;
