import React from "react";
import Particles from "react-particles";
import { loadConfettiPreset } from "tsparticles-preset-confetti";

class ParticlesContainer extends React.PureComponent {
  // this customizes the component tsParticles installation
  async customInit(engine) {
    // this adds the preset to tsParticles, you can safely use the
    await loadConfettiPreset(engine);
  }

  render() {
    const options = {
      emitters: [
        {
          life: {
            duration: 5,
            count: 1,
          },
          position: {
            x: 60,
            y: 50,
          },
          particles: {
            move: {
              direction: "top",
            },
          },
        },
        {
          life: {
            duration: 5,
            count: 1,
          },
          //   position: {
          //     x: 100,
          //     y: 30,
          //   },
          particles: {
            move: {
              direction: "top",
            },
          },
        },
        {
          life: {
            duration: 5,
            count: 1,
          },
          //   position: {
          //     x: 100,
          //     y: 30,
          //   },
          particles: {
            move: {
              direction: "top",
            },
          },
        },
        {
          life: {
            duration: 5,
            count: 1,
          },
          //   position: {
          //     x: 100,
          //     y: 30,
          //   },
          particles: {
            move: {
              direction: "top",
            },
          },
        },
      ],
      preset: "confetti",
    };

    return <Particles options={options} init={this.customInit} />;
  }
}
export default ParticlesContainer;
