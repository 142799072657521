import React from "react";

const AccountSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="3.33333"
        cy="3.33333"
        rx="3.33333"
        ry="3.33333"
        transform="matrix(-1 0 0 1 13.3333 2.5)"
        stroke="#334155"
        strokeWidth="1.5"
      />
      <path
        d="M4.16663 14.1124C4.16663 13.3954 4.61734 12.7559 5.29254 12.5147V12.5147C8.33666 11.4275 11.6633 11.4275 14.7074 12.5147V12.5147C15.3826 12.7559 15.8333 13.3954 15.8333 14.1124V15.2086C15.8333 16.1982 14.9569 16.9583 13.9773 16.8184L13.6507 16.7717C11.2292 16.4258 8.77076 16.4258 6.34923 16.7717L6.02263 16.8184C5.04305 16.9583 4.16663 16.1982 4.16663 15.2086V14.1124Z"
        stroke="#334155"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default AccountSvg;
