import { Box, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import CustomButton from "../../../components/formUI/CustomButton";
import { Add } from "@mui/icons-material";
import StackRow from "../../../components/layoutUI/StackRow";
import CloudPng from "../../../assets/icons/cloud.png";
import ServerPaginationDataGrid from "../../../components/pageUI/ServerPaginationDataGrid";
import ItemCard from "../../../components/pageUI/ItemCard";
import Text from "../../../components/typographyUI/Text";
import CustomSwitch from "../../../components/formUI/CustomSwitch";
import CustomFormDialog from "../../../components/CustomFormDialog";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
// import { itemGetUrl, itemPutUrl } from "../../../utils/urls";
import { useMutation } from "react-query";
import axios from "axios";
import { useGetApis } from "../../../hooks/useGetApis";
import { useSelector } from "react-redux";
import {
  getPetpoojaMenu,
  pushDataToPetpooja,
} from "../../../Services/menuService";
import FetchMenuModal from "../../Modals/FetchMenuModal/FetchMenuModal";
import PetPoojaDialog from "../../../components/layoutUI/PetPoojaDialog";
import RestoListFilter from "../../../components/pageUI/filters/RestoListFilter";
import CustomMenu from "../../../components/layoutUI/CustomMenu";
import CustomSearchTextField from "../../../components/formUI/CustomSearchTextField";
import { styled } from "@mui/material/styles";
import FileUploadDialog from "../../../components/formUI/FileUploadDialog";
import NewFileUploadComponent from "../../../components/formUI/NewFileUploadComponent";
import Joyride from "react-joyride";
import CustomDialog from "../../../components/layoutUI/CustomDialog";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import CustomDataGrid from "../../../components/pageUI/CustomDataGrid";
import { useSmallScreen, useMediumScreen } from "../../../utils/mediaQueries";

const Items = () => {
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const { itemGetUrl, itemPutUrl, deleteItemUrl } = useGetApis();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [outletId, setOutletId] = useState(user?.outletId);
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const [search, setSearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [runTour, setRunTour] = useState(true);
  const [closeGuide, setCloseGuide] = useState(false);
  //

  useEffect(() => {
    const hasShownStep = localStorage.getItem("hasShownStep");
    if (user?.publishProcessingStep == 1 && !hasShownStep) {
      const driverObj = driver();
      if (closeGuide) {
        driverObj.destroy();
      }
      driverObj.highlight({
        element: ".menu",
        popover: {
          title: "Option to add items",
          description: "Click here to open options",
        },
      });
      localStorage.setItem("hasShownStep", "true");
    }
  }, [user?.publishProcessingStep, closeGuide]);

  const { mutate: mutatePut } = useMutation((data) => {
    //
    const url = itemPutUrl + data.itemid;
    return axios.post(url, data);
  });

  const [isFetchMenuModalOpen, setIsFetchMenuModalOpen] = useState(false);
  const [isPetPoojaModalOpen, setIsPetPoojaModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [dep, setDep] = useState(Math.random());

  // const [statusMap, setStatusMap] = useState({}); // Initialize the hashmap for storing statuses

  // const handleStatusChange = (itemid, newStatus) => {
  //   setStatusMap((prevMap) => ({
  //     ...prevMap,
  //     [itemid]: newStatus,
  //   }));
  // };

  // const [statusMap1, setStatusMap1] = useState({}); // Initialize the hashmap for storing statuses

  // const handleStatusChange1 = (itemid, newStatus) => {
  //   setStatusMap1((prevMap) => ({
  //     ...prevMap,
  //     [itemid]: newStatus,
  //   }));
  // };

  const [itemStates, setItemStates] = useState({});

  console.log(itemStates);

  const handleItemChange = (itemid, field, value) => {
    setItemStates((prevStates) => ({
      ...prevStates,
      [itemid]: {
        ...prevStates[itemid],
        [field]: value,
      },
    }));

    // Delay the API call to batch changes
    setTimeout(() => {
      console.log("called...");
      const currentItemState = itemStates[itemid] || {};
      mutatePut({
        ...currentItemState,
        itemid,
        [field]: value,
      });
    }, 0);
  };

  const columns = useMemo(() => [
    {
      field: "itemid",
      headerName: "Product ID",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.itemSequenceId}</Text>,
    },
    {
      field: "itemname",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "8px",
            objectFit: "cover",
          }}
          img={row.item_image_url}
          sx={{
            height: 36,
          }}
          title={row.itemname}
          starCount={4}
        />
      ),
    },
    {
      field: "cookingTime",
      headerName: "Cooking Time ",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }) => <Text>{row.minimumpreparationtime} min</Text>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const currentStatus = itemStates[row.itemid]?.status ?? row.status;
        return (
          <CustomSwitch
            value={currentStatus}
            label={(state) => (state ? "In Stock" : "Out of stock")}
            onChange={(e) => {
              handleItemChange(row.itemid, "status", e);
              // mutatePut({ ...row, status: e });
            }}
          />
        );
      },
    },
    {
      field: "recommendedItem",
      headerName: "Recommendation",
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell: ({ row }) => {
        const currentStatus =
          itemStates[row.itemid]?.isRecommendedItem ?? row.isRecommendedItem;

        return (
          <CustomSwitch
            value={currentStatus}
            label={(state) => (state ? "Recommended" : "Not recommended")}
            onChange={(e) => {
              handleItemChange(row.itemid, "isRecommendedItem", e);
              // mutatePut({ ...row, isRecommendedItem: e });
            }}
          />
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => <Text bold>₹ {row?.price}</Text>,
    },
  ]);

  async function syncMenuWithPetpooja() {
    try {
      let postData = {
        restaurantId: user?.restaurantId,
      };
      if (user?.outletId) {
        postData.outletId = user?.outletId;
      }
      const pushDataToPetpoojaResponse = await pushDataToPetpooja(postData);

      if (pushDataToPetpoojaResponse.data.success) {
        toast.success("Menu items are successfully listed.");
      }
    } catch (error) {
      console.error(error);
      if (typeof error?.response?.data?.error === "string") {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error("Something went wrong please try again", {
          position: "top-center",
        });
      }
    }
  }
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  async function fecthMenuFromPetpooja() {
    // try {
    //   let postData = {
    //     outletId: user?.outletId
    //   };
    //   // if (user?.outletId) {
    //   //   postData.outletId = user?.outletId;
    //   // }
    //   const fetchedResponse = await getPetpoojaMenu(postData);

    //
    //   if (fetchedResponse.data.success) {
    //     toast.success("Menu items are successfully listed.");
    //   }
    // }
    // catch (error) {
    //   console.error(error);
    //   if (typeof error?.response?.data?.error === "string") {
    //     toast.error(error?.response?.data?.error);
    //   } else {
    //     toast.error("Something went wrong please try again", {
    //       position: "top-center",
    //     });
    //   }
    // }

    if (
      !user?.petPoojaApAccessToken ||
      !user?.petPoojaAppKey ||
      !user?.petPoojaAppSecret ||
      !user?.petPoojaRestId
    ) {
      setIsPetPoojaModalOpen(true);
    } else {
      setIsFetchMenuModalOpen(true);
    }
  }
  const getFilterData = (data) => {
    let _data = data;
    if (search)
      _data = _data?.filter((row) =>
        row?.itemname.toLowerCase().includes(search.toLowerCase())
      );
    return _data;
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Box sx={{ width: "100%" }}>
          <PageHeader
            secondary
            title={`Items (${totalItems})`}
            subTitle={"View and update your store details"}
            rightUi={
              !isSmallScreen && (
                <StackRow gap={"10px"} center>
                  <CustomSearchTextField
                    placeholder="Search Item"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <CustomMenu
                    stepGuide
                    className="menu"
                    width={"220px"}
                    menuList={[
                      // { id: 1, label: "Fetch Menu From Petpooja", clas },
                      // // { id: 2, label: "Push Menu To Paetpooja" },
                      // { id: 3, label: "Add items" },
                      // { id: 4, label: "Upload Excel" },
                      {
                        id: 3,
                        label: "Add items",
                        className: "custom-menu-item-3",
                      },
                      {
                        id: 1,
                        label: "Fetch Menu From Petpooja",
                        className: "custom-menu-item-1",
                      },
                      // {
                      //   id: 2,
                      //   label: "Push Menu To Petpooja",
                      //   className: "custom-menu-item-2",
                      // },
                      {
                        id: 4,
                        label: "Upload Excel",
                        className: "custom-menu-item-4",
                      },
                    ]}
                    onClick={() => setCloseGuide(true)}
                    menuOnClick={({ label, id: _id }) => {
                      if (user?.publishProcessingStep == 1) {
                        localStorage.setItem("hasShownMenuStep", "true");
                      }
                      if (_id == 1) {
                        fecthMenuFromPetpooja();
                      } else if (_id == 2) {
                        syncMenuWithPetpooja();
                      } else if (_id == 3) {
                        navigate("add#menu-information");
                      } else {
                        setOpen(true);
                      }
                    }}
                  />
                  {/* <CustomButton onClick={() => fecthMenuFromPetpooja()}>
                  Fetch Menu From Petpooja
                </CustomButton>
                <CustomButton onClick={() => syncMenuWithPetpooja()}>
                  Push Menu To Petpooja
                </CustomButton>
                <CustomButton
                  startIcon={<Add />}
                  onClick={() => navigate("add#menu-information")}
                >
                  Add Items
                </CustomButton> */}
                </StackRow>
              )
            }
          />
          {isSmallScreen && (
            <Box sx={{ flexGrow: 1, mb: 2 }}>
              <Grid container spacing={1} alignItems="center">
                {/* Search Bar - 90% width */}
                <Grid item xs={10.8}>
                  <CustomSearchTextField
                    placeholder="Search Item"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    fullWidth
                  />
                </Grid>

                {/* Custom Menu - 10% width */}
                <Grid item xs={1.2}>
                  <CustomMenu
                    stepGuide
                    className="menu"
                    width="100%"
                    menuList={[
                      {
                        id: 3,
                        label: "Add items",
                        className: "custom-menu-item-3",
                      },
                      {
                        id: 1,
                        label: "Fetch Menu From Petpooja",
                        className: "custom-menu-item-1",
                      },
                      {
                        id: 4,
                        label: "Upload Excel",
                        className: "custom-menu-item-4",
                      },
                    ]}
                    onClick={() => setCloseGuide(true)}
                    menuOnClick={({ label, id: _id }) => {
                      if (user?.publishProcessingStep == 1) {
                        localStorage.setItem("hasShownMenuStep", "true");
                      }
                      if (_id == 1) {
                        fecthMenuFromPetpooja();
                      } else if (_id == 2) {
                        syncMenuWithPetpooja();
                      } else if (_id == 3) {
                        navigate("add#menu-information");
                      } else {
                        setOpen(true);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {outletIdNew && (
            <CustomDataGrid
              depStr={dep}
              rowId="itemid"
              name="item-table"
              url={itemGetUrl + `${outletIdNew}`}
              labelName="Item"
              height={"700px"}
              columns={columns}
              filterFn={getFilterData}
              deleteOption
              setTotalItems={setTotalItems}
              deleteUrl={deleteItemUrl}
              deleteItem
            />
          )}
          {/* <Joyride
            steps={steps}
            // debug={true}
            run={runTour}
            continuous={true}
            showSkipButton={true}
            styles={{
              
              overlay: {
                width: "50vw", // Set a fixed width for the overlay
              },
            }}
            callback={handleJoyrideCallback}
          /> */}
        </Box>
      </motion.div>
      {/* <Tour
        steps={steps}
        isOpen={runTour}
        // position="top-right"
        showNavigation={false}
        onRequestClose={() => setRunTour(false)} 
        /> */}

      {isFetchMenuModalOpen && (
        <FetchMenuModal
          setDep={setDep}
          open={isFetchMenuModalOpen}
          handleClose={() => {
            setIsFetchMenuModalOpen(false);
          }}
        />
      )}

      {isPetPoojaModalOpen && (
        <PetPoojaDialog
          open={isPetPoojaModalOpen}
          // buttonComp={(props) => <CustomButton {...props}>Add Role</CustomButton>}
          width={"650px"}
          title="Petpooja"
          subTitle={"Connect Petpooja with Mealpe for better performance."}
          setIsConnected={() => {}}
          closeHandler={(flag) => {
            setIsPetPoojaModalOpen(false);
            if (flag) {
              setIsFetchMenuModalOpen(true);
            }
          }}
        />
      )}
      {open && (
        <CustomFormDialog
          postUrl="pass here url"
          onSubmit={(file) => {
            toast.success("New Items Added");
          }}
          width={"600px"}
          open={open}
          setOpen={(flag) => {
            setOpen(flag);
            setDep(Date.now());
          }}
        ></CustomFormDialog>
      )}
    </>
  );
};

export default Items;
