import { Box, Dialog, Grid, InputAdornment, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PaperBox from "./PaperBox";
import PageHeader from "../pageUI/PageHeader";
import StackRow from "./StackRow";
import { Close } from "@mui/icons-material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CustomTextField from "../formUI/CustomTextField";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

const PetPoojaConfigDialog = ({
  open,
  closeHandler,
  width,
  title,
  subTitle,
}) => {
  const copyToClipBoard = (value) => {
    copy(value);
    toast.success("Copied to clipboard!");
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => {
          closeHandler();
        }}
        sx={{
          "& .MuiDialog-paper": {
            background: "transparent",
            width: width || "auto",
          },
        }}
      >
        <Box>
          <PaperBox padding={"24px"}>
            <PageHeader
              secondary={true}
              title={title}
              subTitle={subTitle}
              rightUi={
                <StackRow center>
                  <div onClick={() => closeHandler()}>
                    <Close
                      sx={{
                        color: "#64748B",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </StackRow>
              }
            />
            <Grid container spacing={"20px"}>
              <Grid item xs={12}>
                <CustomTextField
                  label={"Base URL"}
                  value={`${process.env.REACT_APP_BACKEND_BASE_URL}`}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.2)",
                            color: "black",
                          },
                        }}
                        position="end"
                      >
                        <ContentCopyOutlinedIcon
                          onClick={() =>
                            copyToClipBoard(
                              `${process.env.REACT_APP_BACKEND_BASE_URL}`
                            )
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label={"Menu Sharing Endpoint"}
                  value={"petpooja/webhook/menu-sharing-webhook"}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.2)",
                            color: "black",
                          },
                        }}
                        position="end"
                      >
                        <ContentCopyOutlinedIcon
                          onClick={() =>
                            copyToClipBoard(
                              "petpooja/webhook/menu-sharing-webhook"
                            )
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label={"Get Store Status API Endpoint"}
                  value={"petpooja/webhook/get-store-status-webhook"}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.2)",
                            color: "black",
                          },
                        }}
                        position="end"
                      >
                        <ContentCopyOutlinedIcon
                          onClick={() =>
                            copyToClipBoard(
                              "petpooja/webhook/get-store-status-webhook"
                            )
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label={"Update Store Status API Endpoint"}
                  value={"petpooja/webhook/update-store-status-webhook"}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.2)",
                            color: "black",
                          },
                        }}
                        position="end"
                      >
                        <ContentCopyOutlinedIcon
                          onClick={() =>
                            copyToClipBoard(
                              "petpooja/webhook/update-store-status-webhook"
                            )
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  label={"Item Off API Endpoint"}
                  value={"petpooja/webhook/item-off-webhook"}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.2)",
                            color: "black",
                          },
                        }}
                        position="end"
                      >
                        <ContentCopyOutlinedIcon
                          onClick={() =>
                            copyToClipBoard("petpooja/webhook/item-off-webhook")
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label={"Item On API Endpoint"}
                  value={"petpooja/webhook/item-on-webhook"}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.2)",
                            color: "black",
                          },
                        }}
                        position="end"
                      >
                        <ContentCopyOutlinedIcon
                          onClick={() =>
                            copyToClipBoard("petpooja/webhook/item-on-webhook")
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </PaperBox>
        </Box>
      </Dialog>
    </Box>
  );
};
export default PetPoojaConfigDialog;
