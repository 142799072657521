import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  Divider,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio,
} from "@mui/material";
import { motion } from "framer-motion";
import Text from "../../../components/typographyUI/Text";
import PageHeader from "../../../components/pageUI/PageHeader";
import CustomDataGrid from "../../../components/pageUI/CustomDataGrid";
import { useGetApis } from "../../../hooks/useGetApis";
import { useSelector } from "react-redux";
import CustomDialog from "../../../components/layoutUI/CustomDialog";
import { Add, Close } from "@mui/icons-material";
import CustomTextFieldFormik from "../../../components/formUI/CustomTextFieldFormik";
import { Field, Form, Formik } from "formik";
import CustomRadiobox from "../../../components/CustomRadiobox";
import axios from "axios";
import { toast } from "react-toastify";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/formUI/CustomButton";
import CustomSearchTextField from "../../../components/formUI/CustomSearchTextField";
import { useSmallScreen, useMediumScreen } from "../../../utils/mediaQueries";

const Addons = () => {
  const {
    getAddonGroupForOutlet,
    getCategoriesWithItems,
    deleteAddonGroup,
    getAddonGroupItemsForOutlet,
    deleteAddonGroupItem,
    createAddonGroupItem,
    addAddonGroup,
  } = useGetApis();
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const outletIdNew = useSelector((state) => state.outledId.outletId);

  const navigate = useNavigate();

  const [openGroupItem, setOpenGroupItem] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [itemsWithCategory, setItemsWithCategory] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [addonGroupList, setAddonGroupList] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    openGroup && getItemsWithCategory();
  }, [openGroup]);

  useEffect(() => {
    openGroupItem && getAddonGroupList();
  }, [openGroupItem]);

  const getAddonGroupList = async () => {
    setLoading(true);
    try {
      const items = await axios.get(`${getAddonGroupForOutlet}${outletIdNew}`);

      // console.log("get addons groups ", items?.data?.data);
      if (items?.data?.success) {
        setAddonGroupList(items?.data?.data);
      }
    } catch (error) {
      console.log("Error while group api call");
    } finally {
      setLoading(false);
    }
  };

  const getItemsWithCategory = async () => {
    setLoading(true);
    try {
      const items = await axios.get(`${getCategoriesWithItems}${outletIdNew}`);

      // console.log("Get Category with items ", items?.data?.data);
      if (items?.data?.success) {
        setItemsWithCategory(items?.data?.data);
      }
    } catch (error) {
      console.log("Error while items api call");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event, itemid) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemid]);
    } else {
      setSelectedItems(selectedItems.filter((id) => id !== itemid));
    }
  };

  const handleCheckboxChangeforgroup = (event, variationId) => {
    if (event.target.checked) {
      setSelectedVariations([...selectedVariations, variationId]);
    } else {
      setSelectedVariations(
        selectedVariations.filter((id) => id !== variationId)
      );
    }
  };

  const handleRadioChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const columns = useMemo(
    () => [
      {
        field: "addongroupname",
        headerName: "Addon Group Name",
        flex: 1,
        minWidth: 250,
        sortable: false,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem" }} bold>
            {row?.name}
          </Text>
        ),
      },
      {
        field: "Active",
        headerName: "Active",
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold> {row?.active ? "Active" : "Deactive"}</Text>
        ),
      },
      {
        field: "rank",
        headerName: "Rank",
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.rank}</Text>,
      },
    ],
    []
  );

  const columns1 = useMemo(
    () => [
      {
        field: "addongroupitem",
        headerName: "Item Name",
        flex: 1,
        minWidth: 250,
        sortable: false,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem" }} bold>
            {row?.name}
          </Text>
        ),
      },
      {
        field: "price",
        headerName: "Item Price",
        flex: 1,
        minWidth: 50,
        sortable: false,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem" }} bold>
            ₹ {row?.price}
          </Text>
        ),
      },
      {
        field: "attributeid",
        headerName: "Attribute",
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem" }} bold>
            {row?.attributeId == "1"
              ? "veg"
              : row?.attributeId == "2"
              ? "non-veg"
              : "egg"}
          </Text>
        ),
      },
      {
        field: "Active",
        headerName: "Active",
        flex: 1,
        minWidth: 50,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold> {row?.active ? "Active" : "Deactive"}</Text>
        ),
      },
      {
        field: "rank",
        headerName: "Rank",
        flex: 1,
        minWidth: 30,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.rank}</Text>,
      },
      {
        field: "assignedgroup",
        headerName: "Group",
        flex: 1,
        minWidth: 220,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.AddonGroup?.name}</Text>,
      },
    ],
    []
  );

  const getFilterData = (data) => {
    let _data = data;
    if (search)
      _data = _data?.filter((row) =>
        row?.name.toLowerCase()?.includes(search?.toLowerCase())
      );
    return _data;
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Box sx={{ width: "100%" }}>
          <PageHeader
            secondary
            title={`Addon Groups`}
            subTitle={"View and update your store details"}
          />

          <Box
            style={{
              display: "flex",
              justifyContent: `${isSmallScreen ? "flex-start" : "flex-end"}`,
              marginBottom: "1rem",
              flexWrap: "wrap",
            }}
          >
            <CustomSearchTextField
              placeholder="Search Addon Group"
              value={search}
              style={{ marginRight: "1rem" }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <CustomButton
              startIcon={<Add />}
              onClick={() => setOpenGroup(true)}
              style={{ marginTop: `${isSmallScreen ? "20px" : "0px"}`,width: isSmallScreen ? "50%" : "auto" }}
            >
              Add AddonGroup
            </CustomButton>
          </Box>

          <CustomDataGrid
            rowId="id"
            name="addongroup-table"
            labelName="AddonGroup"
            height={"700px"}
            columns={columns}
            url={`${getAddonGroupForOutlet}${outletIdNew}`}
            noEditAction
            menuOnClick={({ id, label }, row) => {
              if (label === "Edit") {
                navigate("/menu/addon/" + row?.id);
              }
            }}
            deleteOption
            deleteUrl={`${deleteAddonGroup}`}
            deleteItem
            filterFn={getFilterData}
          />

          <PageHeader
            secondary
            style={{ marginTop: "3rem" }}
            title={`Addon Items`}
            subTitle={"View and update your store details"}
          />

          <Box
            style={{
              display: "flex",
              justifyContent: `${isSmallScreen ? "flex-start" : "flex-end"}`,
              marginBottom: "1rem",
              flexWrap: "wrap",
            }}
          >
            <CustomSearchTextField
              placeholder="Search Addon Item"
              value={search}
              style={{ marginRight: "1rem" }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <CustomButton
              startIcon={<Add />}
              onClick={() => setOpenGroupItem(true)}
              style={{ marginTop: `${isSmallScreen ? "20px" : "0px"}`,width: isSmallScreen ? "50%" : "auto" }}
            >
              Add Addon Item
            </CustomButton>
          </Box>

          <CustomDataGrid
            rowId="id"
            name="addongroupitem-table"
            labelName="AddonGroupitem"
            height={"700px"}
            columns={columns1}
            url={`${getAddonGroupItemsForOutlet}` + `${outletIdNew}`}
            noEditAction
            menuOnClick={({ id, label }, row) => {
              if (label === "Edit") {
                navigate("/menu/addonItem/" + row?.id);
              }
            }}
            deleteOption
            deleteUrl={`${deleteAddonGroupItem}`}
            deleteItem
            filterFn={getFilterData}
          />
        </Box>

        {/* addon Items start  */}
        {openGroupItem && (
          <CustomDialog dontClose open={true} sx={{ width: "80rem" }}>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <PageHeader secondary title={`Addon Group Item`} />
              <Close
                style={{ cursor: "pointer", fontSize: "2rem" }}
                onClick={() => setOpenGroupItem(false)}
              />
            </Box>

            <Formik
              initialValues={{
                name: "",
                price: "",
                rank: "",
                active: true,
                attributeId: "",
                outletId: outletIdNew,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "Group Item name required";
                }
                if (!values.rank) {
                  errors.rank = "Rank required";
                }
                if (!values.price) {
                  errors.price = "Price required";
                }
                if (!values.attributeId) {
                  errors.attributeId = "Attribute is required";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                // console.log("group items ", {
                //   ...values,
                //   addonGroupId: selectedGroup,
                // });

                try {
                  const res = await axios.post(`${createAddonGroupItem}`, {
                    ...values,
                    addonGroupId: selectedGroup,
                  });
                  // console.log("API response:", res);
                  setOpenGroupItem(false);
                  if (res?.data?.success) {
                    toast.success("AddonGroup Item Added Successfully");
                    window.location.reload(); // Refresh the page
                  }
                } catch (error) {
                  console.error("API error:", error);
                }
              }}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <Stack
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      marginTop: "16px",
                    }}
                  >
                    <CustomTextFieldFormik
                      label="Addon Item Name"
                      placeholder="Enter Item Name"
                      required
                      name="name"
                    />
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        flexWrap: "wrap",
                      }}
                    >
                      <CustomTextFieldFormik
                        label="Price"
                        placeholder="Enter Price"
                        required
                        name="price"
                      />
                      <CustomTextFieldFormik
                        label="Rank"
                        placeholder="Enter Rank"
                        required
                        name="rank"
                        type="number"
                      />
                    </Box>

                    <Field name="attributeId">
                      {({ field, form: { setFieldValue, values } }) => (
                        <CustomRadiobox
                          {...field}
                          label="Food Type"
                          options={
                            [
                              { attributeId: 1, attribute: "veg" },
                              { attributeId: 2, attribute: "non-veg" },
                              { attributeId: 3, attribute: "egg" },
                            ]?.map((a) => ({
                              ...a,
                              value: a.attributeId,
                              label: a.attribute,
                            })) || []
                          }
                          value={field.value || ""}
                          onChange={(event) =>
                            setFieldValue("attributeId", event.target.value)
                          }
                         
                        />
                      )}
                    </Field>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.active}
                          onChange={(event) =>
                            setFieldValue("active", event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label={values.active ? "Active" : "Deactive"}
                    />

                    <Divider />

                    <Text
                      bold
                      style={{ fontSize: "1.5rem", marginTop: "1rem" }}
                    >
                      Addon Groups
                    </Text>
                    {loading ? (
                      <text>fetching addon groups...</text>
                    ) : (
                      <Box>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Select a Group
                          </FormLabel>
                          <RadioGroup
                            value={selectedGroup}
                            onChange={handleRadioChange}
                          >
                            {addonGroupList?.map((group) => (
                              <FormControlLabel
                                key={group.id}
                                value={group.id}
                                control={<Radio />}
                                label={<Typography>{group?.name}</Typography>}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    )}
                    <Box
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isSubmitting}
                        style={{
                          height: "35px",
                          width: "5rem",
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Stack>
                </Form>
              )}
            </Formik>
          </CustomDialog>
        )}
        {/* addon items end */}

        {openGroup && (
          <CustomDialog dontClose open={true} sx={{ width: "80rem" }}>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <PageHeader secondary title={`Addon Group`} />
              <Close
                style={{ cursor: "pointer", fontSize: "2rem" }}
                onClick={() => setOpenGroup(false)}
              />
            </Box>

            <Formik
              initialValues={{
                name: "",
                rank: "",
                active: true,
                max_selection: "",
                min_selection: "",
                outletId: outletIdNew,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "Group name required";
                }
                if (!values.rank) {
                  errors.rank = "Rank required";
                }
                if (values.max_selection === "" && values.max_selection !== 0) {
                  errors.max_selection = "Max selection required";
                }
                if (values.min_selection === "" && values.min_selection !== 0) {
                  errors.min_selection = "Min selection required";
                }
                if (values.max_selection < values.min_selection) {
                  errors.max_selection =
                    "Max value must be greter then min value";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                // console.log("res", {
                //   ...values,
                //   selectedItems,
                //   selectedVariations,
                // });

                try {
                  const res = await axios.post(`${addAddonGroup}`, {
                    ...values,
                    selectedItems,
                    selectedVariations,
                  });
                  // console.log("API response:", res);
                  setOpenGroup(false);
                  if (res?.data?.success) {
                    toast.success("AddonGroup Added Successfully");
                    window.location.reload(); // Refresh the page
                  }
                } catch (error) {
                  console.error("API error:", error);
                }
              }}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <Stack
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      marginTop: "16px",
                    }}
                  >
                    <CustomTextFieldFormik
                      label="Addon Group Name"
                      placeholder="Enter Group Name"
                      required
                      name="name"
                    />
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        flexWrap: "wrap",
                      }}
                    >
                      <CustomTextFieldFormik
                        label="Rank"
                        placeholder="Enter Rank"
                        required
                        name="rank"
                        type="number"
                      />

                      <CustomTextFieldFormik
                        label="Max Selection"
                        placeholder="Enter Number"
                        required
                        name="max_selection"
                        type="number"
                      />
                      <CustomTextFieldFormik
                        label="Min Selection"
                        placeholder="Enter Number"
                        required
                        name="min_selection"
                        type="number"
                      />
                    </Box>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.active}
                          onChange={(event) =>
                            setFieldValue("active", event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label={values.active ? "Active" : "Deactive"}
                    />

                    <Divider />

                    <Text
                      bold
                      style={{ fontSize: "1.5rem", marginTop: "1rem" }}
                    >
                      Items
                    </Text>
                    {loading ? (
                      <Text style={{ textAlign: "center" }}>
                        Fetching items...
                      </Text>
                    ) : (
                      itemsWithCategory?.map((category, index) => (
                        <Accordion key={index} style={{ borderRadius: "1rem" }}>
                          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                            <Typography>{category.categoryname}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {category.items.map((item, idx) => (
                              <>
                                <FormControlLabel
                                  key={idx}
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCheckboxChange(e, item.itemid)
                                      }
                                    />
                                  }
                                  label={item.itemname}
                                />
                                <div style={{ marginLeft: "20px" }}>
                                  {item.variations &&
                                    item.variations.map((variation, varIdx) => (
                                      <FormControlLabel
                                        key={`${idx}-${varIdx}`}
                                        control={
                                          <Checkbox
                                            onChange={(e) =>
                                              handleCheckboxChangeforgroup(
                                                e,
                                                variation.variationId
                                              )
                                            }
                                          />
                                        }
                                        label={variation.name}
                                      />
                                    ))}
                                </div>
                              </>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ))
                    )}
                    <Box
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        color="secondary"
                        style={{
                          height: "35px",
                          width: "5rem",
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Stack>
                </Form>
              )}
            </Formik>
          </CustomDialog>
        )}
      </motion.div>
    </>
  );
};

export default Addons;
