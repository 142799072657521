import { Box, Chip, Tooltip } from "@mui/material";
import PageHeader from "../PageHeader";
import CustomButton from "../../formUI/CustomButton";
import RoleDialog from "../../layoutUI/RoleDialog";
import CustomDataGrid from "../CustomDataGrid";
import Text from "../../typographyUI/Text";
import CustomChip from "../CustomChip";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Fragment } from "react";
import { useSmallScreen } from "../../../utils/mediaQueries";

const Role = () => {
  const isSmallScreen = useSmallScreen();
  const [chipColor] = useState({
    dashboard: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    orders: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    menu: {
      backgroundColor: "#F1F5F9",
      textColor: "#334155",
    },
    cutomers: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    outlets: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });
  const [dep, setDep] = useState(Math.random());

  const restaurantData = useSelector((state) => state.auth.user);
  const columns = [
    {
      field: "id",
      headerName: "Serial No",
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => <Text>{row.id + 1}</Text>,
    },
    {
      field: "Role",
      headerName: "Role Name",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text>{row?.role}</Text>,
    },
    {
      field: "Access",
      headerName: "Access",
      flex: 2,
      minWidth: isSmallScreen ? 320 : 180,
      sortable: false,
      renderCell: ({ row }) => {
        //
        return (
          <>
            {row.access?.slice(0, 3).map((m) => (
              <Fragment key={`${m}`}>
                <CustomChip
                  label={m}
                  textColor={chipColor[m.toLowerCase()]?.textColor}
                  backgroundColor={chipColor[m.toLowerCase()]?.backgroundColor}
                />
              </Fragment>
            ))}

            {row.access?.length > 3 && (
              <Tooltip
                title={row.access.slice(3).map((e) => (
                  <div key={e}>{e}</div>
                ))}
                // placement="right-start"
              >
                <Chip
                  key={row?.access}
                  label={row.access?.length - 3 + "+"}
                  sx={{ height: "28px", py: "8px" }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "Edit",
      headerName: "",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <RoleDialog
          onSuccess={() => setDep(Math.random())}
          width={"650px"}
          title="Update Role"
          subTitle={"Update Role for Restaurant Management"}
          defaultData={{
            role: row.role,
            access: row.access,
            roleId: row.roleId,
          }}
          buttonComp={(props) => (
            <Text
              color="green"
              bold
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              {...props}
            >
              Edit
            </Text>
          )}
        ></RoleDialog>
      ),
    },
  ];

  return (
    <Box>
      <CustomDataGrid
        dep={dep}
        name="role-table"
        rowId={"roleId"}
        mappingData={data => data?.map((row, index) => ({...row, id: index }))}
        // height={"700px"}
        columns={columns}
        url={
          process.env.REACT_APP_BACKEND_BASE_URL +
          `staff/role/getRoleByOutletId/` +
          `${
            restaurantData?.outletId
              ? restaurantData?.outletId
              : restaurantData?.restaurantId
          }`
        }
        noActions
        header={({ refetch }) => {
          return (
            <PageHeader
              secondary
              title="Role"
              subTitle={"Update Role details."}
              rightUi={
                <RoleDialog
                  refetch={refetch}
                  buttonComp={(props) => (
                    <CustomButton {...props}>Add Role</CustomButton>
                  )}
                  width={"650px"}
                  title="Add Role"
                  subTitle={"Add Role for Restaurant Management"}
                />
              }
            />
          );
        }}
        noDataScreen
      ></CustomDataGrid>
    </Box>
  );
};

export default Role;
