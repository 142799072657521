import React from "react";
import StackRow from "../layoutUI/StackRow";
import ItemPng from "../../assets/images/mealpe.png";
import { Stack, Tooltip } from "@mui/material";
import Text from "../typographyUI/Text";
import SmallText from "../typographyUI/SmallText";
import SmallChip from "../layoutUI/SmallChip";
import Title from "../typographyUI/Title";
import StarCount from "../formUI/StarCount";
import { LightTooltip } from "./dashboard/DashboardCard";
const ItemCard = ({
  img = "",
  title ="",
  subTitle ="",
  sx = {},
  outletCount,
  fontSizeSet = 0,
  starCount = 0,
  imgSx = {},
  labelSx = {},
  titleSx = {},
  tooltip = null,
  ...otherProps
}) => {
  const fontSizesArray = [
    {
      title: "1rem",
      subTitle: "0.714rem",
    },
    {
      title: "1.286rem",
      subTitle: "1rem",
    },
  ];
  
  return (
    <StackRow gap={"0.857rem"} sx={sx} {...otherProps}>
      <img
        style={{
          borderRadius: '0.429rem',
          background: "smokewhite",
          maxWidth: "4.286rem",
          height: "100%",
          aspectRatio: "1",
          objectFit: "cover",
          ...imgSx,
        }}
        src={img || 'https://placehold.co/60x60?text=Logo'}
      />
      <Stack>
        <Title sx={titleSx} fontSize={fontSizesArray[fontSizeSet].title}>
          <LightTooltip
            placement="left"
            title={tooltip}
            sx={{
              height: "auto",
            }}
          >
            <span>{title}</span>
          </LightTooltip>
        </Title>
  
        {subTitle && (
          <SmallText
            sx={labelSx}
            mb={"0.429rem"}
            fontSize={fontSizesArray[fontSizeSet].subTitle}
          >
            {subTitle}
          </SmallText>
        )}
        {outletCount && <SmallChip label={"Outlet: " + outletCount} />}
        {starCount !== 0 && <StarCount starCount={starCount} />}
      </Stack>
    </StackRow>
  );
        }  
export default ItemCard;
