import { Box, Grid, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import PageHeader from "../PageHeader";
import CustomButton from "../../formUI/CustomButton";
import CustomTextFieldFormik from "../../formUI/CustomTextFieldFormik";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { updatePassword } from "../../../Services/staff";
import { successToast } from "../../../Redux Store/toast";
import {
  dismissLoader,
  presentLoader,
} from "../../../Redux Store/loaderService";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Password = () => {
  const resData = useSelector((state) => state.auth);

  // const resId = resData?.user?.restaurantId;

  const [initialState] = useState({
    password: "",
    cpassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [formData, setFormData] = useState(initialState);

  const handleSubmit = (values) => {
    const updatedData = {
      password: values.cpassword,
      adminId: resData?.user?.restaurantId || resData?.user?.outletId,
    };
    presentLoader();
    updatePassword(updatedData)
      .then((res) => {
        if (res.data.success) {
          setFormData(initialState);
          dismissLoader();
          successToast("Password Updated Successfully");
        }
      })
      .catch((err) => {});
  };

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={formData}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required("Current Password is required")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character"
          ),
        cpassword: Yup.string()
          .required("Confirm Password is required")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
      })}
    >
      <Form>
        <Box>
          <PageHeader
            secondary
            title="Password"
            subTitle={"View and update your Password"}
            rightUi={<CustomButton type="submit">Save Changes</CustomButton>}
          />
          <Grid container spacing={"20px"}>
            <Grid item xs={6}>
              <PageHeader
                secondary
                title="Password"
                subTitle={
                  "Confirm your current password before setting a new one. 8 characters minimum."
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={"20px"}>
                <Grid item xs={12}>
                  <CustomTextFieldFormik
                    label={"Password"}
                    placeholder="Enter Password"
                    required
                    onPaste={(e) => e.preventDefault()}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {showPassword ? (
                            <VisibilityIcon onClick={handleClick} />
                          ) : (
                            <VisibilityOffIcon onClick={handleClick} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextFieldFormik
                    label={"Confirm Password"}
                    placeholder="Enter Confirm Password"
                    required
                    name="cpassword"
                    onPaste={(e) => e.preventDefault()}
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityIcon
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            />
                          ) : (
                            <VisibilityOffIcon
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Form>
    </Formik>
  );
};

export default Password;
