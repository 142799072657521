import React, { useEffect, useState } from "react";
import PaperBox from "../layoutUI/PaperBox";
import StackRow from "../layoutUI/StackRow";
import Logo from "../../../src/assets/images/noInternetLogo.png";
import NoInternetPng from "../../../src/assets/images/no-internet.png";
import CustomDialog from "../layoutUI/CustomDialog";
import { Stack } from "@mui/material";
import Headline2 from "../typographyUI/Headline2";
import Headline3 from "../typographyUI/Headline3";
import Title from "../typographyUI/Title";

function InternetStatusChecker() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div>
      {!isOnline ? (
        <CustomDialog dontClose open={true} sx={{ width: "38.21429rem" }}>
          <Stack alignItems={'center'} >
            <img src={Logo} style={{ width: " 3.5rem", height: "3.25rem", alignSelf : 'flex-start', objectFit : 'contain' }} />
            <img src={NoInternetPng} style={{ width: "23.85714rem" }} />
            <Title sx={{ textAlign: "center", fontSize : '1.3rem' }}>
              Oops Can't Move Forward
            </Title>
            <Title sx={{ textAlign: "center" }}>
              It seems your Internet is slow or not working{" "}
            </Title>
          </Stack>
        </CustomDialog>
      ) : <></>}
    </div>
  );
}

export default InternetStatusChecker;
