import { Box, Button, Chip, Divider, Grid, IconButton } from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import PageHeader from "../PageHeader";
import StackRow from "../../layoutUI/StackRow";
import CustomSearchTextField from "../../formUI/CustomSearchTextField";
import DateFilter from "../filters/DateFilter";
import CustomFilter from "../filters/CustomFilter";
import StatusFilter from "../filters/StatusFilter";
import CustomDataGrid from "../CustomDataGrid";
import ItemCard from "../ItemCard";
import CustomSwitch from "../../formUI/CustomSwitch";
import Text from "../../typographyUI/Text";
import CustomChip from "../CustomChip";
import { useDispatch, useSelector } from "react-redux";
import { useGetApis } from "../../../hooks/useGetApis";
import { Stack } from "@mui/system";
import moment from "moment";
import ButtonPng from "../../../assets/icons/Button.png";
import CloseButtonPng from "../../../assets/icons/CloseButton.png";
import { acceptOrder, rejectOrder } from "../../../Services/orderService";
import { OrderStatus } from "./OrderStatus";
import { useNavigate } from "react-router-dom";
import CustomLink from "../../formUI/CustomLink";
import ServerPaginationDataGrid from "../ServerPaginationDataGrid";
import RestoListFilter from "../filters/RestoListFilter";
import touchMp3 from "../../../assets/images/touch.mp3";
import { removeRealTimeOrder } from "../../../Redux Store/Slices/realtimeOrder";
import CustomButton from "../../formUI/CustomButton";
import CustomDialog from "../../layoutUI/CustomDialog";
import { ArrowBack, Close } from "@mui/icons-material";
import axios from "axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { outletUrl } from "../../../utils/urls";
import {
  useSmallScreen,
  isMobile,
  useMediumScreen,
} from "../../../utils/mediaQueries";
import { toast } from "react-toastify";

const PendingOrders = ({ getAllOrderDetails }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { orderUpcomingGetUrl, getPendingOrders, itemGetUrl } = useGetApis();
  const navigate = useNavigate();
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();

  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState([]);
  const [newRowArray, setNewRowArray] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [outletId, setOutletId] = useState(user?.outletId);
  const [sortType, setSortType] = useState("");
  const [orderOpen, setOrderOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [loading1, setLoading1] = useState(false);

  const audioRef = useRef(new Audio(touchMp3));
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const dummyRows = useSelector((state) => state.pendingOrders.pendingOrders);
  const pendingTOCancelOrders = useSelector(
    (state) => state.pendingOrders.pendingTOCancelOrders
  );

  const [orderType, setOrderType] = useState("Dine In");

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  // console.log("pending ", dummyRows);

  // useEffect(() => {
  //   const eventSource = new EventSource(
  //     "https://mealpe-testing-api.onrender.com/order/order/realtimePendingOrder/" + outletId
  //   );
  //   eventSource.onopen = (e) => {
  //
  //   };
  //   eventSource.onmessage = (e) => {
  //
  //   };
  //   eventSource.addEventListener("neworder", (e) => {
  //
  //     audioRef.current.play();
  //
  //     setNewRowArray([JSON.parse(e?.data), ...newRowArray]);
  //   });

  //   return () => {
  //     eventSource.close();
  //   };
  // }, [newRowArray]);

  const [chipColor] = useState({
    accepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    pickUp: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    delivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    dispatch: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        setOrderData((prevData) => [...prevData]);
      }
    }, 30000); // 30 sec

    return () => clearInterval(intervalId);
  }, []);

  const orderDetails = (_orderId) => {
    navigate(`/orders/${_orderId}`);
  };
  const handleAccept = (_orderId) => {
    return acceptOrder(_orderId)
      .then((res) => {
        setOrderId((p) => [...p, _orderId]);
        getAllOrderDetails();
        dispatch(removeRealTimeOrder(_orderId));
      })
      .catch((error) => {});
  };
  const handleReject = (_orderId) => {
    return rejectOrder(_orderId)
      .then((res) => {
        setOrderId((p) => [...p, _orderId]);
        getAllOrderDetails();
        dispatch(removeRealTimeOrder(_orderId));
      })
      .catch((error) => {});
  };

  const normalizeString = (str) =>
    str?.trim()?.toLowerCase()?.replace(/\s+/g, " ");
  const getFilterData1 = (data) => {
    let _data = data;
    if (search1) {
      const searchTerm = normalizeString(search1);
      _data = _data?.filter((row) =>
        normalizeString(row?.itemname)?.includes(searchTerm)
      );
    }
    return _data;
  };

  const getFilterData = (data) => {
    let _data = [...newRowArray, ...data];
    if (type)
      _data = _data?.filter((row) =>
        type == "Dining"
          ? row.is_dine_in
          : type == "Pickup"
          ? row.is_pick_up
          : type == "Delivery"
          ? row.is_delivery
          : true
      );
    if (sortType == "Ascending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeA - dateTimeB;
      });
    }
    if (sortType == "Descending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeB - dateTimeA;
      });
    }
    if (search)
      _data = _data?.filter((row) => row.order_sequence_id.includes(search));
    if (orderId.length)
      _data = _data?.filter((o) => !orderId.includes(o.order_id));
    // if (newRow) {
    //   _data.unshift(newRow);
    //
    // }
    // console.clear()

    // return _data
    return _data;
  };

  const [outletCount, setOutletCount] = useState();

  useEffect(() => {
    outletIdNew && getOutletsByPrimaryOutlet();
  }, [outletIdNew]);

  const getOutletsByPrimaryOutlet = async () => {
    try {
      const data = await axios.get(`${outletUrl}${outletIdNew}`);
      setOutletCount(data?.data?.data);
    } catch (error) {
      console.log("Error ");
    }
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: "order_id",
        headerName: "Order ID",
        flex: 1,
        minWidth: 90,
        maxWidth: 130,
        sortable: false,
        renderCell: ({ row }) => {
          return <Text bold>{row?.order_sequence_id}</Text>;
        },
      },
      {
        field: "item",
        headerName: "Order Details",
        flex: 1,
        minWidth: 240,
        sortable: false,
        renderCell: ({ row }) => <ItemDetails menuItem={row?.menu_item} />,
      },
      {
        field: "actions",
        headerName: "Order Accept Reject",
        flex: 1,
        minWidth: 170,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <OrderStatus
              row={row}
              chipColor={chipColor}
              handleAccept={handleAccept}
              handleReject={handleReject}
              title="Accept"
              noChip
              button
            />
          );
        },
      },
      {
        field: "view",
        headerName: "Action",
        flex: 1,
        minWidth: 110,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <CustomLink
              onClick={() => orderDetails(row?.order_id)}
              sx={{ cursor: "pointer" }}
            >
              View Details
            </CustomLink>
          );
        },
      },
      {
        field: "order_type",
        headerName: "Order Type",
        flex: 1,
        minWidth: 120,
        maxWidth: 250,
        sortable: false,
        renderCell: ({ row }) => {
          let label, type;
          if (row?.is_dine_in) {
            label = "Dine In";
            type = "dineIn";
          } else if (row?.is_pick_up) {
            label = "Pick Up";
            type = "pickUp";
          } else if (row?.is_delivery) {
            label = "Delivery";
            type = "foodready";
          } else {
            label = "Unknown";
            type = "Unknown";
          }
          return (
            <div>
              <CustomChip
                label={label}
                textColor={chipColor[type]?.textColor}
                backgroundColor={chipColor[type]?.backgroundColor}
              />
              {row?.is_delivery && row?.delivery_addresss && (
                <div
                  style={{
                    marginTop: "5px",
                    fontSize: "1rem",
                    fontWeight: "600",
                  }}
                >
                  {row.delivery_addresss}
                </div>
              )}
            </div>
          );
        },
      },
      {
        field: "schedule_type",
        headerName: "Schedule Type",
        flex: 1,
        minWidth: 130,
        sortable: false,
        renderCell: ({ row }) => (
          <CustomChip
            label={row?.schedule_now ? "Now" : "Later"}
            textColor={
              chipColor[row?.schedule_now ? "dineIn" : "pending"]?.textColor
            }
            backgroundColor={
              chipColor[row?.schedule_now ? "dineIn" : "pending"]
                ?.backgroundColor
            }
          />
        ),
      },
      {
        field: "order_value",
        headerName: "Order Value",
        flex: 1,
        minWidth: 120,
        sortable: false,
        renderCell: ({ row }) => (
          <Text bold>₹ {row?.total_price?.toFixed(2)}</Text>
        ),
      },
      {
        field: "date and time",
        headerName: "Delivery Time",
        flex: 1,
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => {
          const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");
          const formattedTime = parsedTime.format("h:mm A");
          const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");
          let formattedAdjustedTime;

          if (row?.schedule_now) {
            const preparation_time = row?.preparation_time || 0;
            const adjustedTime = parsedTime.add(
              preparation_time + 15,
              "minutes"
            );
            formattedAdjustedTime = adjustedTime.format("h:mm A");
          }

          const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");

          return (
            <Stack>
              <Text bold>{formattedDate2}</Text>
              <Text bold>
                {row?.schedule_now ? formattedAdjustedTime : formattedTime}
              </Text>
            </Stack>
          );
        },
      },
    ];

    // Conditionally add the outletName column only when outletCount > 1
    if (outletCount?.length > 1) {
      baseColumns.splice(2, 0, {
        field: "outletName",
        headerName: "Outlet Details",
        flex: 1,
        minWidth: 140,
        sortable: false,
        renderCell: ({ row }) => (
          <Text>
            {row?.outletName},{row?.campusName}
          </Text>
        ),
      });
    }

    return baseColumns;
  }, [outletCount, chipColor, handleAccept, handleReject, orderDetails]);

  const handleQuantityChange = (itemid, change) => {
    setQuantities((prevQuantities) => {
      const newQuantity = (prevQuantities[itemid] || 0) + change;
      return {
        ...prevQuantities,
        [itemid]: Math.max(newQuantity, 0), // Ensure quantity doesn't go below 0
      };
    });
  };

  // console.log("items ", quantities);

  const columns1 = useMemo(() => [
    {
      field: "itemid",
      headerName: "ID",
      flex: 1,
      maxWidth: 50,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.itemSequenceId}</Text>,
    },
    {
      field: "itemname",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "8px",
            objectFit: "cover",
          }}
          img={row.item_image_url}
          sx={{
            height: 36,
          }}
          title={row.itemname}
          starCount={4}
        />
      ),
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => <Text bold>₹ {row?.price}</Text>,
    },
    {
      field: "quantity",
      headerName: "Qty",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <button
            variant="outlined"
            style={{
              background: "white",
              border: "1.3px solid #4F46E5",
              borderRadius: "0.4rem",
              cursor: "pointer",
            }}
            onClick={() => handleQuantityChange(row?.itemid, -1)}
          >
            -
          </button>
          <Text style={{ margin: "0 10px" }}>
            {quantities[row?.itemid] || 0}
          </Text>
          <button
            variant="outlined"
            style={{
              background: "white",
              border: "1.3px solid #4F46E5",
              borderRadius: "0.4rem",
              cursor: "pointer",
            }}
            onClick={() => handleQuantityChange(row?.itemid, 1)}
          >
            +
          </button>
        </Box>
      ),
    },
  ]);

  const handleSeeOrder = async () => {
    setOrderOpen(false);
    setLoading1(true);
    try {
      const orderData = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/menu/getDetailsForItems`,
        {
          items: quantities,
          outletId: outletIdNew,
          isDelivery: orderType == "Delivery" ? true : false,
          isDineIn: orderType == "Dine In" ? true : false,
          isPickUp: orderType == "Take Away" ? true : false,
        }
      );

      // console.log("orderData ", orderData);

      if (orderData?.data?.success) {
        setLoading1(false);
        setOrderDetail(orderData?.data?.data[0]);
        setOpenDetail(true);
      }
    } catch (error) {
      console.log("Error while fetch order data");
    }
  };

  useEffect(() => {
    // console.log("orderType changed:", orderType);
    handleSeeOrder();
  }, [orderType]);

  // console.log("orderDetail ", orderDetail);

  const handlePlaceOrder = async () => {
    setLoading(true);
    try {
      const order = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}payment/customer/getTransactionIdForCOD`,
        {
          itemTotalPrice: orderDetail?.base_price,
          productinfo: "items",
          firstname: "Restaurant",
          phone: "9638819704",
          email: "gajerajaymin5@gmail.com",
          customerAuthUID: "f35229d5-3ecc-41de-80d2-0e9615a24b88",
          outletId: outletIdNew,
          isDelivery: orderType == "Delivery" ? true : false,
          isDineIn: orderType == "Dine In" ? true : false,
          isPickUp: orderType == "Take Away" ? true : false,
        }
      );

      // console.log("orderData ", order);
      // console.log("orderDetail 568", orderDetail);

      if (order?.data?.success) {
        const orderDaata = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/createOrder`,
          {
            additional_Instruction: "",
            address: "",
            basePrice: order?.data?.response?.foodBasePrice,
            customerAuthUID: order?.data?.response?.customerAuthUID,
            outletId: outletIdNew,
            paymentId: "",
            isCashOrder: true,
            isDelivery: orderType == "Delivery" ? true : false,
            isDineIn: orderType == "Dine In" ? true : false,
            isPickUp: orderType == "Take Away" ? true : false,
            isScheduleNow: true,
            pickupTime: {
              orderDate: new Date()?.toISOString().split("T")[0],
              time: new Date()?.toTimeString().split(" ")[0],
            },
            items: orderDetail?.menu_item,
            restaurantId: null,
            totalPrice: order?.data?.response?.amount,
            txnid: order?.data?.response?.txnid,
          }
        );

        // console.log("place order ", orderDaata);

        if (orderDaata?.data?.success) {
          setOrderOpen(false);
          setOpenDetail(false);
          setQuantities({});
          const orderrr = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/sendOrderToHistory`,
            {
              orderId: orderDaata?.data?.data?.orderid,
            }
          );

          // console.log("send roder to history ", orderrr);

          if (orderrr?.data?.success) {
            setOrderOpen(false);
            setOpenDetail(false);
            setQuantities({});

            // navigate("/orders/#history");
            window?.location?.reload();
            toast.success("Order Placed Successfully");
          }
        }
      }
    } catch (error) {
      console.log("Error while create order");
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = (orderDetail) => {
    var content = document.createElement("div");
    content.innerHTML = getHPrintHTML(orderDetail);

    var pri = document.getElementById("ifmcontentstoprint")?.contentWindow;

    pri?.document?.open();
    pri?.document?.write(content.innerHTML);
    pri?.document?.write(`
    <style type="text/css">
      @media print {
        @page {
            margin-top: 0;
            margin-bottom: 0;
        }
        body {
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 !important;
        }
      }
    </style>
    `);

    pri?.document?.close();
    pri?.focus();
    pri?.print();
  };
  function getHPrintHTML(orderDetail) {
    // console.log("order ", order);
    const itemsHTML = orderDetail?.menu_item?.map(
      (item) =>
        `<div>
          <div style="float:left; margin-bottom:1px;">
          ${
            orderDetail?.additional_instruction
              ? "[note] : " + orderDetail?.additional_instruction
              : ""
          }
          </div><br/>
          <div style="float:left; font-weight: bold;">${item?.itemname} ${
          item?.variation?.variation_name
            ? ` ${item?.variation?.variation_name}`
            : ""
        } x ${item?.quantity}  
          </div>
          <div style="float:right; font-weight: bold;">
            ₹${
              item?.variation?.variation_price
                ? item?.variation?.variation_price
                : item?.calculated_price
            }
          </div>
          <div style="clear:both"></div>
           ${
             item?.addons?.length
               ? item?.addons
                   ?.map(
                     (addon) =>
                       `<div>
                    <div style="float:left;">${addon?.name}</div>
                    <div style="float:right;">₹${addon?.price}</div>
                    <div style="clear:both"></div>
                  </div>`
                   )
                   .join("")
               : ""
           }
        </div>`
    );

    const _html = `
      <div style="border:1px solid lightgray; height:auto; width: 65mm; margin:auto; padding:5px">
        <div style="text-align:center; font-size:18px; font-weight:600">${
          user?.outletName
        }</div>
        <div style="text-align:center">${
          user?.GSTIN ? "GSTIN: " + user?.GSTIN : ""
        }</div>
        <div style="text-align:center">${
          user?.FSSAI_number ? "FSSAI No:" + user?.FSSAI_number : ""
        }</div>
        <div style="text-align:center">${user?.address}</div>
        <div style="text-align:center">Mobile: + 91 ${user?.mobile}</div>
        <div style="text-align:center">Email: ${user?.email}</div>
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
    
        <div>
          <div style="float:left">${orderDetail?.order_schedule_date}</div>
          <div style="float:right">${convertTo12HourFormat(
            orderDetail?.order_schedule_time,
            orderDetail?.preparation_time,
            orderDetail?.schedule_now
          )}</div>
          <div style="clear:both"></div>
        </div>
        <div style="clear: both;border-bottom: 1px dotted black; margin-block:5px"></div>
    
        <div>Order ID: #${orderDetail?.order_sequence_id}</div>
        
        <div style="margin-top:30px;">
          ${itemsHTML.join("")}
          <div style="clear:both"></div>
        </div>
         
        <div style="clear:both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div>
            <div style="float:left">Sub Total:</div>
            <div style="float:right">₹ ${orderDetail?.base_price}</div>
          </div>
          ${
            orderDetail?.is_delivery || orderDetail?.is_pick_up
              ? `<div style="clear:both">
                   <div style="float:left">Packaging Charges:</div>
                   <div style="float:right">₹ ${
                     orderDetail?.packaging_charge || 0
                   }</div>
                 </div>`
              : ""
          }
          ${
            orderDetail?.is_delivery && !orderDetail?.is_pick_up
              ? `<div style="clear: both">
                   <div style="float:left">Delivery Charge:</div>
                   <div style="float:right">₹ ${
                     orderDetail?.deliverycharge || 0
                   }</div>
                 </div>`
              : ""
          }
          <div style="display: ${
            orderDetail?.isgstapplied ? `block` : `none`
          }; clear:both">
            <div style="float:left">Taxes:</div>
            <div style="float:right">₹ ${orderDetail?.foodGST || 0}</div>
          </div>
          <div style="clear: both">
            <div style="float:left">Convenience Fee:</div>
            <div style="float:right">₹ ${
              orderDetail?.convenienceTotalAmount?.toFixed(2) || 0
            }</div>
          </div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div style="float:left">Total:</div>
          <div style="float:right">₹ ${orderDetail?.totalPriceForCustomer?.toFixed(
            2
          )}</div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
      </div>
    `;

    return _html;
  }

  function convertTo12HourFormat(time, preparationTime, addTime) {
    // Remove seconds
    time = time?.split(":")?.slice(0, 2)?.join(":");

    // Convert time to total minutes
    let [hours, minutes] = time?.split(":")?.map(Number);
    let totalMinutes = hours * 60 + minutes;

    // Adjust time based on the boolean value
    if (addTime) {
      totalMinutes += preparationTime + 15;
    }

    // Calculate the new time
    let newHours = Math.floor(totalMinutes / 60) % 24;
    let newMinutes = totalMinutes % 60;

    // Determine AM or PM
    let period = newHours >= 12 ? "PM" : "AM";

    // Adjust hours for 12-hour format
    if (newHours > 12) {
      newHours -= 12;
    } else if (newHours === 0) {
      newHours = 12;
    }

    // Format the time in 12-hour format
    let time12Hour = `${newHours}:${
      newMinutes < 10 ? "0" : ""
    }${newMinutes} ${period}`;

    return time12Hour;
  }

  return (
    <Box p={0}>
      <PageHeader
        secondary
        title="Pending Orders"
        subTitle={"Deliver these orders within 2 hours."}
      />
      {isSmallScreen ? (
        <Box sx={{ width: "100%", mb: isSmallScreen ? 2 : 3 }}>
          <Grid container spacing={2} alignItems="center">
            {/* Search Bar */}
            <Grid item xs={12} md={4}>
              <CustomSearchTextField
                placeholder="Search by Order ID"
                value={search}
                onChange={(e) =>
                  setSearch(e.target.value.replace(/[^0-9]/g, ""))
                }
                fullWidth
              />
            </Grid>

            {/* Filters */}
            <Grid item xs={12} md={3}>
              <StatusFilter
                label="Sort By Time"
                menuList={[
                  { id: 1, label: "Ascending" },
                  { id: 2, label: "Descending" },
                ]}
                onClick={(label) => setSortType(label)}
                fullWidth={isSmallScreen}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StatusFilter
                label="Order Type"
                defaultValue={"All"}
                menuList={[
                  { id: null, label: "All" },
                  { id: 1, label: "Dining" },
                  { id: 2, label: "Pickup" },
                  { id: 3, label: "Delivery" },
                ]}
                onClick={(label) => setType(label)}
                fullWidth={isSmallScreen}
              />
            </Grid>

            {/* Take Order Button */}
            <Grid item xs={12} md={2}>
              <CustomButton
                onClick={() => setOrderOpen(true)}
                width={"4rem"}
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AddCircleOutlineIcon sx={{ mr: 1 }} /> Take Order
              </CustomButton>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <StackRow between mb={"24px"}>
          <CustomSearchTextField
            placeholder="Search by Order ID"
            value={search}
            onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
          />
          <StackRow gap="20px">
            <StatusFilter
              label="Sort By Time"
              menuList={[
                { id: 1, label: "Ascending" },
                { id: 2, label: "Descending" },
              ]}
              onClick={(label) => setSortType(label)}
            />
            <StatusFilter
              label="Order Type"
              defaultValue={"All"}
              menuList={[
                { id: null, label: "All" },
                { id: 1, label: "Dining" },
                { id: 2, label: "Pickup" },
                { id: 3, label: "Delivery" },
              ]}
              onClick={(label) => setType(label)}
            />
            <CustomButton onClick={() => setOrderOpen(true)}>
              <AddCircleOutlineIcon style={{ marginRight: "0.5rem" }} /> Take
              Order
            </CustomButton>
          </StackRow>
        </StackRow>
      )}

      <CustomDataGrid
        // dummyRows={dummyRows}
        dummyRows={dummyRows?.filter(
          (f) => !pendingTOCancelOrders?.some((s) => s?.orderId === f?.order_id)
        )}
        name="pending_orders"
        rowId={"order_id"}
        // url={getPendingOrders + user?.outletId}
        columns={columns}
        labelName="Pending Order"
        filterFn={getFilterData}
        noActions
        getRowHeight={() => "auto"}
        // getEstimatedRowHeight={() => 300}
      />

      {orderOpen && (
        <CustomDialog dontClose open={orderOpen} sx={{ width: "80rem" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageHeader secondary title={`Order Items`} />
            <Close
              style={{ cursor: "pointer", fontSize: "2rem" }}
              onClick={() => setOrderOpen(false)}
            />
          </Box>

          {/* <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          > */}

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap:"wrap"
            }}
          >
            <CustomSearchTextField
              style={{ marginBottom: "1rem" }}
              placeholder="Search"
              value={search1}
              onChange={(e) => setSearch1(e.target.value)}
            />
            {Object.values(quantities).some((quantity) => quantity > 0) && (
              <Button
                style={{
                  fontSize: "1.1rem",
                  backgroundColor: "#574eed",
                  color: "white",
                  marginBottom: "1rem",
                  display: "flex",
                  alignItems: "center",
                }}
                sx={{ textTransform: "none" }}
                onClick={handleSeeOrder}
              >
                <LocalMallOutlinedIcon
                  style={{ fontSize: "1.9rem", marginRight: "0.5rem" }}
                />{" "}
                <div>
                  Check Out (
                  {Object.values(quantities).reduce(
                    (acc, res) => Number(acc) + Number(res),
                    0
                  )}
                  )
                </div>
              </Button>
            )}
          </Box>
          <CustomDataGrid
            rowId="itemid"
            name="item-table"
            height={"490px"}
            columns={columns1}
            url={itemGetUrl + `${outletIdNew}`}
            labelName={"Items"}
            noActions
            filterFn={getFilterData1}
          />
          {/* </Box> */}
        </CustomDialog>
      )}

      {openDetail && (
        <CustomDialog dontClose open={openDetail} sx={{ width: "80rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                marginTop: "-3.5rem",
                fontSize: "3rem",
                cursor: "pointer",
              }}
            >
              <ArrowBack
                style={{ fontSize: "2rem" }}
                onClick={() => setOrderOpen(true)}
              />
            </Box>

            <PageHeader secondary title="Order Details" />

            <Box
              style={{
                display: "flex",
                gap: "0.5rem",
                justifyContent: "flex-end",
                marginTop: "-2.5rem",
                marginRight: "1rem",
              }}
            >
              <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                Items
              </Text>
              <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                (
                {Object.values(quantities).reduce(
                  (acc, res) => Number(acc) + Number(res),
                  0
                )}
                )
              </Text>
            </Box>
          </Box>

          {loading1 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px", // Adjust height as per your requirement
              }}
            >
              <Text style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Calculating...
              </Text>
            </Box>
          ) : (
            <>
              <Box style={{ padding: "1.5rem" }}>
                {orderDetail?.menu_item?.map((order) => (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <Text>
                      {order?.itemname} x {order?.quantity}
                    </Text>
                    <Text>₹{order?.price?.toFixed(2)}</Text>
                  </Box>
                ))}
                <Divider />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Text>Sub Total:</Text>
                  <Text>₹{orderDetail?.base_price?.toFixed(2)}</Text>
                </Box>
                {orderDetail?.is_delivery && (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Text>Delivery Charge:</Text>
                    <Text>₹{orderDetail?.deliverycharge?.toFixed(2)}</Text>
                  </Box>
                )}
                {!!orderDetail?.packaging_charge && (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Text>Packaging Charge:</Text>
                    <Text>₹{orderDetail?.packaging_charge?.toFixed(2)}</Text>
                  </Box>
                )}

                {
                      orderDetail?.isgstapplied &&  <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <Text>Taxes:</Text>
                        <Text>₹{orderDetail?.foodGST?.toFixed(2)}</Text>
                      </Box>
                     }
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Text>Convenience Fee:</Text>
                  <Text>
                    ₹{orderDetail?.convenienceTotalAmount?.toFixed(2)}
                  </Text>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Text bold>
                    {orderDetail?.isGSTApplied
                      ? "Subtotal"
                      : "Subtotal + Taxes:"}
                  </Text>
                  <Text bold>
                    ₹{orderDetail?.totalPriceForCustomer?.toFixed(2)}
                  </Text>
                </Box>
              </Box>

              {/* Radio Buttons for Order Type */}
              <Box style={{ padding: "1.5rem"}}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="orderType"
                    name="orderType"
                    value={orderType}
                    onChange={handleOrderTypeChange}
                    row // Makes the radio buttons display in a row
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        flexWrap:"wrap"
                      }}
                    >
                      <Box
                        sx={{
                          border: "1px solid #4F46E5",
                          borderRadius: "0.5rem",
                          padding: "0.3rem 1rem",
                        }}
                      >
                        <FormControlLabel
                          value="Take Away"
                          control={<Radio />}
                          label="Take Away"
                        />
                      </Box>

                      <Box
                        sx={{
                          border: "1px solid #4F46E5",
                          borderRadius: "0.5rem",
                          borderRadius: "0.5rem",
                          padding: "0.3rem 1rem",
                        }}
                      >
                        <FormControlLabel
                          value="Delivery"
                          control={<Radio />}
                          label="Delivery"
                        />
                      </Box>

                      <Box
                        sx={{
                          border: "1px solid #4F46E5",
                          borderRadius: "0.5rem",
                          padding: "0.3rem 1rem",
                        }}
                      >
                        <FormControlLabel
                          value="Dine In"
                          control={<Radio />}
                          label="Dine In"
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1.4rem",
                  gap: "1rem",
                  flexWrap:"wrap"
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#F9F7F5",
                    color: "black",
                    fontSize: "1rem",
                  }}
                  onClick={() => {
                    setOpenDetail(false);
                    setQuantities({});
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    fontSize: "1rem",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setOrderOpen(true);
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Edit
                </Button>
                <Button
                  style={{
                    fontSize: "1rem",
                    backgroundColor: "#574eed",
                    color: "white",
                  }}
                  sx={{ textTransform: "none" }}
                  onClick={() => handlePrint(orderDetail)}
                >
                  Print Kot
                </Button>

                <Button
                  style={{
                    fontSize: "1rem",
                    backgroundColor: "#574eed",
                    color: "white",
                  }}
                  sx={{ textTransform: "none" }}
                  onClick={handlePlaceOrder}
                >
                  {" "}
                  <ReceiptIcon
                    style={{ fontSize: "1.8rem", marginRight: "0.5rem" }}
                  />
                  {loading ? "Ordering..." : "Place Order"}
                </Button>
              </Box>
            </>
          )}
        </CustomDialog>
      )}
      <iframe
        id="ifmcontentstoprint"
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
          visibility: "hidden",
        }}
      ></iframe>
    </Box>
  );
};

export default PendingOrders;

const ItemDetails = ({ menuItem }) => {
  const order = menuItem?.slice(0, 4).map((item, index) => (
    <React.Fragment key={item?.item_price * index}>
      <Text
        bold
        sx={{
          overflow: "hidden",
          width: "230px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {item?.quantity} x {item?.itemname}{" "}
        {item?.variation?.variation_name ? item.variation.variation_name : ""}
        {item?.addons?.length
          ? ", " + item?.addons.map((addon) => addon?.name).join(", ")
          : ""}
      </Text>
    </React.Fragment>
  ));
  return (
    <span>
      {order}{" "}
      {menuItem?.length > 4 && (
        <Chip
          label={"+" + (menuItem?.length - 4) + " more"}
          sx={{ height: "28px", py: "8px" }}
        />
      )}
    </span>
  );
};
