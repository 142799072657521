import { Box, Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import CustomButton from "../../../components/formUI/CustomButton";
import { Add, Category } from "@mui/icons-material";
import StackRow from "../../../components/layoutUI/StackRow";
import ServerPaginationDataGrid from "../../../components/pageUI/ServerPaginationDataGrid";
import ItemCard from "../../../components/pageUI/ItemCard";
import Text from "../../../components/typographyUI/Text";
import CustomMenu from "../../../components/layoutUI/CustomMenu";
import EditSvg from "../../../components/svgComponents/EditSvg";
import DeleteSvg from "../../../components/svgComponents/DeleteSvg";
import CustomSwitch from "../../../components/formUI/CustomSwitch";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import axios from "axios";
// import {
//   categoryDeleteUrl,
//   categoryGetUrl,
//   categoryPutUrl,
//   subCategoryDeleteUrl,
//   subCategoryPutUrl,
// } from "../../../utils/urls";
import CustomDataGrid from "../../../components/pageUI/CustomDataGrid";
import { useGetApis } from "../../../hooks/useGetApis";
import RestoListFilter from "../../../components/pageUI/filters/RestoListFilter";
import CustomSearchTextField from "../../../components/formUI/CustomSearchTextField";
import {
  useSmallScreen,
  isMobile,
  useMediumScreen,
} from "../../../utils/mediaQueries";

const Categories = () => {
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const { categoryDeleteUrl, categoryGetUrl, categoryPutUrl } = useGetApis();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [outletId, setOutletId] = useState(user?.outletId);
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const [search, setSearch] = useState("");

  const { mutate: mutatePut } = useMutation((data) => {
    const url = categoryPutUrl + data.parent_category_id;
    return axios.post(url, data);
  });
  const columns = useMemo(() => [
    {
      field: "category",
      headerName: "Categories",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          center
          sx={{
            height: 36,
          }}
          title={row?.parentCategoryName}
          img={row.parent_category_image_url}
        />
      ),
    },
    {
      field: "productCount",
      headerName: "Products",
      flex: 1,
      minWidth: 180,
      sortable: false,

      renderCell: ({ row }) => <Text>{row?.Menu_Item_count}</Text>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomSwitch
          value={row.status}
          label={(state) => (state ? "In Stock" : "Out of stock")}
          onChange={(e) => {
            mutatePut({
              parent_category_id: row.parent_category_id,
              status: e,
            });
          }}
        />
      ),
    },
  ]);
  const getFilterData = (data) => {
    let _data = data;
    if (search)
      _data = _data?.filter((row) =>
        row?.parentCategoryName.toLowerCase().includes(search.toLowerCase())
      );
    return _data;
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <PageHeader
          secondary
          title={"Categories"}
          subTitle={"View and update your store details"}
          rightUi={
            !isSmallScreen && (
              <StackRow gap={"10px"} between center>
                {/* <RestoListFilter
              onClick={(label, id) => {
                setOutletId(id);
              }}
            /> */}
                <CustomSearchTextField
                  placeholder="Search Category"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <CustomButton
                  startIcon={<Add />}
                  onClick={() => navigate("add")}
                >
                  Add Menu Category
                </CustomButton>
              </StackRow>
            )
          }
        />
         {
          isSmallScreen && (<Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                {/* Search Bar - Full width on all screen sizes */}
                <Grid item xs={12} md={8}>
                  <CustomSearchTextField
                    placeholder="Search Category"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    fullWidth
                  />
                </Grid>

                {/* Add Menu Category Button - Full width on small screens, auto on larger screens */}
                <Grid item xs={6} md={4}>
                  <CustomButton
                    startIcon={<Add />}
                    onClick={() => navigate("add")}
                    
                  >
                    Add Menu Category
                  </CustomButton>
                </Grid>
              </Grid>)
         }
        {/* <ServerPaginationDataGrid
          labelName="Category"
          height={"700px"}
          columns={columns}
          dummyRows={[
            {
              id: 1,
              productId: 1,
              cookingTime: 20,
            },
          ]}
        /> */}
        <CustomDataGrid
          rowId={"parent_category_id"}
          name="Categories"
          url={categoryGetUrl + outletIdNew}
          height={"700px"}
          columns={columns}
          labelName={"Category"}
          // noActions
          filterFn={getFilterData}
        />
      </Box>
    </motion.div>
  );
};

export default Categories;
