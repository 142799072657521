import React, { useState } from "react";
import QrReader from "react-qr-scanner";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import CustomDialog from "../layoutUI/CustomDialog";
import Scan from "../../assets/icons/scan.svg";
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";

const QRScanner = ({outletId, afterScanMethod}) => {
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const  handleClose= () => {
    setOpen(false);
  };

  const handleScan = (data) => {
    
    if (data) {

      if (data?.text?.includes("===")) {
        const _otp = data.text.split("===")?.[0];
        const _orderId = data.text.split("===")?.[1];
        axios.post(process.env.REACT_APP_BACKEND_BASE_URL + "order/order/orderVerifyOTP", { otp: _otp, orderId: _orderId, outletId: outletId }).then((res) => {
          // setQrOrderId((p) => [...p, _orderId])
          
          toast.success("Otp verified");
          afterScanMethod(_orderId);
        }).catch((error) => {
          toast.error(error?.response?.data?.error);
          // 
        });
      } else {
        toast.error("Invalid QR code.");
      }

      handleClose()
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <IconButton
      onClick={handleOpen}
      >
        <img
          src={Scan}
          style={{
            width: "30px",
            height: "30px",
          }}
        />
      </IconButton>
    
      {open && (
        <CustomDialog
          open={true}
          sx={{
            width: 600,
          }}
          // buttonComp={(props) => (

          // )}
        >
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%" }}
          />
        </CustomDialog>
      )}
    </div>
  );
};

export default QRScanner;
