import { Box, Grid, InputAdornment, Stack } from "@mui/material";
import React, { useState } from "react";
import FormHeader from "../../../formUI/FormHeader";
import CustomTextField from "../../../formUI/CustomTextField";
import CustomSelect from "../../../formUI/CustomSelect";
import { Form, Formik, useFormik } from "formik";
import CustomButton from "../../../formUI/CustomButton";
import * as Yup from "yup";
import CustomTextFieldFormik from "../../../formUI/CustomTextFieldFormik";
import { mobilePattern, panCardPattern } from "../../../../utils/regex";
import CustomSelectFormik from "../../../formUI/CustomSelectFormik";
import StackRow from "../../../layoutUI/StackRow";
import PaperBox from "../../../layoutUI/PaperBox";
import CustomCheckbox from "../../../formUI/CustomCheckbox";
import { useSelector } from "react-redux";
const EstablishmentDetails = ({ formData = { isDineIn : false, isPickUp : false, isVeg : false, isNonVeg : false, isDelivery : false } , onSetState, tabIndexHandler }) => {
  
  const { isDineIn, isPickUp, isVeg, isNonVeg, isDelivery } = formData;
  
  const onChange = (e) =>
    onSetState({ ...formData, [e.target.name]: e.target.checked });

  const handlenBack = () => {
    tabIndexHandler(2);
  };

  return (
    <Stack
      sx={{
        minHeight: "550px",
      }}
    >
      <Grid container spacing={"24px"}>
        <Grid item xs={12} md={6}>
          <PaperBox shadowed>
            <FormHeader>Establishment Details:</FormHeader>
            <Stack>
              <CustomCheckbox
                name="isDineIn"
                value={isDineIn}
                label="Dine in"
                onChange={onChange}
              />
              <CustomCheckbox
                onChange={onChange}
                name="isPickUp"
                value={isPickUp}
                label="Pickup"
              />
              <CustomCheckbox
                onChange={onChange}
                name="isDelivery"
                value={isDelivery}
                label="Delivery"
              />
            </Stack>
          </PaperBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <PaperBox shadowed>
            <FormHeader>Type of Restaurant:</FormHeader>
            <Stack>
              <CustomCheckbox
                onChange={onChange}
                name="isVeg"
                value={isVeg}
                label="Veg"
              />
              <CustomCheckbox
                onChange={onChange}
                name="isNonVeg"
                value={isNonVeg}
                label="Non Veg"
              />
              <CustomCheckbox
                onChange={onChange}
                name="isNonVeg"
                value={isNonVeg}
                label="Non veg"
                labelSx={{visibility:"hidden"}}
                checkboxSx={{visibility:"hidden"}}
              />
            </Stack>
          </PaperBox>
        </Grid>
      </Grid>

      <StackRow
        justifyContent="flex-end"
        sx={{
          marginTop: "auto",
        }}
      >
        <CustomButton
          // type="submit"
          variant="outlined"
          sx={{
            fontWeight: "400",
            mt: 2,
            mr: 2,
          }}
          onClick={handlenBack}
        >
          Back
        </CustomButton>
        <CustomButton
          onClick={() => tabIndexHandler(4)}
          sx={{
            fontWeight: "400",
            mt: 2,
          }}
        >
          Save
        </CustomButton>
      </StackRow>
    </Stack>
  );
};

export default EstablishmentDetails;
