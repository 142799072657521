import React from "react";

const DownSvg = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.69043 4.89221L6.3475 7.54928C6.64038 7.84216 7.11524 7.84216 7.40812 7.54928L10.0652 4.89221"
        stroke="#0F172A"
        strokeWidth="1.12496"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DownSvg;
