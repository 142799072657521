import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
// const BACKEND_BASE_URL = 'http://localhost:8000/';

async function updateOutlet(data, outletId) {
  return await axios.post(
    BACKEND_BASE_URL + `outlet/publishOutlet/${outletId}`,
    data
  );
}

export { updateOutlet };
