// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { ResponsiveBar } from "@nivo/bar";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
let data = [
  {
    country: "AG",
    dineincount: 24,
    pickupcount: 26,
  },
  {
    country: "AI",
    dineincount: 25,
    pickupcount: 25,
  },
  {
    country: "AL",
    dineincount: 20,
    pickupcount: 30,
  },
];

const BarChart2 = ({
  data: _data = null,
  /* see data tab */
}) => (
  <ResponsiveBar
    data={_data || data}
    keys={["Dine In", "Take Away", "Delivery"]}
    // gridYValues={[10, 20, 30, 40, 50]} // Specify the y-values where you want the dotted line
    indexBy="country"
    margin={{ top: 30, right: 40, bottom: 70, left: 60 }}
    padding={0.5}
    innerPadding={1}
    minValue={0}
    // maxValue={100}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    // colors={{ scheme: "nivo" }}
    yFormat=" >-.2f"
    
    borderRadius={3}
    borderColor={{ theme: "background" }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 0,
      tickPadding: 10,
      tickRotation: 0,
      legend: "Time",
      legendPosition: "middle",
      legendOffset: 40,
    }}
    axisLeft={{
      orient: "right",
      tickSize: 0,
      tickPadding: 10,
      // tickValues: [0, 10, 20, 30, 40, 50],
      format: (value) => `${value}`,
      tickRotation: 0,
      legendPosition: "middle",
      legend: "Number of Orders",
      legendOffset: -40,
    }}
    enableLabel={false}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    // legends={[]}
    legends={[
      {
        dataFrom: "keys",
        anchor: "top-right",
        direction: "row",
        justify: false,
        translateX: 0,
        translateY: -30,
        itemWidth: 100,
        itemHeight: 20,
        itemsSpacing: 2,
        symbolSize: 10,
        symbolShape: "circle",
        itemDirection: "left-to-right",
      },
    ]}
    role="application 2"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={(e) =>
      e.id + ": " + e.formattedValue + " in country: " + e.indexValue
    }
    // colors={["#4F46E5","#22C55E","#9234EA"]}
    colors={["#9E77ED", "#D6BBFB", "#F4EBFF"]}
    // colorBy="index"
    defs={[
      {
        id: "dots1",
        type: "patternDots",
        background: "#D6BBFB",
        color: "#D6BBFB",
        size: 4,
        padding: 1,
        stagger: true,
      },

      {
        id: "lines3",
        type: "patternLines",
        background: "#9E77ED",
        color: "#9E77ED",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: "pickupcount",
        },
        id: "dots1",
      },
      {
        match: {
          id: "dineincount",
        },
        id: "lines3",
      },
    ]}
  />
);

export default BarChart2;
