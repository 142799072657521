import { Box, Grid, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import CustomButton from "../../../components/formUI/CustomButton";
import StackRow from "../../../components/layoutUI/StackRow";
import PaperBox from "../../../components/layoutUI/PaperBox";
import { motion } from "framer-motion";
import { useMutation } from "react-query";
import axios from "axios";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import CustomTextFieldFormik from "../../../components/formUI/CustomTextFieldFormik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useGetApis } from "../../../hooks/useGetApis";
import { authLogin } from "../../../Redux Store/Slices/auth";

const Taxation = () => {
  const initialState = {
    CGST: "",
    SGST: "",
    packaging_charge: "",
  };
  const { taxationUrl, packageingChargesUrl } = useGetApis();
  const [formData, setFormData] = useState(initialState);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  

  useEffect(() => {
    setFormData(user?.Tax || initialState);
  }, [user]);

  const { mutate, isLoading: isLoadingMutate } = useMutation(
    async ({ data, values }) => {
      return axios
        .post(taxationUrl, { tax: data })
        .then(() => ({ data, values }));
    },
    {
      onSuccess: ({ data, values }) => {
        
        dispatch(
          authLogin({
            ...user,
            Tax: data,
            packaging_charge: values.packaging_charge,
          })
        );
        mutateRestoOrOutlet({ packaging_charge: values.packaging_charge });
      },
    }
  );
  const { mutate: mutateRestoOrOutlet, isLoading } = useMutation(
    async (data) => {
      return axios
        .post(packageingChargesUrl + (user.outletId || user.restaurantId), data)
        .then(() => data);
    },
    {
      onSuccess: (res) => {
        toast.success("Taxation Updated");

        
      },
    }
  );
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Formik
        initialValues={{
          CGST: user.Tax.find((t) => t.taxname == "CGST").tax || 0,
          SGST: user.Tax.find((t) => t.taxname == "SGST").tax || 0,
          packaging_charge: user.packaging_charge,
        }}
        onSubmit={(values) => {
          let data = formData.map((obj) => {
            if (obj.taxname == "CGST") return { ...obj, tax: values.CGST };
            else return { ...obj, tax: values.SGST };
          });
          setFormData(data);
          
          mutate({ data, values });
          // mutateRestoOrOutlet({ packaging_charge: values.packaging_charge });
        }}
        validationSchema={Yup.object().shape({
          // CGST: Yup.number().required("CGST is required"),
          // SGST: Yup.number().required("SGST is required"),
          // packaging_charge  : Yup.number().required(
          //   "Packaging Charges is required"
          // ),
        })}
      >
        <Form>
          <Box>
            <PageHeader
              secondary
              title={"Taxation"}
              subTitle={"View and update your store details"}
              rightUi={
                <CustomButton
                  loading={isLoadingMutate || isLoading}
                  type="submit"
                >
                  Save
                </CustomButton>
              }
            />
            <StackRow gap={"32px"}>
              <PaperBox
                shadowed
                padding={"0 24px 16px 24px"}
                sx={{
                  flex: "1",
                  overflow: "auto",
                  maxWidth: "800px",
                }}
              >
                <Box
                  id="menu-media"
                  sx={{
                    pt: "24px",
                    pb: 3,
                  }}
                >
                  <PageHeader secondary title={"Types of taxes"} />
                  <Grid container spacing={"20px"}>
                    <Grid item xs={6}>
                      <CustomTextFieldFormik
                        type="number"
                        name="CGST"
                        label="CGST "
                        placeholder="Enter CGST"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextFieldFormik
                        type="number"
                        name="SGST"
                        label="SGST "
                        placeholder="Enter SGST"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </PaperBox>
            </StackRow>
            <Box mt={"40px"}>
              <PageHeader
                secondary
                title={"Packaging Charges "}
                subTitle={"View and update your store details"}
              />
            </Box>
            <StackRow gap={"32px"}>
              <PaperBox
                shadowed
                padding={"0 24px 16px 24px"}
                sx={{
                  flex: "1",
                  overflow: "auto",
                  maxWidth: "800px",
                }}
              >
                <Box
                  id="menu-media"
                  sx={{
                    pt: "24px",
                    pb: 3,
                  }}
                >
                  <PageHeader secondary title={"Types of Charges"} />

                  <Grid container spacing={"20px"}>
                    <Grid item xs={12}>
                      <CustomTextFieldFormik
                        type="number"
                        name="packaging_charge"
                        label="Packaging Charges"
                        placeholder="Enter Packaging Charges"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </PaperBox>
            </StackRow>
          </Box>
        </Form>
      </Formik>
    </motion.div>
  );
};

export default Taxation;
