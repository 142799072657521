import { Box, Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import CustomButton from "../../../components/formUI/CustomButton";
import { Add } from "@mui/icons-material";
import StackRow from "../../../components/layoutUI/StackRow";
import ItemCard from "../../../components/pageUI/ItemCard";
import CustomSwitch from "../../../components/formUI/CustomSwitch";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useMutation, useQuery } from "react-query";
import CustomDataGrid from "../../../components/pageUI/CustomDataGrid";
import axios from "axios";
// import {
//   subCategoryDeleteUrl,
//   subCategoryGetUrl,
//   subCategoryPutUrl,
// } from "../../../utils/urls";
import { useSelector } from "react-redux";
import { useGetApis } from "../../../hooks/useGetApis";
import RestoListFilter from "../../../components/pageUI/filters/RestoListFilter";
import CustomSearchTextField from "../../../components/formUI/CustomSearchTextField";
import Text from "../../../components/typographyUI/Text";
import {
  useSmallScreen,
  isMobile,
  useMediumScreen,
} from "../../../utils/mediaQueries";

const SubCategories = () => {
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const { subCategoryGetUrl, subCategoryPutUrl } = useGetApis();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [outletId, setOutletId] = useState(user?.outletId);
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const [search, setSearch] = useState("");

  const { mutate: mutatePut } = useMutation((data) =>
    axios.post(subCategoryPutUrl + data.categoryid, data)
  );
  const columns = useMemo(() => [
    {
      field: "categoryname",
      headerName: "Sub Categories",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          img={row.category_image_url}
          title={row.categoryname}
          center
          sx={{
            height: 36,
          }}
        />
      ),
    },
    {
      field: "productCount",
      headerName: "Products",
      flex: 1,
      minWidth: 180,
      sortable: false,

      renderCell: ({ row }) => <Text>{row?.Menu_Item_count}</Text>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomSwitch
          value={row.status}
          label={(state) => (state ? "In Stock" : "Out of stock")}
          onChange={(e) => {
            
            mutatePut({ ...row, status: e });
          }}
        />
      ),
    },
  ]);

  const getFilterData = (data) => {
    let _data = data;
    if (search)
      _data = _data?.filter((row) =>
        row?.categoryname.toLowerCase().includes(search.toLowerCase())
      );
    return _data;
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <PageHeader
          secondary
          title={"Sub-Categories"}
          subTitle={"View and update your store details"}
          rightUi={ !isSmallScreen &&
            <StackRow gap={"10px"} between center>
              {/* <RestoListFilter
                onClick={(label, id) => {
                  setOutletId(id);
                }}
              /> */}
              <CustomSearchTextField
                placeholder="Search Sub-Category"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <CustomButton startIcon={<Add />} onClick={() => navigate("add")}>
                Add Sub Category
              </CustomButton>
            </StackRow>
          }
        />
        {
          isSmallScreen && (
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                {/* Search Bar - Full width on all screen sizes */}
                <Grid item xs={12} md={8}>
                  <CustomSearchTextField
                    placeholder="Search Category"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    fullWidth
                  />
                </Grid>

                {/* Add Menu Category Button - Full width on small screens, auto on larger screens */}
                <Grid item xs={6} md={4}>
                <CustomButton startIcon={<Add />} onClick={() => navigate("add")}>
                Add Sub Category
              </CustomButton>
                </Grid>
              </Grid>
          )
        }
        <CustomDataGrid
          rowId={"categoryid"}
          name="Sub-Category"
          url={subCategoryGetUrl + outletIdNew}
          height={"700px"}
          columns={columns}
          labelName={"Sub-category"}
          filterFn={getFilterData}
        />
      </Box>
    </motion.div>
  );
};

export default SubCategories;
