import React from "react";
import PaperBox from "../PaperBox";
import BgHeaderPng from "../../../assets/images/bg-header.png";
import StackRow from "../StackRow";
import Title from "../../typographyUI/Title";
import CustomButton from "../../formUI/CustomButton";
import { Box, Slide } from "@mui/material";
import TabPanel from "./TabPanel";

const TabPanelUI = ({ tabIndex, children, tabIndexHandler, onSubmit, sx }) => {
  return (
    <div>
      <PaperBox
        padding={"20px 36px 32px"}
        boxSx={{
          display: "flex",
          flexDirection: "column",
        }}
        sx={{
          mt: "40px",
          background: tabIndex == 5 ? 'none' : `url(${BgHeaderPng})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        shadowed
      >
        <StackRow
          between
          center
          sx={{
            pb: "24px",
          }}
        >
          <Title fontWeight="700" fontSize="20px" style={{...sx, display : tabIndex == 5 ? 'none' : 'block'}}>
            Restaurant Details
          </Title>
          {/* <CustomButton
            onClick={() => tabIndexHandler(tabIndex + 1)}
            textColor="#334155"
            bgColor={"white"}
            borderColor="#CBD5E1"
            variant="outlined"
            sx={{
              opacity: tabIndex == 3 ? "0" : "1",
            }}
          >
            Skip
          </CustomButton> */}
        </StackRow>
        <Box sx={{ flex: "1" }}>{children}</Box>
        {/* <StackRow justifyContent="flex-end">
          <CustomButton
            sx={{
              fontWeight: "400",
            }}
            onClick={() => {
              onSubmit(tabIndex, () => tabIndexHandler(tabIndex + 1));
            }}
          >
            {tabIndex == 3 ? "Save" : "Save & Next"}
          </CustomButton>
        </StackRow> */}
      </PaperBox>
    </div>
  );
};

export default TabPanelUI;
