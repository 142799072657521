import { useField } from "formik";
import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CircularProgress, Stack, Typography } from "@mui/material";

export default function CustomSelectFormik({
  noLabel = false,
  required,
  label = "Add Label",
  sx = {},
  placeholder,
  value = "Role",
  menuList = [],
  isStaff = false,
  noLabelMenu,
  defaultValue,
  disabled=false,
  onChange = () => {},
  loading = true,
  ...otherProps
}) {
  const [field, meta] = useField(otherProps.name);

  // 

  // 
  // 
  // 
  // 
  // 

  return (
    <Stack spacing={0.5}>
      {!noLabel && (
        <Typography
          fontWeight={"500"}
          sx={{
            fontSize: "1rem",
            mb: "0.2rem",
          }}
        >
          {label}{" "}
          <span
            style={{
              display: required ? "inline" : "none",
              color: "#EF4444",
            }}
          >
            *
          </span>
          {!menuList.length && loading && (
            <CircularProgress
              sx={{
                ml: 1,
              }}
              size={14}
            />
          )}
        </Typography>
      )}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Age"
        defaultValue={"defaultValue"}
        // value={isStaff ? (field?.value?.roleId || (field?.value||"")) : (field?.value || "")}
        value={field?.value || ""}
        onChange={(e) => {
          field.onChange(e);
          onChange(e);
        }}
        onBlur={field.onBlur}
        error={meta.touched && meta.error ? true : false}
        {...otherProps}
        sx={{
          position: "relative",
          top: "2px",
          mb: "1.25rem",
          borderRadius: "0.625rem",
          boxShadow: "0px 1px 2px rgba(15, 23, 42, 0.06)",
          height: "40px",
          padding: "11px",
          color: "#94A3B8",
          borderColor: "#CBD5E1",
          "& .MuiOutlinedInput-notchedOutline": {},
          "& legend": {
            display: "none",
          },
          "& fieldset": {
            border: `1px ${
              meta.touched && meta.error ? "#d32f2f" : "#CBD5E1"
            } solid!important`,
          },
          ...sx,
        }}
        disabled={disabled}
      >
        {!noLabelMenu && placeholder && (
          <MenuItem value={""}>{placeholder}</MenuItem>
        )}
        {menuList.map((menu, index) => (
          <MenuItem key={menu.id || index} value={menu.value}>
            {menu.name}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <Typography variant="body2" color="error" sx={{
          fontSize : '0.75rem', marginLeft: '13px !important', marginTop: '5px !important'
        }} >
          {meta.error}
        </Typography>
      )}
    </Stack>
  );
}
