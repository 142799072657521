import axios from "axios";

export const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

export const loginUrl1 = baseUrl + "restaurent/restaurentLogin1";
export const loginUrl = baseUrl + "restaurent/restaurentLogin";
export const outletUrl = baseUrl + "outlet/getOutletListByPrimaryOutletId/";
export const createOutletUrl = baseUrl + "outlet/createOutlet";
export const updateOutlet = baseUrl + 'outlet/updateOutlet/'
export const campusListUrl = baseUrl + "campus/getCampus/";
export const bankList = baseUrl + "common/getBankNames";
// export const campustByCity = baseUrl + "campus/getCampus/";
export const categoryListUrl = baseUrl + "category/getCategoryList";
export const dayUrl = baseUrl + "days/getDaysList";
export const cityListUrl = baseUrl + "city/getCities";

// sub-category
export const subCategoryUrl = baseUrl + "restaurent/createCategory";
export const subCategoryGetUrl = baseUrl + "restaurent/category/";
export const subCategoryGetSingleUrl = baseUrl + "restaurent/getCategoryById/";
export const subCategoryPutUrl = baseUrl + "restaurent/updateCategory/";
export const subCategoryDeleteUrl = baseUrl + "restaurent/deleteCategory/";
export const subCategoryImageUrl = baseUrl + "restaurent/upsertCategoryImage";
// category
export const categoryUrl = baseUrl + "restaurent/createParentCategory";
export const categoryGetUrl = baseUrl + "restaurent/getParentCategory/";
export const categoryGetSingleUrl =
  baseUrl + "restaurent/getParentCategoryById/";
export const categoryPutUrl = baseUrl + "restaurent/updateParentCategory/";
export const categoryDeleteUrl = baseUrl + "restaurent/deleteParentCategory/";
export const categoryImageUrl =
  baseUrl + "restaurent/upsertParentCategoryImage";
// item
export const itemUrl = baseUrl + "restaurent/addMenu";
export const itemGetUrl = baseUrl + "restaurent/getItemList";
export const itemGetSingleUrl = baseUrl + "restaurent/getItem/";
export const itemPutUrl = baseUrl + "restaurent/updateMenu/";
export const itemDeleteUrl = baseUrl + "restaurent/deleteItem/";
export const itemImageUrl = baseUrl + "restaurent/upsertMenuItemImage";
// attributes
export const attributeUrl = baseUrl + "outlet/menu/getAttributes";
export const spiceLevelUrl = baseUrl + "common/getSpiceLevel";
export const dietaryRestrictionsUrl = baseUrl + "common/getDietaryRestrictions";

//getorders
// export const getAllOrdersUrl = baseUrl + "order/order/getReadyOrders";

export async function getCampusByCity(cityId){
  return await axios.get(baseUrl + "campus/getCampus/" + cityId)
}

export function convertObjectToFormData(data) {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key];
    formData.append(key, value);
  });

  
  

  return formData;
}
