import React, { useState } from "react";
import CustomButton from "../components/formUI/CustomButton";
import axios from "axios";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import Logo from "../assets/images/newLogo.png";
import { Link } from "react-router-dom";

const SendForgotEmail = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendEmail = async () => {
    setLoading(true);
    setMessage("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/resetPassword`,
        {
          email,
        }
      );

      console.log("res ", response);

      if (response?.data?.success) {
        toast.success(response?.data?.message, {
          position: "top-center",
        });
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ height: "100vh", background: "#E1F8FE" }}>
      <Link to={"/login"}>
        <img style={{ width: "15rem", margin: "3rem" }} src={Logo} alt="logo" />
      </Link>
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>Forgot Password</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Enter you email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={handleEmailChange}
            style={{ borderRadius: "7px", marginRight: "5px", width: "25rem" }}
            required
          />
          <CustomButton
            onClick={handleSendEmail}
            disabled={loading}
            style={{ height: "4rem", marginTop: "0.7rem" }}
          >
            {loading ? "Sending..." : "Send Email"}
          </CustomButton>
        </div>
        {message && <p style={{ color: "red" }}>{message}</p>}
      </div>
    </div>
  );
};

export default SendForgotEmail;
