import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import CustomButton from "../../../components/formUI/CustomButton";
import StackRow from "../../../components/layoutUI/StackRow";
import PaperBox from "../../../components/layoutUI/PaperBox";
import { useNavigate, useParams } from "react-router-dom";
import FormHeader from "../../../components/formUI/FormHeader";
import CustomChip from "../../../components/pageUI/CustomChip";
import { Add, Remove } from "@mui/icons-material";
import { motion } from "framer-motion";
import UpliadImageFieldWithoutFormik from "../../../components/formUI/UpliadImageFieldWithoutFormik";
import { useSelector } from "react-redux";
// import {
//   categoryGetSingleUrl,
//   categoryImageUrl,
//   categoryPutUrl,
//   categoryUrl,
//   convertObjectToFormData,
//   subCategoryGetUrl,
// } from "../../../utils/urls";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomTextFieldFormik from "../../../components/formUI/CustomTextFieldFormik";
import CustomLoader from "../../../components/layoutUI/CustomLoader";
import { useGetApis } from "../../../hooks/useGetApis";
import CustomCheckboxWithFormik from "../../../components/formUI/CustomCheckboxWithFormik";
import Text from "../../../components/typographyUI/Text";

const CategoriesAdd = () => {
  const {
    categoryGetSingleUrl,
    categoryImageUrl,
    categoryPutUrl,
    categoryUrl,
    convertObjectToFormData,
    subCategoryGetUrl,
  } = useGetApis();
  const { user } = useSelector((state) => state.auth);
  const initialData = {
    restaurantId: user.restaurantId,
    outletId: user.outletId,
    parentCategoryName: "",
  };
  const [formData, setFormData] = useState(initialData);
  const navigate = useNavigate();
  const { cId } = useParams();
  const [img, setImg] = useState(null);
  const [categories, setCategories] = useState([]);

  const [isLoadingFormData, setIsLoadingFormData] = useState(null);
  const fetchData = () => {
    setIsLoadingFormData(true);
    axios
      .get(categoryGetSingleUrl + cId)
      .then((res) => {
        setFormData(res.data.data[0]);
        setCategories(
          res.data.data[0].Menu_Categories.map((c) => c.categoryid) || []
        );
        setIsLoadingFormData(false);
        setImg(res.data.data[0].parent_category_image_url);
      })
      .catch((err) => setIsLoadingFormData(false));
  };
  useEffect(() => {
    cId && fetchData();
  }, []);

  const { mutate, isLoading } = useMutation(
    (data) => {
      if (cId) {
        const url = categoryPutUrl + data.parent_category_id;
        return axios.post(url, data);
      } else {
        return axios.post(categoryUrl, data);
      }
    },
    {
      onSuccess: (res) => {
        // console.log(res.data.data);
        const parent_category_id = res.data.data[0]?.parent_category_id;
        typeof img != "string" &&
          photoMutate({ file: img, parent_category_id });
        typeof img == "string" && toast.success("Category Updated");
        typeof img == "string" && navigate(-1);
      },
      onError: (res) => {
        toast.error(res.response.data.error || "Something Went Wrong");
      },
    }
  );
  const { mutate: photoMutate, isLoading: isLoading2 } = useMutation(
    (data) =>
      axios.post(categoryImageUrl, convertObjectToFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => {
        toast.success("Category " + (cId ? "Updated" : "Created"));
        navigate(-1);
      },
      onError: (res) => {
        toast.error(res.response.data.error || "Something Went Wrong");
      },
    }
  );

  const { data: subCategories } = useQuery(
    "sub-category-list-for-category-add-or-edit",
    () => axios.get(subCategoryGetUrl + user.outletId)
    // () => axios.get(subCategoryGetUrl + user.restaurantId)
  );
  //
  return cId && isLoadingFormData ? (
    <CustomLoader />
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          if (!img) return toast.error("Image is required!");

          delete values.img;
          mutate({ ...values, category: categories });
        }}
        validationSchema={Yup.object().shape({
          parentCategoryName: Yup.string().required(
            "Category Name is required"
          ),
        })}
      >
        <Form>
          <Box>
            <PageHeader
              showBack
              secondary
              title={"Add new Menu category"}
              subTitle={"View and update your store details"}
              rightUi={
                <CustomButton
                  // onClick={() => alert("add")}
                  loading={isLoading2 || isLoading}
                  type="submit"
                >
                  Save
                </CustomButton>
              }
            />
            <StackRow gap={"32px"}>
              <PaperBox
                shadowed
                padding={"0 24px"}
                sx={{
                  flex: "1",
                  overflow: "auto",
                  height: "76vh",
                  maxWidth: "800px",
                  margin: "auto",
                }}
              >
                <Box
                  id="menu-media"
                  sx={{
                    pt: "24px",
                    pb: 3,
                  }}
                >
                  <PageHeader secondary title={"Category Media"} />
                  <Stack spacing={4}>
                    <UpliadImageFieldWithoutFormik
                      defaultValue={formData.parent_category_image_url}
                      name="img"
                      onChange={setImg}
                      uploadImg={true}
                    />
                    <CustomTextFieldFormik
                      label="Category Name"
                      placeholder="Enter Category Name"
                      required
                      name="parentCategoryName"
                    />

                    <Stack>
                      <FormHeader>Sub Categories</FormHeader>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              subCategories?.data?.data?.length ==
                              categories.length
                            }
                            onChange={(e) => {
                              e.target.checked
                                ? setCategories(
                                    subCategories?.data?.data?.map(
                                      (c) => c.categoryid
                                    )
                                  )
                                : setCategories([]);
                            }}
                          />
                        }
                        label="Add All"
                      />
                      <StackRow
                        gap={"12px"}
                        mb={4}
                        sx={{
                          flexWrap: "wrap",
                        }}
                      >
                        {subCategories?.data?.data?.map((category) => {
                          const isCurrent = categories.includes(
                            category.categoryid
                          );
                          return (
                            <Stack key={category?.categoryid}>
                              {/* <CustomChip
                                key={category.categoryid}
                                onClick={() =>
                                  categories.includes(category.categoryid)
                                    ? setCategories((p) => [
                                        ...p.filter(
                                          (c) => c !== category.categoryid
                                        ),
                                      ])
                                    : setCategories((p) => [
                                        ...p,
                                        category.categoryid,
                                      ])
                                }
                                label={category.categoryname}
                                sx={{
                                  border: "1px #94A3B8 solid",
                                  cursor: "pointer",
                                  background: isCurrent && "#2e7d32",
                                  color: isCurrent && "white",
                                  "&:hover": {
                                    background: isCurrent
                                      ? "#EF4444"
                                      : "#2e7d32",
                                    color: isCurrent ? "white" : "white",
                                    "& svg": {
                                      fill: isCurrent ? "white" : "white",
                                    },
                                  },
                                  "& svg": {
                                    transition: "0.3s",
                                    fill: isCurrent && "white",
                                    "&:active": {
                                      transform: "scale(0.8)",
                                    },
                                    "&:hover": {},
                                  },
                                }}
                                icon={isCurrent ? <Remove /> : <Add />}
                              /> */}
                              <PaperBox
                                boxSx={{
                                  p: 0,
                                  pr: 1,
                                }}
                              >
                                <StackRow center>
                                  <Checkbox
                                    checked={isCurrent}
                                    onChange={() =>
                                      categories.includes(category.categoryid)
                                        ? setCategories((p) => [
                                            ...p.filter(
                                              (c) => c !== category.categoryid
                                            ),
                                          ])
                                        : setCategories((p) => [
                                            ...p,
                                            category.categoryid,
                                          ])
                                    }
                                  />
                                  <Text
                                    bold
                                    sx={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {category.categoryname}
                                  </Text>
                                </StackRow>
                              </PaperBox>
                            </Stack>
                          );
                        })}
                      </StackRow>
                    </Stack>
                  </Stack>
                </Box>
              </PaperBox>
            </StackRow>
          </Box>
        </Form>
      </Formik>
    </motion.div>
  );
};

export default CategoriesAdd;
