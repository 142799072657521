import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  TextField,
  Button,
  Box,
  InputAdornment,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { toast } from "react-toastify";
import CustomSearchTextField from "../../components/formUI/CustomSearchTextField";
import PageHeader from "../../components/pageUI/PageHeader";
import { useSelector } from "react-redux";

const Notify = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedApps, setSelectedApps] = useState({
    // "com.mealpe": true,
    // "com.nbscafeteria": false,
  // });
  const [selectedApps, setSelectedApps] = useState(["com.mealpe"]);
  const outletId = useSelector((state) => state.auth.user.outletId);
  const outletName = useSelector((state) => state.auth.user.outletName);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    const filtered = customers?.filter((customer) =>
      customer?.customerName
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase())
    );
    setFilteredCustomers(filtered);
    setPage(0);
  }, [customers, searchQuery]);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_BASE_URL + `customer/getCustomerList/${outletId}`
      );
      console.log("response ", response);
      if (response?.data?.success) {
        setCustomers(response?.data?.data);
        setFilteredCustomers(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedCustomers(
        filteredCustomers.map((customer) => customer.customerAuthUID)
      );
    } else {
      setSelectedCustomers([]);
    }
  };

  const handleSelectCustomer = (customerAuthUID) => {
    setSelectedCustomers((prev) => {
      if (prev.includes(customerAuthUID)) {
        return prev.filter((id) => id !== customerAuthUID);
      } else {
        return [...prev, customerAuthUID];
      }
    });
  };

  // const handleAppSelection = (appId) => {
  //   setSelectedApps((prev) => ({
  //     ...prev,
  //     [appId]: !prev[appId],
  //   }));
  // };

  const handleSendNotification = async () => {
    setLoading(true);
    try {
      // Get selected app IDs
      // const selectedAppIds = Object.entries(selectedApps)
      //   .filter(([_, isSelected]) => isSelected)
      //   .map(([appId]) => appId);

      const notSend = await axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + "outlet/sendNotifications",
        {
          title,
          body: description,
          selectedCustomers,
          selectedApps, // Include selected apps in the request
          outletName
        }
      );

      // console.log("send notif ", notSend);

      if (notSend?.data?.success) {
        toast.success(notSend?.data?.message);
        setTitle("");
        setDescription("");
        setSelectedCustomers([]);
        // setSelectedApps({
        //   "com.mealpe": false,
        //   "com.nbscafeteria": false,
        // });
      }
    } catch (error) {
      console.log("error while send notifications");
    }finally{
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const isNotificationButtonEnabled =
    selectedCustomers.length > 0 &&
    title.trim() !== "" &&
    description.trim() !== "" 
    // Object.values(selectedApps).some((value) => value); // At least one app must be selected
  return (
    <Box>
      <PageHeader
        secondary
        title={`Notification`}
        subTitle={
          "Send notifications to keep your customers informed about updates"
        }
      />
      <Box mb={2}>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={2}
        />

        {/* App Selection Checkboxes */}
        {/* <FormGroup row sx={{ mt: 2, mb: 2 }}> */}
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={true}
                onChange={() => handleAppSelection("com.mealpe")}
              />
            }
            label="Mealpe"
          /> */}
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={selectedApps["com.nbscafeteria"]}
                onChange={() => handleAppSelection("com.nbscafeteria")}
              />
            }
            label="NBS Cafeteria"
          /> */}
        {/* </FormGroup> */}
      </Box>

      {/* <TextField
        variant="outlined"
        placeholder="Search customers"
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      /> */}
      <CustomSearchTextField
        placeholder="Search customers"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <h5 className="mb-4">
        {selectedCustomers?.length > 0 &&
          `${selectedCustomers?.length} Selected`}{" "}
      </h5>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedCustomers.length > 0 &&
                    selectedCustomers.length < filteredCustomers.length
                  }
                  checked={
                    filteredCustomers.length > 0 &&
                    selectedCustomers.length === filteredCustomers.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Customer Name
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Customer Mobile
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCustomers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((customer) => (
                <TableRow key={customer.customerAuthUID}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomers.includes(
                        customer.customerAuthUID
                      )}
                      onChange={() =>
                        handleSelectCustomer(customer.customerAuthUID)
                      }
                    />
                  </TableCell>
                  <TableCell>{customer.customerName}</TableCell>
                  <TableCell>{customer.mobile}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCustomers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendNotification}
          disabled={!isNotificationButtonEnabled}
          sx={{ mt: 2, textTransform: "none" }}
        >
          {loading ? "Sending..." : "Send Notification"}
        </Button>
      </Box>
    </Box>
  );
};

export default Notify;
