import React, { useEffect, useState } from "react";
import CustomDialog from "../../components/layoutUI/CustomDialog";
import Title from "../../components/typographyUI/Title";
import Logo from "../../assets/images/noInternetLogo.png";
import NoInternetPng from "../../assets/images/no-internet.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Lock from "../../assets/images/lock.png";
import CustomButton from "../../components/formUI/CustomButton";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { logout } from "../../Redux Store/Slices/auth";
import {useSmallScreen} from "../../utils/mediaQueries"


const OutletSubscription = () => {
  const { user } = useSelector((state) => state.auth);
  const isSmallScreen = useSmallScreen();

  const [subscriptionIsActive, setSubscriptionIsActive] = useState(false);
  const dispatch = useDispatch();

  // const navigate = useNavigate();

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = () => resolve(script);
      script.onerror = () => reject(new Error(`Script load error for ${src}`));
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    // Load the Easebuzz script
    // const script = document.createElement("script");
    // try {
    //   script.src =
    //     "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js";
    //   script.async = true;
    //   script.onload = () => {
    //     // Initialize EasebuzzCheckout after the script has loaded
    //     window.easebuzzCheckout = new window.EasebuzzCheckout(
    //       "7JUVAHGZVB",
    //       "prod"
    //     ); // for test environment pass "test"
    //   };
    //   document.body.appendChild(script);
    // } catch (error) {
    //   console.log("Error while easebuzz payment script loading...");
    // }

    // return () => {
    //   // Cleanup the script if the component unmounts
    //   document.body.removeChild(script);
    // };

    const scriptSrc =
      "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js";

    loadScript(scriptSrc)
      .then(() => {
        // Initialize EasebuzzCheckout after the script has loaded
        window.easebuzzCheckout = new window.EasebuzzCheckout(
          "7JUVAHGZVB",
          "prod"
        );
      })
      .catch((error) => {
        console.log("Error while loading Easebuzz payment script:", error);
      });

    return () => {
      // Cleanup the script if the component unmounts
      const script = document.querySelector(`script[src="${scriptSrc}"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const handlePayment = async (subcode) => {
    try {
      if (user) {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}payment/customer/initiate-payment-for-subscription`,
          {
            subscriptionCode: subcode,
            subscriptionInfo: `${
              subcode == 0 ? "12" : "6"
            } months subscription`,
            firstname: user?.outletName,
            phone: user?.mobile,
            email: user?.email,
            outletId: user?.outletId,
          }
        );

        // console.log("Payment ", res?.data?.subscriptionId);
        if (window.easebuzzCheckout && res?.data?.response?.data) {
          const options = {
            access_key: res?.data?.response?.data, // access key received via Initiate Payment
            onResponse: async (response) => {
              console.log("response ", response);
              if (response?.status == "success") {
                const subData = await axios.post(
                  `${process.env.REACT_APP_BACKEND_BASE_URL}payment/customer/changeTxnStatus/${res?.data?.subscriptionId}`
                );
                // console.log("resssss ", subData);
                if (subData?.data?.success) {
                  setSubscriptionIsActive(true);
                }
              }
              if (response?.status == "userCancelled") {
                const res = await axios.post(
                  `${process.env.REACT_APP_BACKEND_BASE_URL}payment/customer/cancel-subscription`,
                  {
                    outletId: user?.outletId,
                  }
                );
              }
              if (response?.status == "dropped") {
                const res = await axios.post(
                  `${process.env.REACT_APP_BACKEND_BASE_URL}payment/customer/cancel-subscription`,
                  {
                    outletId: user?.outletId,
                  }
                );
              }
              if (response?.status == "bounced") {
                const res = await axios.post(
                  `${process.env.REACT_APP_BACKEND_BASE_URL}payment/customer/cancel-subscription`,
                  {
                    outletId: user?.outletId,
                  }
                );
              }
            },
            theme: "#123456", // color hex
          };
          window.easebuzzCheckout.initiatePayment(options).then((res) => {
            console.log("responsesese ", res);
          });
        } else {
          console.error("EasebuzzCheckout is not initialized yet");
        }
      } else {
        console.log("User Not Found");
      }
    } catch (error) {
      console.log("Error while payment ");
    }
  };
  return (
    <div>
      {!subscriptionIsActive ? (
        <Dialog
          open={true}
          maxWidth="md"
          fullWidth
          style={{ backdropFilter: "blur(5px)" }}
        >
          {/* <DialogTitle>Subscription Plans</DialogTitle> */}
          <DialogContent>
            <Box style={{ display: "flex", justifyContent: "flex-end",marginBottom:isSmallScreen ? "2rem" : "" }}>
            <Typography
                variant={isSmallScreen ? "h5" : "h4"}
                style={{ color: "red", fontWeight: "bold", marginRight:isSmallScreen ? "2%" :"20%" }}
              >
                Your subscription has ended!
              </Typography>
              <CustomButton
                style={{
                  backgroundColor: "#F6F6F6",
                  color: "#FF5833",
                  borderRadius: "5rem",
                  width: "10rem",
                  border:"1px solid #FF5833"
                }}
                onClick={() => {
                  dispatch(logout());
                  window.location.reload();
                }}
              >
              
                Log Out
              </CustomButton>
            </Box>
            <Box sx={{ p: isSmallScreen ? 0 : 4 }}>
              <Typography
                variant= {isSmallScreen ? "h4" : "h3"}
                align="center"
                color="black"
                sx={{ fontWeight: "bold" }}
              >
                Just a few clicks away!
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="black"
                sx={{ fontWeight: "semibold", marginBottom: "3rem" }}
              >
                Subscribe for a smooth cafeteria experience!
              </Typography>

              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      textAlign: "center",
                      p: 2,
                      borderRadius: "16px",
                      "&:hover": {
                        border: "1px solid #FF5833",
                      },
                    }}
                    style={{ backgroundColor: "#F6F6F6" }}
                  >
                    <Typography
                      variant="h6"
                      display="block"
                      color="black"
                      sx={{ fontWeight: "semibold", marginBottom: "3rem" }}
                    >
                      PREFERRED
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      Annual Pricing
                      <br />
                      Per Outlet
                    </Typography>
                    <Typography
                      variant="h4"
                      color="black"
                      sx={{ fontWeight: "bold" }}
                    >
                      INR 6,000
                    </Typography>
                    <Typography
                      variant="h6"
                      color="black"
                      sx={{ fontWeight: "bold" }}
                    >
                      per month
                    </Typography>
                    <CardContent>
                      <Typography variant="body1">
                        Food Ordering App, Cafeteria Management Solution and
                        Mess Manager
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <CustomButton
                        onClick={() => handlePayment(0)}
                        style={{
                          backgroundColor: "#FF5833",
                          color: "white",
                          borderRadius: "10rem",
                        }}
                        fullWidth
                      >
                        Subscribe
                      </CustomButton>
                    </CardActions>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      textAlign: "center",
                      p: 2,
                      borderRadius: "16px",
                      "&:hover": {
                        border: "1px solid #FF5833",
                      },
                    }}
                    style={{ backgroundColor: "#F6F6F6" }}
                  >
                    <Typography
                      variant="h6"
                      display="block"
                      color="black"
                      sx={{ fontWeight: "semibold", marginBottom: "3rem" }}
                    >
                      POPULAR
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      Half Yearly Pricing
                      <br />
                      Per Outlet
                    </Typography>
                    <Typography
                      variant="h4"
                      color="black"
                      sx={{ fontWeight: "bold" }}
                    >
                      INR 7,500
                    </Typography>
                    <Typography
                      variant="h6"
                      color="black"
                      sx={{ fontWeight: "bold" }}
                    >
                      per month
                    </Typography>
                    <CardContent>
                      <Typography variant="body1">
                        Food Ordering App, Cafeteria Management Solution and
                        Mess Manager
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <CustomButton
                        onClick={() => handlePayment(1)}
                        style={{
                          backgroundColor: "#FF5833",
                          color: "white",
                          borderRadius: "10rem",
                        }}
                        fullWidth
                      >
                        Subscribe
                      </CustomButton>
                    </CardActions>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      textAlign: "center",
                      p: 2,
                      borderRadius: "16px",
                      "&:hover": {
                        border: "1px solid #FF5833",
                      },
                    }}
                    style={{ backgroundColor: "#F6F6F6" }}
                  >
                    <Typography
                      variant="h6"
                      display="block"
                      color="black"
                      sx={{ fontWeight: "semibold", marginBottom: "3rem" }}
                    >
                      CUSTOM
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      Let's Discuss Your Pricing
                      <br />
                      Per Outlet
                    </Typography>
                    <Typography
                      variant="h4"
                      color="black"
                      sx={{ fontWeight: "bold" }}
                    >
                      INR{" "}
                      <span role="img" aria-label="heart-eyes">
                        😍
                      </span>
                    </Typography>
                    <Typography
                      variant="h6"
                      color="black"
                      sx={{ fontWeight: "bold" }}
                    >
                      per month
                    </Typography>
                    <CardContent>
                      <Typography variant="body1">
                        Food Ordering App, Cafeteria Management Solution and
                        Mess Manager
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <CustomButton
                        onClick={() =>
                          window.open("https://mealpe.app/contact-us", "_blank")
                        }
                        style={{
                          backgroundColor: "#FF5833",
                          color: "white",
                          borderRadius: "10rem",
                        }}
                        fullWidth
                      >
                        Contact Us
                      </CustomButton>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>

              {/* <Typography variant="caption" display="block" align="center" sx={{ mt: 3 }}>
              You will be charged $9.99 (monthly plan) or $60.99 (annual plan) through your iTunes account. You can cancel at any time if you’re not satisfied.
            </Typography> */}
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OutletSubscription;
