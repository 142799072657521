import { Box, Grid, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import PageHeader from "../PageHeader";
import CustomButton from "../../formUI/CustomButton";
import CustomTextFieldFormik from "../../formUI/CustomTextFieldFormik";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Email } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateAdmin } from "../../../Services/RoleService";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { authLogin } from "../../../Redux Store/Slices/auth";
import { updateSatff } from "../../../Services/staff";

const Profile = () => {
  // const resData = useSelector(state => state?.auth?.user?.outletAdminId)
  const [formData, setFormData] = useState({
    // new_password: "",
    // current_password: "",
    address: "",
    name: "",
    email: "",
    mobile: "",
  });
  const { user } = useSelector((state) => state?.auth);
  const data = user?.outletStaffData
    ? user?.outletStaffData
    : user?.outletAdminId;
  const dispatch = useDispatch();
  const { mutate, isLoading } = useMutation((values) =>
    user?.outletStaffData
      ? updateSatff(user?.outletStaffData?.outletStaffAuthUId, {
          ...data,
          ...values,
        })
          .then((res) => {
            if (res.data.success) {
              toast.success("Staff Updated Successfully");
              dispatch(
                authLogin({
                  ...user,
                  outletStaffData: {
                    ...user.outletStaffData,
                    ...res.data.data[0],
                  },
                })
              );
            }
          })
.catch((err) => {})
      : updateAdmin(data?.outletAdminId, {
          ...data,
          ...values,
        })
          .then((res) => {
            toast.success("Profile Updated");
            dispatch(
              authLogin({
                ...user,
                outletAdminId: { ...user.outletAdminId, ...res },
              })
            );
          })
.catch((error) => {})
  );

  return (
    <Formik
      initialValues={{ ...formData, ...data }}
      onSubmit={(values) => {
        mutate(values);
      }}
      validationSchema={Yup.object().shape({
        // new_password: Yup.string()
        //   .required("Current Password is required")
        //   .min(8, "Length must be 8 characters"),
        // current_password: Yup.string()
        //   .required("Password is required")
        //   .min(8, "Length must be 8 characters"),
        name: Yup.string().required("Name is required"),
        // outletName: Yup.string().required("Name is required"),
        // email: Yup.string().required("Email is required"),
        // mobile: Yup.string().required("Mobile Number is required"),
      })}
    >
      {(formik) => (
        <Form>
          <Box>
            <PageHeader
              secondary
              title="Profile settings"
              subTitle={"View and update your account details"}
              rightUi={
                <CustomButton loading={isLoading} type="submit">
                  Save Changes
                </CustomButton>
              }
            />
            {/* <h1>{user?.outletStaffData?.outletStaffAuthUId ? "Staff" : "Outlet Manager"}</h1> */}
            <Grid container spacing={"20px"}>
              <Grid item xs={6}>
                <PageHeader
                  secondary
                  title="Name"
                  subTitle={
                    "Will appear on receipts, invoices, and other communication"
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextFieldFormik
                  name="name"
                  placeholder="Enter Name Here (Required)"
                />
              </Grid>
              {!user?.outletStaffData ? (
                <>
                  {" "}
                  <Grid item xs={6}>
                    <PageHeader
                      secondary
                      title="Address"
                      subTitle={
                        "Will appear on receipts, invoices, and other communication"
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextFieldFormik
                      name="address"
                      placeholder="Enter Address Here (Required)"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        ),
                      }}
                      disabled
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <PageHeader
                      secondary
                      title="Email address"
                      subTitle={
                        "Used to sign in, for email receipts and product updates"
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextFieldFormik
                      name="email"
                      placeholder="Enter Email Here (Required)"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        ),
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PageHeader
                      secondary
                      title="Mobile number"
                      subTitle={
                        "Used to sign in, for Mobile receipts and product updates"
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextFieldFormik
                      name="mobile"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+91</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default Profile;
