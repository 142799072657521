import store from "../Redux Store/store";
import { setLoadingSate } from "../Redux Store/Slices/loaderSlice";

function presentLoader() {
  store.dispatch(setLoadingSate(true));
}

function dismissLoader() {
  store.dispatch(setLoadingSate(false));
}

export { presentLoader, dismissLoader };