// // install (please try to align the version of installed @nivo packages)
// // yarn add @nivo/pie
// import { ResponsivePie } from "@nivo/pie";

// // make sure parent container have a defined height when using
// // responsive component, otherwise height will be 0 and
// // no chart will be rendered.
// // website examples showcase many properties,
// // you'll often use just a few of them.
// const data = [
//   {
//     id: "javascript",
//     label: "javascript",
//     value: 247,
//     color: "hsl(149, 70%, 50%)",
//   },
//   {
//     id: "python",
//     label: "python",
//     value: 42,
//     color: "hsl(161, 70%, 50%)",
//   },
//   {
//     id: "go",
//     label: "go",
//     value: 65,
//     color: "hsl(251, 70%, 50%)",
//   },
// ];
// const PieChart = (
//   {
//     /* see data tab */
//   }
// ) => (
//   <ResponsivePie
//     data={data}
//     margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
//     innerRadius={0.5}
//     activeOuterRadiusOffset={8}
//     borderWidth={1}
//     borderColor={{
//       from: "color",
//       modifiers: [["darker", 0.2]],
//     }}
//     enableArcLinkLabels={false}
//     arcLinkLabelsSkipAngle={10}
//     arcLinkLabelsTextColor="#333333"
//     arcLinkLabelsThickness={2}
//     arcLinkLabelsColor={{ from: "color" }}
//     enableArcLabels={false}
//     arcLabelsRadiusOffset={0}
//     arcLabelsSkipAngle={10}
//     arcLabelsTextColor={{
//       from: "color",
//       modifiers: [["darker", 2]],
//     }}
//     defs={[
//       {
//         id: "dots",
//         type: "patternDots",
//         background: "inherit",
//         color: "rgba(255, 255, 255, 0.3)",
//         size: 4,
//         padding: 1,
//         stagger: true,
//       },
//       {
//         id: "lines",
//         type: "patternLines",
//         background: "inherit",
//         color: "rgba(255, 255, 255, 0.3)",
//         rotation: -45,
//         lineWidth: 6,
//         spacing: 10,
//       },
//     ]}
//     isInteractive={false}
//     colors={["#9E77ED", "#B692F6", "#D6BBFB"]}
//     legends={[]}
//   />
// );

// export default PieChart;
// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { ResponsivePie } from '@nivo/pie'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

let data = [
    {
      "id": "Delivery",
      "label": "Delivery",
      "value": 400,
       "color": "#9E77ED"
    },
    {
      "id": "Dine In",
      "label": "Dine In",
      "value": 307,
      "color": "#B692F6"
    },
    {
      "id": "Take Away",
      "label": "Take Away",
      "value": 144,
      "color": "#F4EBFF"
    },
    // {
    //   "id": "others",
    //   "label": "others",
    //   "value": 264,
    //   "color":  "hsl(304, 70%, 50%)"
    // },
  ]
const PieChart = ({data =[]}) => (
    <ResponsivePie
        data={data}
        margin={{ top: 40, right: 110, bottom: 80, left: 0 }}
        innerRadius={0.5}
        padAngle={0}
        colors={["#7F56D9","#9E77ED","#B692F6","#F4EBFF"]}
        colorBy="index"
        cornerRadius={0}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={8}
        borderWidth={0}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#FFFFFF"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: '.................' }}
        arcLabelsSkipAngle={10}
        enableArcLabels={false}
        arcLabelsTextColor="#000000"
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 135,
                translateY: -50,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 20,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 10,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
)
export default PieChart;
