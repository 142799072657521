import { Typography } from "@mui/material";
import React from "react";

const Headline3 = ({ children, sx, ...otherProps }) => {
  return (
    <Typography
      fontWeight={600}
      fontSize={"1.8rem"}
      color={"black"}
      sx={sx}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default Headline3;
