import { Box, Tab, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import React, { useState } from "react";
import CustomMenuAdd from "../../../../pages/Menu/Categories/CustomMenuAdd";
import { useGetApis } from "../../../../hooks/useGetApis";
import CustomDialog from "../../../layoutUI/CustomDialog";
import CustomButton from "../../../formUI/CustomButton";
import StackRow from "../../../layoutUI/StackRow";
import { verifyEmailOtp } from "../../../../Services/otp";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { authLogin } from "../../../../Redux Store/Slices/auth";
import { updatePetPoojaConfigs } from "../../../../Services/menuService";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, pb: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FetchMenu = ({ showModal, setShowModal }) => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [tabs] = useState(["Category", "Sub-Category", "Menu Items"]);
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    categoryGetUrl,
    subCategoryGetUrl,
    itemGetUrl,
    subCategoryGetSingleUrl,
    categoryGetSingleUrl,
    pushMenuData,
  } = useGetApis();
  const handleSubmit = () => {
    if (items.length > 0) {
      setLoading(true);
      axios
        .post(pushMenuData + user?.outletId, {
          category: category,
          subCategory: subcategory,
          item: items,
        })
        .then(async (res) => {
          
          if (user?.publishProcessingStep <= 1) {
            const postData = {
              publishProcessingStep: 2,
            };
            //* update outlet
            const updateOutletConfigsResponse = await updatePetPoojaConfigs(
              postData,
              user?.outletId
            );
            

            if (updateOutletConfigsResponse?.data?.success) {
              localStorage.removeItem('hasShownMenuStep')
              localStorage.removeItem('hasShownStep')
              dispatch(authLogin({ ...user, ...postData }));
            }
          }

          setShowModal(false);
        })
        .catch((err) => {
          setLoading(false)
          console.error(err);
          toast.error("Something went wrong.")
        });
    } else {
      toast.warning("Please select items");
    }
  };

  const handleChange = (_, newValue) => {
    
    setValue(newValue);
  };
  const handleNext = () => {
    if (value < 2) {
      setValue((prev) => prev + 1);
    }
    if (value == 2) {
      handleSubmit();
    }
  };
  const handleBack = () => {
    if (value == 0) {
      setShowModal(false);
    }
    if (value > 0) {
      setValue((prev) => prev - 1);
    }
  };
  const getCategoryName = (url, id) => {
    axios
      .get(url + id)
      .then((res) => {
        
        toast.warn(
          `Please select ${
            res?.data?.data?.[0]?.parentCategoryName ||
            res?.data?.data?.[0]?.categoryname
          } sub-category`
        );
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={() => setShowModal(false)}
      width={"900px"}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            scrollButtons="auto"
            allowScrollButtonsMobile
            variant="scrollable"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
            s
          >
            {tabs.map((tab, index) => (
              <Tab
                label={tab}
                style={{ fontSize: "1rem" }}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CustomMenuAdd
            header={"Category"}
            url={categoryGetUrl}
            rowId={"parent_category_id"}
            selected={category}
            onChange={setCategory}
            categoryTab
            handler={(list) => {
              setSubCategory(
                subcategory.filter((sc) =>
                  list.some(
                    (c) => c.parent_category_id == sc.parent_category_id
                  )
                )
              );
            }}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CustomMenuAdd
            header={"Sub-Category"}
            url={subCategoryGetUrl}
            rowId={"categoryid"}
            selected={subcategory}
            parentCategory={category}
            onChange={setSubCategory}
            handler={(list) => {
              setItems(
                items.filter((sc) =>
                  list.some((c) => c.parent_category_id == sc.item_categoryid)
                )
              );
            }}
            subCategoryTab
            alert={(row) =>
              getCategoryName(categoryGetSingleUrl, row.parent_category_id)
            }
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CustomMenuAdd
            header={"Items"}
            url={itemGetUrl}
            rowId={"itemid"}
            selected={items}
            parentSubCategory={subcategory}
            onChange={setItems}
            itemsTab
            searchItem={true}
            alert={(row) =>
              getCategoryName(subCategoryGetSingleUrl, row.item_categoryid)
            }
          />
        </CustomTabPanel>
        <StackRow
          justifyContent="flex-end"
          sx={{
            marginTop: "auto",
          }}
        >
          <CustomButton
            onClick={() => handleBack()}
            variant="outlined"
            sx={{
              mt: 2,
              mr: 1,
              ml: 2,
            }}
          >
            {value == 0 ? "Cancel" : "Back"}
          </CustomButton>
          <CustomButton
            sx={{
              fontWeight: "400",
              mt: 2,
            }}
            loading={value == 2 && loading}
            onClick={() => handleNext()}
          >
            {value >= 2 ? "Save" : "Next"}
          </CustomButton>
        </StackRow>
      </Box>
    </CustomDialog>
  );
};

export default FetchMenu;
