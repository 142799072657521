import { Button } from "@mui/material";
import { DropzoneDialog } from "material-ui-dropzone";
import React from "react";

const FileUploadDialog = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Add Image
      </Button>
      <DropzoneDialog
        acceptedFiles={["image/*"]}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          
          setOpen(false);
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </div>
  );
};

export default FileUploadDialog;
